.delete-person {
  width: 100%;
  padding: 20px 32px 0 32px;

  &__title {
    color: black;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 16px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text {
    width: calc(100% - 350px);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #9898A6;
  }

  &__button {
    width: 308px;
    display: flex;
    padding: 8px 20px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid #DB1F26;
    color: #DB1F26;
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: 25px;
    background-color: white;
  }
}
