@import "../../../../styles/mainVariables";
.roles{
  overflow: unset !important;
  margin-bottom: 100px;

  &__container{
    display: flex;
    justify-content: center;
    width: 100%;

    &-wrap{
      width: 100%;
      max-width: 1200px;
    }
  }
  //
  //&.full {
  //  padding-left: calc($menuWidthSmall + 32px)!important;
  //}

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    &__right {
      display: flex;
      align-items: center;
    }

    &__add-user {
      background-color: transparent;
      border: 0;
      outline: 0;
      color: #1ABB78;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;

      svg {
        margin-right: 13px;
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
    margin-right: 40px;
  }

  &__list-header {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #F8F8F8;
    padding: 0 24px;
    text-transform: uppercase;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.24px;
    margin-bottom: 20px;

    &__content {
      width: calc(100% - 180px);
      display: flex;
      //justify-content: space-between;
      align-items: center;

      .item_col {
        width: 28%;
        margin-right: 5%;
        opacity: 0.3499999940395355;
      }
    }

    &__actions {
      width: 156px;
      opacity: 0.3499999940395355;
    }
  }
}
