.texts-update-menu {
  margin-left: 2px;
  position: relative;
  z-index: 1110 !important;

  .dropdown-content {
    z-index: 1110 !important;
    top: 36px !important;
    background-color: white;

    .menu-item {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 450;
      line-height: 20px;

      &:first-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }

      svg {
        path {
          fill: black;
        }
      }
    }
  }

}

