.sources{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;

  &__panel{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__btn.active{
      svg{
        transform: rotate(0deg);
      }
    }
    &__btn{
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      span{
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(152, 152, 166, 1);
      }

      svg{
        width: 16px;
        height: 16px;
        transform: rotate(180deg);
        path{
          fill: #a8a7ac;
        }
      }
    }

    &__actions{
      margin-left: auto;
      &-icons{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        margin-top: 4px;
        cursor: pointer;

        &--plus{
          width: 14px;
          height: 14px;

          rect {
            fill: #A8A8AC;
          }
        }

        &--info{
          width: 16px;
          height: 16px;
            path{
              fill: #A8A8AC;
              stroke: white;
            }
        }

        &--copy{
          width: 16px;
          height: 16px;
          path{
            stroke: #A8A8AC;
          }
        }

      }
    }

  }

  &__list{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &--item{
      display: inline-flex;
      align-items: flex-start;
      gap: 4px;
      flex-wrap: wrap;

      &-id{
        margin-right: 8px;
      }

      &-id, &-subs{
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(152, 152, 166, 1);
      }



      &-link{
        text-decoration: underline;
        color: rgba(130, 130, 130, 1);
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;

        &:hover{
          color: #1ABB78;
          text-decoration: underline;
        }
      }
    }
  }
}