$borderColor: #F2F2F2;
.protocol {
  height: 100%;
  background: white;
  border-radius: 12px;
  border: 1px solid $borderColor;
  transition: all 0.3s;
  position: relative;

  .slider_dots {
    display: none;
  }

  &__header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    border-bottom: 1px solid $borderColor;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: #111826;
    position: relative;

    &__btn {
      width: 32px;
      height: 32px;
      background: #22C55E;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      cursor: pointer;
      transition: all 0.2s;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%);
      z-index: 1;
      opacity: 0;
      &.disabled {
        opacity: 0.5;
      }
    }
    .share_icon{
      cursor: pointer;
    }

    &:hover {
      .protocol__header__btn {
        opacity: 1;
      }
    }
  }

  &__form {
    margin: 20px 24px;
    position: relative;

    &__title, &__close {
      display: none;
    }
    .autocomplete_input-wrap {
      height: 32px;
      width: 100%;
      border: 1px solid #DAD9DB;
      border-radius: 10px;
      padding: 0 12px;
      font-size: 14px;

      input {
        width: 100%;
        border: 0;

        &:focus {
          outline: 0;
        }
      }
    }
    .autocomplete_input{
      margin-bottom: 8px;
    }

    textarea {
      height: 77px;
      width: 100%;
      //max-width: 376px;
      border: 1px solid #DAD9DB;
      border-radius: 10px;
      padding: 12px;
      font-size: 14px;
    }

    &__users_list {
      height: 32px;
      width: calc(100% - 24px) !important;
      max-width: 376px;
      border-radius: 10px;
      margin-bottom: 10px;
    }

    input:focus, textarea:focus {
      outline: 0;
    }

    textarea {
      &.error {
        border-color: red;
      }
    }

    .form_time {
      width: 86px;

      &.error {
        border-color: red;
      }
    }

    .time-wrapper{
      display: flex;
      align-items: center;
      margin-bottom: 0;

      input{
        margin-bottom: 0;
        margin-right: 6px;
        height: 32px;
        border: 1px solid #DAD9DB;
        border-radius: 10px;
        padding: 0 12px;
        font-size: 14px;
      }
      svg{
        width: 12px;
        height: 12px;
        cursor: pointer;
      }
    }

    &__btn {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: #22C55E;
      color: white;
      height: 32px;
      padding: 0 16px;
      //width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      cursor: pointer;

      &.edit{
        right: 116px;
        background-color: #f0f0f0;
        color: black;
      }

      &.disabled {
        opacity: 0.5;
      }
    }
  }

  &.protocol_second_slide {
    height: calc(100% - 500px);
  }

  &.protocol_full {
    height: calc(100vh - 66px);
  }

  &__content {
    width: 128px;
    position: absolute;
    right: 20px;
    top: 80px;
    //top: 42px;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    transform: translateY(-50%);
    z-index: 1;
  }

  &__item {
    height: 42px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #46454E;
    cursor: pointer;

    &.delete {
      color: #DB1F26;
      border-top: 1px solid #E8EBEE;
    }

    svg {
      margin-right: 6px;
    }
  }

  .protocol-list{
    width: 100%;
    max-height: calc(100% - 100px);
    height: auto;
    padding: 0;
    list-style-type: none;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: white;
      border: 1px solid #F2F2F2;
    }

    &::-webkit-scrollbar-thumb {
      background-color: lightgray;
      border-radius: 10px;
      border: 1px solid #F2F2F2;
      min-height: 40px;
      min-width: 40px;
    }

    .dragable{
      border: 2px dashed #299bf0;
      background-color: white;
      border-radius: 12px;
      div{
        opacity: 0;
      }
    }
    .marked{
      .tiptap-editor:not(.tiptap-editor--edit) {
        background: rgba(26, 187, 120, 0.35) !important;

      }
      //.tiptap-editor{
      //  background: rgba(26, 187, 120, 0.35) !important;
      //}
    }
  }

  @media only screen and (max-width: 1100px) {
    &__form {
      &__content {
        display: flex;
        flex-direction: column;

        textarea {
          margin-bottom: 8px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 16px 16px 0 0;
    z-index: 1;

    .slider_dots {
      display: flex;
      justify-content: center;
      transform: translateY(-24px);

      .dot {
        width: 6px;
        height: 6px;
        background-color: white;
        border: 2px solid #A8A8AC;
        border-radius: 50%;
        margin-left: 6px;

        &.active {
          width: 20px;
          height: 6px;
          background: #77777D;
          border-radius: 30px;
        }
      }
    }


    &__note {
      &__dots {
        opacity: 1;
      }

      &__actions {
        width: 100%;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 100;
        background: rgba(17, 24, 38, 0.9);

        &__close {
          position: absolute;
          left: 50%;
          bottom: 160px;
          transform: translateX(-50%);
          width: 32px;
          height: 32px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          border: 1px solid $borderColor;
          cursor: pointer;
        }

        &__content {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 101;
          width: 100%;
          background: #FFFFFF;
          border: 1px solid #F5F5F5;
          box-shadow: 0 4px 14px rgba(0, 0, 0, 0.06);
          border-radius: 12px 12px 0 0;
          transform: unset;
        }

        &__item {
          height: 72px;
          padding: 0 17px;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 130%;
          color: #46454E;
          cursor: pointer;

          &.delete {
            color: #DB1F26;
            border-top: 1px solid #E8EBEE;
          }

          &.add {
            border-top: 1px solid #E8EBEE;

            svg {
              rect {
                fill: #46454E;
              }
            }
          }

          svg {
            margin-right: 6px;
          }
        }
      }

      &__btn {
        display: none;
      }
    }

    &__form {
      margin: 0;
      width: 100%;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 100;
      background: rgba(17, 24, 38, 0.9);

      &__content {
        position: absolute;
        bottom: 0;
        left: 24px;
        z-index: 101;
        width: calc(100% - 48px);
        background: #FFFFFF;
        border: 1px solid #F5F5F5;
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.06);
        border-radius: 12px 12px 0 0;
        transform: unset;
        padding: 32px 24px;
        display: flex;
        flex-direction: column;
      }

      &__title {
        display: block;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 24px;
        color: #46454E;
      }

      &__close {
        position: absolute;
        left: 50%;
        top: -46px;
        transform: translateX(-50%);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border: 1px solid $borderColor;
        cursor: pointer;
      }

      input {
        height: 40px;
        width: calc(100% - 24px);
      }

      textarea {
        height: 106px;
        width: calc(100% - 24px);
        max-width: calc(100% - 24px);
        margin-bottom: 8px;
      }

      .form_time {
        //width: 52px;
      }

      &__btn {
        position: unset;
        background: #1ABB78;
        border-radius: 120px;
        color: white;
        height: 48px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.nestable {
  height: 100%;
  .nestable-list {
    width: 100%;
    height: fit-content;
    padding: 0;
    list-style-type: none;
    overflow: hidden;
    padding-bottom: 40px!important;
    .nestable-item {
      //position: relative;

    }


  }
}

.info-block{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 36px;
  text-align: center;
  padding: 0 40px;
  cursor: pointer;

  &--text{
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #9898A6;
    max-width: 500px;
    padding: 0 10px;
  }
}

.voice-block{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;

  &--btn{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    cursor: pointer;
    margin-bottom: 8px;
    background: none;
    svg{
      width: 40px;
      height: 40px;
    }
  }

  &--text{
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #9898A6;
    max-width: 500px;
    padding: 0 10px;
  }
}