.custom-button {
  padding: 8px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid transparent;
  cursor: pointer;
  gap: 8px;

  &--label {
    font-size: 18px;
    font-weight: 450;
    line-height: 24px;
    white-space: nowrap;
  }

  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--l {
    height: 44px;

    &--icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &--xl {
    height: 55px;

    &--icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &--m {
    height: 40px;

    &--icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &--s {
    height: 36px;

    .custom-button--label {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    &--icon {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &--xs {
    height: 32px;

    .custom-button--label {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    &--icon {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &--primary {
    background: #1ABB78;
    border-color: #1ABB78;
    color: white;

    &:hover {
      background: #29D990;
      border-color: #29D990;
    }

    &:disabled {
      //background: #AAF0D3;
      //border-color: #AAF0D3;
      opacity: 0.6;
    }
    &:disabled:hover {
      background: #1ABB78;
      border-color: #1ABB78;
    }

    &:active {
      background: #1FB073;
      border-color: #1FB073;
    }

    &--icon {
      svg {
        path {
          color: #1ABB78
        }
      }
    }
  }

  &--secondary {
    background: transparent;
    border-color: #1ABB78;
    color: #1ABB78;

    &:hover {
      background: #1ABB781A;
    }

    &:disabled {
      color: #AAF0D3;
      border-color: #AAF0D3;
    }
    &:disabled:hover {
      background: transparent;
      border-color: #1ABB78;
      color: #1ABB78;
    }

    &:active {
      background: #d1f1e4;
      border-color: #148F5C;
      color: #148F5C;
    }

    &--red {
      border-color: #DB1F26;
      color: #DB1F26;

      &:hover {
        background: #FCE9E9;
      }

      &:active {
        background: #F8CECF;
        color: #DB1F26;
        border-color: #DB1F26;
      }
    }
  }

  &--ghost {
    background: transparent;
    border-color: transparent;
    color: #1ABB78;
    padding: 10px 4px;

    &:hover {
      color: #28E194;
    }

    &:disabled {
      opacity: 0.6;
    }

    &:active {
      color: #1FB073;
    }

    &--red {
      color: #DB1F26;

      &:hover {
        color: #E5484E;
      }

      &:active {
        color: #DB1F26;
      }
    }
  }
}