.download_btn {
  height: 36px;
  background: #1ABB78;
  color: #ffffff;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  border-radius: 10px;
  border: 0;
  padding: 10px;
  text-decoration: none;
  margin-right: 10px;
  &:hover{
    color: white;
  }
}

.search__buttons-record {
  display: flex;
  align-items: center;
  justify-content: center;

  &.timer{
    background-color: black;
    border-color: black;
  }
}

.count_timer {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.03em;

  svg{
    margin-right: 8px;
  }
}
