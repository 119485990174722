.person-btn {
  width: 306px;
  height: 40px;
  background-color: #1ABB78;
  color: white;
  border: 1px solid #D9D9D9;
  border-radius: 12px;
  margin-top: 12px;
  font-weight: 450;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 6px;
    width: 20px;
    height: 20px;
  }
}

.persons-dropdown-wrapper {
  .dropdown-content {
    //top: 0;
    //right: -420px;
    bottom: 60px!important;
    //transform: translateY(-50%);
    padding: 20px;

    .persons-header {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      border: 1px solid #dad9db;
      border-radius: 10px;

      input {
        width: 100%;
        height: 44px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 10px;
        border: none;
        border-radius: 10px;
        box-sizing: border-box;

        &:focus {
          outline: 0;
          border: none;
        }
      }
    }

    .persons-list {
      width: 100%;
      height: 100%;
      max-height: 350px;
      overflow-y: auto;

      .person-item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        position: relative;
        gap: 12px;
        padding-left: 3px;

        &--radio{
          cursor: pointer;

          .form-check-input{
            box-shadow: none;
            cursor: pointer;
          }
          .form-check-input:checked{
            background-color: #1FB073;
            border-color: #1FB073;
          }
        }
        .person-check{
          position: absolute;
          left: 0;
        }

        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #f2f3fc;
          margin-right: 8px;
        }

        .person_info {
          .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: black;
          }

          .email {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #9898A6;
          }
        }
      }
      &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }

      &::-webkit-scrollbar-track {
        background: white;
        border: 1px solid #F2F2F2;
      }

      &::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 10px;
        border: 1px solid #F2F2F2;
        min-height: 40px;
        min-width: 40px;
      }
    }

    .buttons-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      padding: 16px 0 0 0;

      button {
        width: 48%;
        height: 44px;
        background-color: #1ABB78;
        color: white;
        border: 0;
        border-radius: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .cancel-btn {
        background-color: #f0f0f0;
        color: black;
      }
    }
  }
}

.upload_video_answer {
  border-radius: 8px;
  background: #181818;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.20), 0 6px 30px 0 rgba(0, 0, 0, 0.12), 0 16px 24px 0 rgba(0, 0, 0, 0.14);
  color: white;
  text-align: left;
  display: flex;
  width: 343px;
  padding: 16px 20px;
  align-items: center;
  gap: 12px;

  svg {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
  }

  .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.408px;
    margin-bottom: 8px;
  }

  .description {
    color: #C8C5C5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.078px;
    margin-bottom: 0;
  }
}

.loader-input{
  margin-right: 5px;
}
