
.recent-video{
  width: 17%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 10px;
  max-width: 160px;
  flex-grow: 1;
  position: relative;

  &--wrap{
    flex-direction: column;
    display: flex;
    gap: 4px;
    position: relative;
  }

  &--poster{
    object-fit: fill;
    border-radius: 10px;
    max-width: 210px;
    position: relative;
    flex-grow: 1;

    @media screen and (min-width: 1440px){
      object-fit: cover;
    }

    &-img{
      width: 100%;
      height: 90px;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
      border-radius: 8px;
    }
  }

  &--loader{
    position: absolute;
    width: 100%;
    max-height: 90px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--name{
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    cursor: text;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    padding-top: 5px;
  }

  &__info{
    margin-top: 4px;
    display: flex;
    justify-content: space-between;

    &-date{
      display: flex;
      flex-direction: column;
      color: rgba(152, 152, 166, 1);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      cursor: text;
    }
  }

  &__channels {
    position: absolute;
    bottom: 12px;
    left: 8px;
    display: flex;
    align-items: center;
    gap: 4px;

    &--badge{
      font-weight: 450;
      font-size: 14px;
      line-height: 16px;
      color: white;
      border-radius: 6px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.6);
      max-width: 108px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media screen and (max-width: 1440px){
        max-width: 80px;
      }

      @media screen and (max-width: 1330px){
        max-width: 60px;
      }
    }

    .dote {
      width: 7px;
      height: 7px;
      background-color: #33BCC5;
      display: block;
      border-radius: 50%;
      margin-right: 4px;
      flex-shrink: 0;
    }
  }

  &__time {
    font-weight: 450;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    border-radius: 10px;
    padding: 4px 8px;
    position: absolute;
    margin: 0;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.4);

    p {
      margin: 0;
    }
  }

  .channels_count {
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.60);
    padding: 4px 8px;
    font-weight: 450;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    margin: 0;
    cursor: pointer;
  }

  .user-icons__item, .user-icons__more{
    width: 28px;
    height: 28px;
  }
}


.channels_list {
  min-width: 100px;
  border: 0;
  box-shadow: 0 5px 15px rgba(22, 22, 26, 0.1);
  padding: 14px;
  position: absolute;
  top: 95px;
  left: 0;
  background-color: white;
  border-radius: 10px;
  z-index: 100;

  .channel_item {
    color: #4F40F7;
    padding: 5px 10px;
    border-radius: 60px;
    margin-bottom: 8px;
  }
}