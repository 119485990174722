.menu-wrapper {
  cursor: pointer;
  position: relative;
  width: fit-content;

  .dropdown-content {
    position: absolute;
    top: 46px;
    right: 0;
    z-index: 1000;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(22, 22, 26, 0.1);
    .menu-item:last-child{
      border-radius: 0 0 12px 12px !important;
    }
    .menu-item:first-child{
      border-radius: 12px 12px  0 0 !important;
    }
    .menu-item, div > {
      display: flex;
      align-items: center;
      padding: 16px;
      cursor: pointer;
      color: black;
      font-style: normal;
      font-weight: 400;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      > svg {
        margin-right: 14px;
      }
    }

    .menu-item {
      &:hover{
        background-color: #E9F9F2;
        border-color: #E6E6E6;
        border-radius: 12px;
      }
      &:active{
        background-color: #BBEBD7;
        border-color: #E6E6E6;
      }
    }
    .menu-button{
      cursor: pointer;
    }

    .edit {
      border-bottom: 1px solid #0000000f;
    }

    .delete, .log-out {
      color: #DB1F26;
    }

    &.open-top {
      top: unset!important;
      bottom: calc(100% + 20px);
    }
    &.open-bottom {
      left: 0;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a8a7ad;
    }
  }
}
