.transcription-author-block{
  display: flex;
  align-items: center;

  &--img{
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: white;
    cursor: default;
  }

  &--text{
    color: #37A87B;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    &:hover{
      color:#45C090
    }
  }

  .text-markdown{
    .markdown-paragraph{
      color: #37A87B;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;

      &:hover{
        color:#45C090
      }
    }
  }
  &--to{
    color: #4A4A4A;
    font-size: 14px;
    line-height: 20px;
  }
}