.filter-note-person {
  .ant-popover-inner {
    padding: 0;
    width: 280px;
  }
  .person-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    gap: 12px;
    input{
      margin: 0;
    }

    .person-check{
      position: absolute;
      left: 0;
    }

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: #f2f3fc;
      border: 2px solid #F2F2F2;
      margin-right: -4px;
    }

    .person_info {
      .name {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: black;
        width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .email {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #9898A6;
      }
    }
  }

  &__find-users{
    .ant-popover-inner{
      padding: 0;
      border-radius: 10px;
      width: 244px;
    }
    .person-item {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      position: relative;
      gap: 12px;
      cursor: pointer;
      input{
        margin: 0;
      }

      .person-check{
        position: absolute;
        left: 0;
      }

      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: #f2f3fc;
        border: 2px solid #F2F2F2;
        margin-right: -4px;
        flex-shrink: 0;
      }

      .person_info {
        .name {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: black;
          width: 140px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .email {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          width: 145px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: #9898A6;
        }
      }
    }
    &--container{
      box-shadow: 0px 5px 15px 0px #16161A1A;
      border-radius: 10px;
      padding: 16px 12px;
      background: white;
      z-index: 1;
      max-height: 300px;
      overflow: auto;
    }

  }

  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__user-info{
    padding: 0 16px;
  }

  &__search {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px 16px;


    &--input-wrap {
      border: 1px solid #D9DEE2;
      border-radius: 20px;
      padding: 4px 10px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;

      input {
        border: none;
        outline: 0;
        padding: 0;

        &:focus-visible {
          border: none;
        }
      }

      &:active {
        border-color: #1FB073;
      }

      &:focus,
      &:focus-within {
        border: 1px solid #1ABB78;
      }
    }


  }

  &__container {
    display: flex;
    flex-direction: column;
    min-width: 144px;
  }

  &__list {
    padding: 0 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
    max-height: 240px;
    height: 100%;
  }

  &__empty{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 16px ;
    color: rgba(152, 152, 166, 1);
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 4px 8px;
    border: 1px solid #CDCDCD;
    border-radius: 8px;
    width: max-content;

    &:hover {
      border-color: #1FB073;
    }

    svg:first-child {
      width: 20px;
      height: 18px;
      flex-shrink: 0;
    }

    &--text {
      font-size: 14px;
      font-weight: 450;
      line-height: 16px;
      color: #212121;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }

    &--arrow {
      transform: rotate(-90deg);
      flex-shrink: 0;

      path {
        fill: black;
      }
    }

    &--arrow.open {
      transform: rotate(90deg);

      path {
        fill: black;
      }
    }
  }

  &__label.selected {
    background-color: #DDF5EB;
    border-color: #1ABB78;
  }

  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 16px;
    border-top: 1px solid #E6E6E6;

    button {
      width: 100%;
    }
  }

  &__tooltip {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}