.filter-links {
  .ant-popover-inner {
    padding: 0;
  }

  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__search {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px 16px;


    &--input-wrap {
      border: 1px solid #D9DEE2;
      border-radius: 20px;
      padding: 4px 10px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;

      input {
        border: none;
        outline: 0;
        padding: 0;

        &:focus-visible {
          border: none;
        }
      }

      &:active {
        border-color: #1FB073;
      }

      &:focus,
      &:focus-within {
        border: 1px solid #1ABB78;
      }
    }


  }

  &__container {
    display: flex;
    flex-direction: column;
    min-width: 144px;
  }

  &__list {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 4px 8px;
    border: 1px solid #CDCDCD;
    border-radius: 8px;
    width: max-content;

    &:hover {
      border-color: #1FB073;
    }

    svg:first-child {
      width: 20px;
      height: 18px;
      flex-shrink: 0;
    }

    &--text {
      font-size: 14px;
      font-weight: 450;
      line-height: 16px;
      color: #212121;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100px;
      width: 100%;
    }

    &--arrow {
      transform: rotate(-90deg);
      flex-shrink: 0;

      path {
        fill: black;
      }
    }

    &--arrow.open {
      transform: rotate(90deg);

      path {
        fill: black;
      }
    }
  }

  &__label.selected {
    background-color: #DDF5EB;
    border-color: #1ABB78;
  }

  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 16px;
    border-top: 1px solid #E6E6E6;

    button {
      width: 100%;
    }
  }

  &__tooltip {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}