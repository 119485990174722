.persons-menu {
    .ant-popover-content{
      width: 326px;
    }
  .ant-popover-inner{
    padding: 0;
  }
    .header{
      border-bottom: 1px solid #E6E6E6;
      padding: 20px 16px 0 16px;

      label{
        color: #212121;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .form-check{
        height: 20px;
        margin-bottom: 12px;
      }
    }
  .form-check{
    display: flex;
    align-items: center;
  }
  .form-check-input{
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
    .form-check-input:checked {
      background-color: #1ABB78;
      border-color: #1ABB78;
    }

    .form-check-input:focus {
      border-color: #1ABB78;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(26, 187, 120, .25);
    }

    .persons_list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-height: 26vh;
      border: 0;
      background-color: white;
      border-radius: 10px;
      z-index: 100;
      overflow-y: auto;
      padding: 0 16px;

      .person_item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        position: relative;

        .check_icon {
          position: absolute;
          left: 0;
          //opacity: 1;
          //width: 20px;
        }

        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #f2f3fc;
          margin-right: 8px;
        }

        .person_info {
          .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: black;
          }

          .email {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #9898A6;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #a8a7ad;
      }
    }

    .add-person {
      color: #1ABB78;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }

    .add-persons-dropdown{
      padding: 16px;
      width: 100%;

      .menu-button{
        .input-wrap{
          display: flex;
          align-items: center;
          gap: 5px;
          border: 1px solid #f1e0ff;
          border-radius: 10px;
          &-loader{
            margin-right: 10px;
          }
        }
        input{
          width: 100%;
          height: 40px;
          padding: 0 10px;
          border-radius: 10px;
          border: none;
          &:focus{
            outline: 0;
          }
        }
      }
      .dropdown-content{
        left: 17px;
        top: 50px;
        padding: 0;
        .persons_list{
          margin-top: 0;
          padding: 24px 16px;

          .person_item{
            padding-left: 0;
          }
        }
      }
    }


    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 32px 20px;
      border-top: 1px solid #0000000f;
      gap: 16px;

      button {
        width: 48%;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1ABB78;
        color: white;
        border-radius: 10px;
        font-size: 18px;
        line-height: 25px;
        border: 0;

        &.cancel-btn {
          background-color: white;
          border: 1px solid #1ABB78;
          color: #1ABB78;
        }
        &.disabled {
          opacity: 0.5;
        }
      }
    }
}
