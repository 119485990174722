.list-item {
  width: 100%;
  height: 80px;
  border-radius: 16px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 8px;

  &__content {
    display: flex;
    align-items: center;
    width: calc(100% - 60px);

    .user_info {
      width: 25%;
      display: flex;
      align-items: center;

      &__img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 20px;
      }

      &__content {
        width: calc(100% - 68px);

        .name {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: black;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-decoration-line: underline;
        }

        .email {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #9898A6;
        }
      }
    }

  }

  &__actions {
    display: flex;
    width: 60px;

    .dropdown-content {
      .actions {
        .edit {
          padding: 16px;
        }
      }
    }

    .video_count {
      width: 196px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: black;
      text-decoration: none;
      cursor: pointer;


      svg {
        margin-right: 10px;
        color: #A8A8AC;
      }
    }
  }

  .edit_btn {
    border: 0;
    outline: 0;
    background-color: transparent;
  }
  &.new{
    .list-item__content{
      .item_col {
        width: 25%;
        //margin-right: 5%;
        svg {
          color: #A8A8AC;
        }
        .badge {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #1ABB78;
          width: 122px;
          height: 30px;
          background: rgba(26, 187, 120, 0.1);
          border-radius: 60px;
        }
      }
    }
  }
}
