.add_user_modal {
  flex-direction: column;
  width: 834px;

  .user-header {
    width: 100%;
    padding: 24px 32px;
    border-bottom: 1px solid #ECECEC;
    color: black;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg{
      cursor: pointer;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding: 32px;
  }

  &__content {
    width: calc(100% - 260px);
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .row-item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    margin-bottom: 20px;
  }

  &__tags {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    &__item {
      width: fit-content;
      padding: 3px 12px;
      border-radius: 60px;
      background-color: white;
      color: #1ABB78;
      border: 1px solid #D9D9D9;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      margin: 0 6px 12px 0;
      display: flex;
      align-items: center;

      svg {
        margin-left: 4px;
        width: 12px;
        height: 12px;
        cursor: pointer;

        path {
          fill: #1ABB78;
        }
      }

      .input-icon {
        position: absolute;
        right: 16px;
        top: 38px;
        cursor: pointer;
      }

      &.error {
        input {
          border: 1px solid red;
        }
      }
    }
  }

  .settings_tab {
    margin-top: 8px !important;
    .nav-tabs {
      border: 0;
      padding: 32px 32px 0 32px;
      border-top: 1px solid #D9D9D9;

      .nav-link {
        border: 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        opacity: 0.5;
        color: #000000;

        .count {
          font-size: 15px;
          line-height: 25px;
          opacity: 0.2;
          margin-left: 4px;
        }

        &.active {
          color: #000000;
          opacity: 1;
        }
      }
    }

    .tab-content {
      padding: 28px 20px 32px 20px;

      .user-info-settings {
        display: flex;
        align-items: center;
        justify-content: space-between;flex-wrap: wrap;

        span{
          font-size: 16px;
          font-weight: 400;
          line-height: 20.8px;
          text-align: left;
          color: #9898A6;
          width: 100%;
          padding: 0 0 28px 0;
        }

        .input_group{
          margin-bottom: 0;
        }
      }
    }
  }

  .connect_person{
    display: flex;
    span{
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      opacity: 0.6;
    }
  }

  .accordion {
    margin: 0;

    &.system {
      //border-top: 0;
      margin-top: 0;
    }
  }

  .input_group {
    display: flex;
    flex-direction: column;
    width: 48%;
    position: relative;

    .input-icon {
      position: absolute;
      right: 16px;
      top: 38px;
      cursor: pointer;
    }

    label {
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #000000;
      opacity: 0.5;
      margin-bottom: 5px;
    }

    input, textarea {
      padding: 12px;
      width: 100%;
      height: 48px;
      background: #F2F2FA;
      border-radius: 10px;
      border: 0;
      outline: 0;
    }

    textarea {
      height: 100%;
    }

    &.full {
      width: 100%;
      height: calc(100% - 200px);

      textarea {
        width: 100%;
        height: 100%
      }
    }

    &__error {
      color: red;
      font-size: 12px;
      padding-top: 4px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }

  input:-webkit-autofill {
    //display: none !important;
  }

  .input-form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .form-switch {
    display: flex;
    align-items: center;

    .form-check-label {
      color: #77777D;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-left: 10px;
    }

    .form-check-input {
      width: 36px !important;
      height: 22px;

      &:checked {
        background-color: #1abb78;
        border-color: #1abb78;
      }
    }
  }

  .buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px;
    margin-top: 32px;
    border-top: 1px solid #ECECEC;
    gap: 20px;

    button {
      width: 50%;
      height: 45px;
      display: block;
      border-radius: 10px;
      border: 1px solid #1ABB78;
      color: #1abb78;
      background: #FFF;
      outline: 0;
      font-weight: 450;
      font-size: 18px;
      line-height: 25px;

      &.disabled {
        opacity: 0.5;
      }
    }

    .save_btn {
      background: #1ABB78;
      color: white;
      display: flex !important;
      align-items: center;
      justify-content: center;
      gap: 20px;

      &.disabled {
        opacity: 0.5;
      }
      &:hover{
        background: rgba(41, 217, 144, 1);
        border-color: rgba(41, 217, 144, 1);
      }
      &:active{
        background: rgba(31, 176, 115, 1);
        border-color: rgba(31, 176, 115, 1);
      }
      &:disabled{
        background: rgba(170, 240, 211, 1);
        border-color: rgba(170, 240, 211, 1);
      }
    }
  }
}

.modal-content {
  .nav-tabs {
    border: 0;
    padding: 32px 32px 0 32px;
    border-top: 1px solid #D9D9D9;

    .nav-link {
      border: 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      opacity: 0.5;
      color: #000000;

      .count {
        font-size: 15px;
        line-height: 25px;
        opacity: 0.2;
        margin-left: 4px;
      }

      &.active {
        color: #000000;
        opacity: 1;
      }
    }
  }

  .tab-content {
    padding: 32px;
  }

  .protocol-list {
    display: flex;
    flex-wrap: wrap;
    max-height: 300px;
    overflow-y: auto;

    .protocol-item {
      width: 48%;
      height: fit-content;
      background-color: white;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      color: #46454E;
      padding: 16px 24px;
      border: 1px solid #D9D9D9;
      border-radius: 16px;
      position: relative;
      display: block;
      margin-right: 2%;
      margin-bottom: 16px;

      &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .left {
          width: calc(100% - 60px);

          .video-title {
            display: flex;
            align-items: center;
            width: 100%;

            .span {
              color: #7E7E7E;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }

            .video-link {
              display: block;
              align-items: center;
              text-decoration: none;
              color: #1ABB78;
              width: calc(100%);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-left: 6px;
            }
          }

          .name {
            font-weight: 600;
            font-size: 16px;
            line-height: 135%;
            color: #1D1929;
          }

          .text {
            margin-bottom: 8px;
            height: 54px;
            overflow: hidden;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; /* 18.2px */
            color: #46454E;
          }
        }

        .right {
          width: 60px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: end;

          .highlights-star {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
          }

          .time {
            position: absolute;
            bottom: 0;
            right: 0;
            font-weight: 500;
            font-size: 13px;
            line-height: 135%;
            text-align: right;
            color: #A8A8AC;
          }
        }
      }
    }

    .custom-scroll::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    .custom-scroll::-webkit-scrollbar-track {
      background: white;
      border: 1px solid #F2F2F2;
    }

    .custom-scroll::-webkit-scrollbar-thumb {
      background-color: lightgray;
      border-radius: 10px;
      border: 1px solid #F2F2F2;
      min-height: 40px;
      min-width: 40px;
    }
  }
}


@media only screen and (max-width: 1000px) {
  .modal-dialog {
    max-width: 800px !important;
    width: 100vw !important;

    .modal-content {
      width: 94% !important;
    }
  }
}



.error_main {
  display: flex;
  justify-content: flex-end;
  padding: 0 33px;
  margin-top: 10px;
  margin-bottom: -20px;
}


.error_main {
  display: flex;
  justify-content: flex-end;
  padding: 0 33px;
  margin-top: 10px;
  margin-bottom: -20px;
}