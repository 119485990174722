.persons {
  height: 100%;
  padding: 0 32px;
  display: flex;
  justify-content: center;
  &__wrap{
    width: 100%;
    max-width: 1200px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    &__left {
      display: flex;
      align-items: center;
    }
    &__right {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__add-user {
      background-color: #1ABB78;
      border: 0;
      outline: 0;
      color: white;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      border-radius: 10px;
      padding: 10px 11px;

      svg {
        path{
          fill: white;
        }
      }
      .person-icon{
        margin-left: 4px;
      }
      .plus-icon{
        margin-right: 4px;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin-right: 24px;
  }

  &__filters {
    display: flex;
    justify-content: right;

    .filter_item {
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      color: #000000;
      opacity: 0.35;
      margin-left: 4px;
      padding: 2px 10px;
      cursor: pointer;

      &.active {
        opacity: 1;
        background-color: white;
        border-radius: 50px;
      }
    }

    .form-select {
      width: 240px;
      margin-left: auto;
    }
  }

  &__list-header {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #F8F8F8;
    padding: 0 24px;
    text-transform: uppercase;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.24px;
    margin-bottom: 20px;

    &__content {
      width: calc(100% - 100px);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item_col {
        width: 14%;
        margin-right: 5%;
        opacity: 0.3499999940395355;
      }

      .user_info {
        width: 26%;
      }
    }

    &__actions {
      width: 100px;
      opacity: 0.3499999940395355;
    }
  }

  &__list {
    height: calc(100% - 240px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a8a7ad;
    }
  }

  .pagination {
    margin-top: 20px;
    padding: 0!important;
  }
}


.toasblock {
  display: flex;
  align-items: center;
  gap: 20px;

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: white;
    }

    &--text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #9898A6;
    }
  }
}