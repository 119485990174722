.user-info-item {
  width: 100%;
  display: flex;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #f2f3fc;
    margin-right: 8px;
  }

  .info-content {
    .name {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: black;
    }

    .email {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #9898A6;
    }
  }
}
