.protocol-editor{
  display: flex;
  align-items: flex-start;
  gap: 4px;
  width: 100%;

  //.tiptap-editor{
  //  width: 100%;
  //  min-height: 40px;
  //  padding: 5px;
  //}

  &__block{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }


  &--no-edit {
    .customParagraph{
      .customDetails{
        display: none;
      }
    }

    .customParagraph[data-visible="true"]{
      .customDetails{
        display: block;
      }
      .customSummary{
        &::after{
          transform: rotate(270deg);
        }
      }
    }

    .customSummary{
      color: rgb(152, 152, 166);
      cursor: pointer;
      text-decoration: underline;
      width: max-content;

      &::after{
        content: "❯︎";
        transform: rotate(90deg);
        display: inline-block;
        margin-left: 0.5ch;
        flex-shrink: 0;
        padding-left: 3px;
      }
    }

    .markdown-user, .video-time{
      cursor: pointer;
    }
  }

  .markdown-user, .video-time{
    color: #1abb78;
  }
}