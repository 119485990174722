.services{
  width: 60%;
  background-color: white;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  border: 1px solid rgba(205, 205, 205, 1);
  &__list{
    display: flex;
    flex-direction: column;
    gap: 20px;


    .service{
      display: flex;
      flex-direction: column;
      gap: 16px;

      &__item{
        display: flex;
        align-items: center;
        flex-direction: column;
        //gap: 12px;
        border: 1px solid #CDCDCD;
        padding: 12px 16px;
        border-radius: 8px;
        width: 100%;
        cursor: pointer;

        &--secretopus{
          width: 30px !important;
          height: 30px !important;
        }

        &--container{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          gap: 12px;
          margin-bottom: 6px;
        }

        &--info{
          display: flex;
          flex-direction: column;
        }
        &--info-text{
          display: flex;
          align-items: center;
          gap: 4px;
          flex-wrap: wrap;
        }
      }

      &__item.active{
        border-color: #1FB073;
      }


      svg{
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }
      button{
        margin-left: auto;
        .custom-button--label{
          font-weight: 450 !important;
        }
      }
    }
  }
}