.connect-calendar-modal {
  &__wrapper{
    .ant-modal-footer{
      display: none;
    }
    .ant-modal-close{
      display: none;
    }
    .ant-modal-content{
      padding: 24px;
    }
  }
  &__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;


    &--title{
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.01em;
    }
    &--description{
      color: #9898A6;
      font-size: 14px;
      font-weight: 450;
      line-height: 20px;
      text-align: center;
    }
    &--img{
      width: 256px;
      height: 256px;
    }
    button{
      width: 100%;
    }
  }

}