.telegram-block{
  width: 70%;
  padding: 24px 40px;

  &--title{
    padding-bottom: 16px;
    display: block;
  }
  &__top{
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid #CDCDCD;
    padding-bottom: 16px;
  }

  &__body{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 40px;
  }

  button{
    width: 186px;
  }
}