.members{
  height: calc(100% - 14px);
  padding: 0 32px;
  display: flex;
  justify-content: center;
  &__wrap{
    width: 100%;
    max-width: 1200px;
  }

  &__title{
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    color: #000000;
    margin: 24px 0;
  }

  &__list-header {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #F8F8F8;
    margin-bottom: 20px;
    padding: 0 24px;
    text-transform: uppercase;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.24px;

    &__content {
      width: calc(100% - 60px);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item_col {
        width: 25%;
        //margin-right: 5%;
        opacity: 0.3499999940395355;
      }
    }

    &__actions {
      width: 60px;
      opacity: 0.3499999940395355;
    }
  }

  &__list {
    height: calc(100% - 260px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a8a7ad;
    }
  }

  .pagination{
    margin-top: 20px;
    padding: 0!important;
  }
}
