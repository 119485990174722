.user-group {
  &__popover {
    z-index: 99999;
    .ant-popover-inner {
      padding: 0;
      background: inherit;
      box-shadow: 0px 4px 4px 0px #00000040;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    cursor: pointer;

    &--active {
      border: 2px solid #7EC6A6 !important;
    }

    &--avatar {
      background-color: white;
      border: 2px solid #F2F2F2;
    }

    &--avatar:not(:first-child) {
      margin-left: -12px;
    }

    &--more {
      margin-left: -12px;
      background-color: white;
      border: 2px solid #F2F2F2;
      font-size: 12px !important;
      font-weight: 500;
      line-height: 18px;
      color: #77777D !important;
    }
  }

  &__container {
    width: 100%;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(1, minmax(100px, 270px));
    padding: 16px 20px;
    background: white;
    border-radius: 12px;
    max-width: 580px;

    &--large {
      grid-template-columns: repeat(2, minmax(100px, 270px)) !important;
    }

    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 40%;
      flex-grow: 0;
      cursor: pointer;

      &--active {
        border: 2px solid #7EC6A6 !important;
      }

      &--avatar {
        background-color: white;
        border: 2px solid #F2F2F2;
      }

      &--icon {
        margin-left: auto;
        z-index: 900000 !important;
        svg {
          width: 18px;
          height: 18px;

          path {
            fill: gray
          }
        }
      }

      &--text {
        display: flex;
        flex-direction: column;
        gap: 2px;

        span:first-child {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          max-width: 180px;
        }

        span:last-child {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          color: #9898A6;
        }
      }

    }
  }
}

.icon-disabled {
  pointer-events: none;
  opacity: 0.5;
}