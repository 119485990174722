.channels-part.hide-list{
  width: 100%;
  border: none;
}
.channels-part {
  width: 327px;
  padding-right: 20px;
  border-right: 1px solid #E6E6E6;
  margin-top: 20px;
  cursor: default;

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    white-space: nowrap;
    margin-bottom: 20px;
  }

  .create-channel {
    background-color: transparent;
    border: 0;
    outline: 0;
    color: #1ABB78;
    display: flex;
    align-items: center;
    height: 26px;
    margin-bottom: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 22px;
    padding-left: 0;

    svg {
      margin-right: 14px;
    }
  }

  .list {
    max-height: 385px;
    overflow-y: auto;

    .channel-item {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      //padding-right: 40px;
      position: relative;
      border-radius: 0;


      .label {
        max-width: calc(100% - 76px);
        padding: 5px 13px;
        border-radius: 60px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: text;
      }

      .channel-actions {
        right: 10px;
        position: absolute;
        //opacity: 0;
        transition: all 0.2s;

        .dropdown-content {
          top: 30px;
        }
      }

      &.private{
        padding-bottom: 8px;
        border-bottom: 0.7px solid #E6E6E6;
      }

      &:hover {
        .channel-actions {
          opacity: 1;
        }
      }
    }

    .form-check {
      display: flex;
      align-items: center;
    }

    .form-check-input {
      width: 20px;
      height: 20px;
      border: 1px solid rgba(0, 0, 0, .25);
      margin-right: 16px;
      display: block;
      background-repeat: no-repeat;
      padding: 0;
    }

    .form-check-input:checked {
      background-color: #1ABB78;
      border-color: #1ABB78;
      width: 20px;
      height: 20px;
      padding: 0;
    }

    .form-check-input:focus {
      outline: 0;
      box-shadow: none;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a8a7ad;
    }
  }

  .create-input-wrapper {
    display: flex;
    align-items: center;

    input {
      width: 100%;
      display: flex;
      height: 32px;
      padding: 4px 12px;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 0;
      border-radius: 10px;
      border: 1px solid #D9DEE2;

      &:focus {
        outline: 0;
        border: 1px solid #1ABB78;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      padding-left: 12px;

      button {
        background-color: transparent;
        border: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin: 0;
      }

      .close-btn {
        color: #7F7F7F;
      }

      .save-btn {
        color: #1abb78;

        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }

  .buttons-wrapper {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    //margin-top: 24px;
    border-top: 1px solid #ECECEC;
    gap: 20px;
    display: none;

    button {
      width: 50%;
      height: 45px;
      display: block;
      border-radius: 10px;
      border: 1px solid #1ABB78;
      color: #1abb78;
      background: #FFF;
      outline: 0;
      font-weight: 450;
      font-size: 18px;
      line-height: 25px;

      &.disabled {
        opacity: 0.5;
      }
    }

    .save_btn {
      background: #1ABB78;
      color: white;

      &.disabled {
        opacity: 0.5;
      }
    }
  }

  &.simple{
    width: 100%;
    border-right: 0;
    padding: 0;

    .title{
      padding: 0 16px 12px 16px;
      border-bottom: 1px solid #ECECEC;
      margin-bottom: 24px;
    }

    .create-channel{
      padding-left: 16px;
    }

    .create-input-wrapper{
      padding: 0 16px;
    }

    .list {
      .channel-item {
        padding: 0 16px;
        border-radius: 0;

        &.private{
          padding-bottom: 8px;
          border-bottom: 0.7px solid #E6E6E6;
        }
      }
    }

    .buttons-wrapper{
      display: flex;
    }
  }
}
