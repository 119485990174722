.manage-video {
  font-style: normal;
  min-width: 1000px;
  //margin-top: 24px;
  //padding: 0 32px;
  width: 100%;

  .page-title{
    color: #000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -.01em;
    line-height: 28px;
    margin: 10px 0 20px;
    width: 100%;
  }

  .empty-info{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 100px;

    &--text{
      color: #9898A6;
      font-size: 16px;
      font-weight: 450;
      line-height: 20px;
      text-align: center;
    }
  }
  &__loader{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }

  &-wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
    .pagination{
      padding: 0 !important;
      margin-bottom: 44px;
    }
    .page-title{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0 !important;
      margin: 0 !important;
    }
    @media screen and (min-width: 1440px){
      .manage-video, .pagination{
        padding: 0 !important;
      }

      .page-title, .manage-video{
        max-width: 1200px;
        width: 100%;
      }
    }
  }

  hr {
    border: 1px solid #e8ebee;
    width: 100%;
    // margin-top: 23px;
  }

  .channels {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .no-channels {
    color: #1ABB78;
    white-space: nowrap;
    cursor: pointer;
  }

  .category-marketing {
    color: #c072ff;
    background: #f1e0ff;
    border-radius: 5px;
    padding: 2px 11px;
    margin-right: 5px;
  }

  .category-sales {
    color: #ff6713;
    background: #ffebe0;
    padding: 2px 11px;
    border-radius: 5px;
  }

  .table-manage-video {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    border-spacing: 0 10px;
    border-collapse: separate;
    margin-bottom: 20px;

    .table-header {
      width: 100%;
      display: flex;
      padding: 10px 24px;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      border: 1px solid #E6E6E6;
      background: #F8F8F8;

      margin-bottom: 16px;

      &__item{
        display: flex;
        align-items: center;
        gap: 4px;

        &--title{
          color: #A6A6A6;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          text-transform: uppercase;
        }
      }
    }

    .table-row {
      width: 100%;
      display: flex;
      height: 72px;
      padding: 12px 24px;
      justify-content: space-between;
      align-items: center;
      border-radius: 16px;
      background: #FEFEFE;
      margin-bottom: 12px;

      .user-group__list--avatar, .user-group__list--more{
        width: 36px !important;
        height: 36px !important;
      }

      .text {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
      }

      .light {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #9898A6;
      }

      .clickable {
        //text-decoration: underline;
        color: #212121;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-decoration-line: underline;
        cursor: pointer;
        width: fit-content;

        .link-btn {
          //font-weight: 450;
        }

        .owner-mark {
          overflow: hidden;
          color: #9898A6;
          text-overflow: ellipsis;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          text-decoration-line: underline;
        }

        &:hover {
          text-decoration-color: #1abb78;
          color: #1abb78;
        }
      }

      .active {
        color: #1abb78;
      }
    }

    .user-icons {
      justify-content: start;
    }

    .description_input {
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        border: 0;
        width: calc(100% - 40px);

        &:focus {
          border: 0;
          outline: 0;
        }
      }

      svg {
        margin-right: 10px;
        width: 12px;
        height: 12px;
        cursor: pointer;
      }
    }

    .channels-btn {
      width: fit-content;
      max-width: 100%;
    }

    .channel-item {
      padding: 5px 12px;
      border-radius: 60px;
      white-space: nowrap;
      position: relative;
      width: 100%;
      //overflow: hidden;
      text-overflow: ellipsis;
    }

    .channels-count {
      background: #FFFFFF;
      border: 1px solid #F2F2F2;
      border-radius: 60px;
      padding: 5px 12px;
      color: #9898A6;
      margin-left: 4px;
      cursor: pointer;
    }

    .manage-channels {
      width: 280px;
      position: absolute;
      top: 56px;
      right: 0;
      z-index: 1000;
      background-color: white;
      border-radius: 12px;
      box-shadow: 0 5px 15px rgba(22, 22, 26, 0.1);

      .title {
        padding: 16px;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        white-space: nowrap;
        border-bottom: 1px solid #0000000f;
      }

      .list {
        border-bottom: 1px solid #0000000f;
        padding: 16px;
        height: 260px;
        overflow-y: auto;

        .channel-item {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          position: relative;

          .label {
            padding: 5px 13px;
            border-radius: 60px;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .channel-menu {
            position: absolute;
            right: 0;
            cursor: pointer;
            opacity: 0;

            .dropdown-content {
              top: 24px;
            }
          }

          .editing-channel {
            width: calc(100% - 50px);

            &:focus {
              border: 1px solid #D9D9D9;
              outline: 0;
            }
          }

          &:hover {
            .channel-menu {
              opacity: 1;
            }
          }
        }

        .form-check {
          display: flex;
          align-items: center;
        }

        .form-check-input {
          width: 20px;
          height: 20px;
          border: 1px solid rgba(0, 0, 0, .25);
          margin-right: 16px;
        }

        .form-check-input:checked {
          background-color: #1ABB78;
          border-color: #1ABB78;
          width: 20px;
          height: 20px;
        }

        .form-check-input:focus {
          outline: 0;
          box-shadow: none;
        }

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #a8a7ad;
        }
      }

      .save-btn {
        width: calc(100% - 32px);
        height: 44px;
        background-color: #1ABB78;
        color: white;
        border: 0;
        //opacity: 0.5;
        border-radius: 10px;
        margin: 16px;

        &.disabled {
          opacity: 0.5;
        }
      }
    }

    .channels_list {
      min-width: 100px;
      background-color: white;
      border-radius: 12px;
      box-shadow: 0 5px 15px rgba(22, 22, 26, 0.1);
      padding: 14px;

      .channel_item {
        padding: 5px 13px;
        border-radius: 60px;
        margin-bottom: 8px;
        width: fit-content;
      }
    }

    .actions-wrapper {
      position: relative;

      .actions {
        //width: 100%;
        //position: absolute;
        //top: 56px;
        //right: 0;
        //z-index: 1000;
        //background-color: white;
        //border-radius: 12px;
        //box-shadow: 0 5px 15px rgba(22, 22, 26, 0.1);

        div {
          display: flex;
          align-items: center;
          padding: 16px;
          cursor: pointer;

          svg {
            margin-right: 14px;
          }
        }

        .edit {
          border-bottom: 1px solid #0000000f;
        }

        .refresh {
          border-bottom: 1px solid #0000000f;

          svg {
            width: 20px;
            height: 20px;

          }
        }

        .delete {
          color: #DB1F26;
        }
      }

      .menu-wrapper .dropdown-content {
        top: 28px;
      }
    }

  }

  .video_actions {
    background-color: transparent;
    border: transparent;

    &:after {
      display: none;
    }

    &:hover {
      background-color: transparent;
      border: transparent;
    }

    &:active {
      background-color: transparent;
      border: transparent;
    }
  }

  .btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active {
    background-color: transparent;
    border-color: transparent;
  }

  .artifacts {
    display: flex;
    justify-content: center;

    &__wrap {
      width: 100%;
      overflow: hidden;
      max-width: 1200px;

      &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 40px;

        &--title {
          font-size: 24px;
          font-weight: 450;
          line-height: 32px;
        }

      }

      &__container{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;

        .preview{
          width: 100%;
          max-width: 520px;

          &__descriptions{
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding: 0 14px;

            &--text{
              font-size: 16px;
              font-weight: 450;
              line-height: 20px;
              color: rgba(152, 152, 166, 1);
            }

            &--list{
              padding-inline-start: 17px;

              li::marker{
                color: rgba(152, 152, 166, 1);
              }
            }
          }

          &--img{
            width: 100%;
            height: 135px;
          }
        }
      }
    }
  }

}

.video_edit {
  &__close {
    border: 0;
    background-color: transparent;
  }

  &__content {
    &__label {
      margin-bottom: 16px;
      font-weight: 600;
    }

    input {
      width: 100%;
      height: 46px;
      border: 1px solid #dee2e6;
      border-radius: 4px;
      padding: 0 12px;
      outline: 0;
    }
  }
}

.admin_actions {
  &__close {
    border: 0;
    background-color: transparent;
  }

  &__content {
    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      svg {
        path {
          fill: #696c6f;
        }
      }

      p {
        margin-bottom: 0;
        margin-left: 10px;
        font-weight: 600;
        font-size: 16px;
      }
    }

    &__buttons {
      button {
        margin-right: 14px;
      }
    }
  }
}

.upload_video_answer {
  position: fixed;
  bottom: 30px;
  right: 30px;
}


.popover_edit-video{
  .ant-popover-inner{
    padding: 0;
    border-radius: 12px;
  }

  .ant-popover-inner-content{
    width: 160px;
  }
  &--settings{
    cursor: pointer;
    margin-left: auto;
  }

  &--item{
    display: flex;
    align-items: center;

    padding: 16px;
    cursor: pointer;
    color: black;
    font-style: normal;
    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    gap: 14px;
    max-width: 160px;
    width: 100%;

    &:last-child{
      border-radius: 0 0 12px 12px !important;
    }
    &:first-child{
      border-radius: 12px 12px  0 0 !important;
    }

    > svg {
      width: 16px;
      height: 16px;
      margin: 0 !important;
    }

    &:hover{
      background-color: #E9F9F2;
      border-color: #E6E6E6;
      border-radius: 12px;
    }
    &:active{
      background-color: #BBEBD7;
      border-color: #E6E6E6;
    }

    &-wrap{
      padding: 16px 4px 16px 16px;
      cursor: pointer;
      justify-content: space-between;
    }

    &-arrow{
      transform: rotate(90deg);
      margin-left: auto;
      path{
        fill: black;
      }
    }
  }
}