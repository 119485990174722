.extension-banner{
  width: 100%;
  padding: 14px 32px;
  background: #DDF5EB;
  display: flex;
  align-items: center;
  gap: 14px;

  &__badge{
    border-radius: 4px;
    background: #1ABB78;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    height: 28px;
    width: min-content;

    svg{
      width: 16px;
      height: 16px;
      color: white;
    }
  }

  &__text{
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;

    span{
      white-space: nowrap;
    }
    span:last-child{
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
}
}

&__buttons{
margin-left: auto;
display: flex;
align-items: center;
gap: 24px;
}

}