@import "../_mixins.scss";

.contacts-wrapper {
  margin: 0;
  padding: 0 32px 24px 32px;

  &__tooltip {
    max-width: 100%;

    &--wrap {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &-title {
        color: #F5F5F5;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &-description {
        color: #F5F5F5;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 8px;
      }
    }
  }

  .contact-header {
    position: relative;
    padding: 20px;

    .arrow-icon {
      position: absolute;
      top: 0;
      right: 0;
      transform: rotate(180deg) translateY(0%);
      margin: 0 !important;
    }
  }

  @include accordion-mixin();
}

