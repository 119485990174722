@import "../../../styles/mainVariables";

.searcher-wrapper {
  width: 100%;
  min-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 32px 12px calc($menuWidth + 32px);
  //position: absolute;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.2s;

  &.full {
    padding-left: calc($menuWidthSmall + 32px);
  }
}

.search-input-wrapper {
  ////width: 300px;
  //width: 42%;
  //position: relative;
  //margin-right: 24px;

  .dropdown-content {
    left: 0;
    //transform: translateX(50%);

    .search-content {
      display: flex;
      gap: 20px;
      flex-direction: column;
      max-height: 80vh;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #a8a7ad;
      }
    }
  }
}

.highlight-text {
  background-color:  #1ABB7866;
}

.search {
  &__buttons {
    width: 58%;
    //width: calc(100% - 320px);
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    color: #ffffff;
    min-height: 100%;
    height: 35px;
    position: relative;

    &-record {
      font-weight: 600;
      line-height: 20px;
      margin-right: 16px;
    }

    .custom-button--label{
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
    .custom-button{
      width: 110px;
    }

    &-import {
      width: 150px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-overflow: ellipsis;
      color: #1ABB78;
      border: 2px solid #1ABB78;
      box-sizing: border-box;
      border-radius: 10px;
      background-color: transparent;
      cursor: pointer;

      .spinner-border {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .url-wrapper {
      width: calc(100% - 320px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 24px;
      border-radius: 10px;
      //border: 1.5px solid #E0E0E3;
      background: #E6E6E6;
      padding: 8px 16px;
      position: relative;
      z-index: 1002;
      height: 36px;
      transition: all 0.2s;
      cursor: pointer;

      input {
        background: transparent;
        border: 0;
        width: calc(100% - 30px);
        color: #9898A6;

        &:focus {
          border-color: transparent;
          outline: 0;
          box-shadow: none;
        }
        &::placeholder {
          color: #9898A6;
        }
      }

      .start {
        background: #1ABB78;
        color: white;
        border: 0;
        display: flex;
        width: 94px;
        height: 28px;
        padding: 10px 12px;
        line-height: 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 6px;
        position: absolute;
        top: 50%;
        right: 6px;
        transform: translateY(-50%);
        opacity: 0;
        transition: all 0.3s;
        z-index: -1;
      }

      &.error {
        border-color: red;
      }
    }

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      background: black;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      transition: all 0.2s;
    }

    &.open-url {
      .url-wrapper {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;

        .start {
          opacity: 1;
          z-index: 1001;
        }
      }

      .overlay {
        opacity: 0.5;
        z-index: 1001;
      }
    }
  }
}