.texts-search-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 232px;
  height: 40px;
  padding: 14px 10px;
  border-radius: 20px;
  background: #FFF;
  position: absolute;
  top: 0;
  right: 24px;

  input {
    width: 100%;
    border: #1ABB78;
    background-color: transparent;
    font-size: 13px;

    &:focus {
      outline: 0;
    }
  }

  svg {
    flex-shrink: 0;
  }

  .arrows {
    display: flex;
    flex-direction: column;
    margin-right: 4px;

    svg {
      width: 10px;
      height: 10px;
      cursor: pointer;

      &:first-child {
        transform: rotate(180deg);
      }

      path{
        fill: #1ABB78;
      }
    }
  }

  .count-wrapper {
    color: #77777D;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.13px;
  }
}
