.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.protocol-block{
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 4px;
  padding: 0 8px;
  margin-top: 16px;
  user-select: auto !important;

  &--plus{
    cursor: pointer;
    width: 20px;
    height: 20px;
    color: #7F7F7F;
    flex-shrink: 0;
  }

  &--start{
    width: 20px;
    height: 20px;
    cursor: pointer;
    path{
      stroke: #1abb78;
    }
  }

  &--start.fill{
    path{
      fill: #1abb78;
    }
  }

  &--file{
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: #1abb78;

  }

  &:hover{
    .setting-protocol-popover, .protocol-block--hover{
      opacity: 1;
    }
  }
  //.hidden{
  //  .setting-protocol-popover, .protocol-block--hover{
  //    display: none;
  //  }
  //}
  .setting-protocol-popover, .protocol-block--hover{
    opacity: 0;
  }

  &__actions{
    min-width: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
}

.tiptap-editor{
  width: 100%;
  min-height: 40px;
  padding: 0 5px 15px 5px;

  &--edit{
    background: #E7F2FE;
  }

    ul[data-type="taskList"] {
      list-style: none;
      margin-left: 0;
      padding: 0;
      display: block !important;
      margin: 0;

      li {
        align-items: flex-start;
        display: flex;

        > label {
          flex: 0 0 auto;
          user-select: none;
        }
      }

      input[type="checkbox"] {
        cursor: pointer;
      }
      input:checked{
        background: #1abb78;
      }
      input {
        appearance: none;
        position: relative;
        background: white;
        &::after {
          border: 1px solid #fff;
          border-width: 0 2px 3px 0;
          content: "";
          display: block;
          height: 11px;
          left: 7px;
          position: absolute;
          top: 3px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          width: 6px;
        }
      }

      ul[data-type="taskList"] {
        margin: 0;
      }
  }
}
.setting-protocol-popover{
  .ant-popover-inner{
    padding: 0;
  }
}


/* Give a remote user a caret */
.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #0d0d0d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4em;
  user-select: none;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 10px;
  white-space: nowrap;
  opacity: 0.9;

  img{
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: white;
  }
}

.label-reverse{
  div{
    right: -1px;
    left: unset;
  }
}

.label-top-reverse{
  div{
    top: unset;
  }
}

.col-group {
  display: flex;
  flex-direction: row;
  height: 100vh;

  @media (max-width: 540px) {
    flex-direction: column;
  }
}

.column-half {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  &:last-child {
    border-left: 1px solid var(--gray-3);

    @media (max-width: 540px) {
      border-left: none;
      border-top: 1px solid var(--gray-3);
    }
  }

  & > .main-group {
    flex-grow: 1;
  }
}

/* Collaboration status */
.collab-status-group {
  align-items: center;
  background-color: var(--white);
  border-top: 1px solid var(--gray-3);
  bottom: 0;
  color: var(--gray-5);
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  font-weight: 400;
  gap: 1rem;
  justify-content: space-between;
  padding: 0.375rem 0.5rem 0.375rem 1rem;
  position: sticky;
  width: 100%;
  z-index: 100;

  button {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    background: none;
    display: -webkit-box;
    flex-shrink: 1;
    font-size: 0.75rem;
    max-width: 100%;
    padding: 0.25rem 0.375rem;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::before {
      background-color: var(--color);
      border-radius: 0.375rem;
      content: "";
      height: 100%;
      left: 0;
      opacity: 0.5;
      position: absolute;
      top: 0;
      transition: all 0.2s cubic-bezier(0.65,0.05,0.36,1);
      width: 100%;
      z-index: -1;
    }

    &:hover::before {
      opacity: 1;
    }
  }

  label {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    gap: 0.375rem;
    line-height: 1.1;


    &::before {
      border-radius: 50%;
      content: " ";
      height: 0.35rem;
      width: 0.35rem;
    }
  }

  &[data-state="online"] {
    label {
      &::before {
        background-color: var(--green);
      }
    }
  }

  &[data-state="offline"] {
    label {
      &::before {
        background-color: var(--red);
      }
    }
  }
}

