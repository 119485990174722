.add-video-to-chain {
  .modal-content {
    width: 900px;
    height: 80vh;

    &__loader{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 24px 32px;
      border-bottom: 1px solid #ECECEC;

      &--title {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 0;
      }

      svg {
        cursor: pointer;
      }
    }

    &__wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: baseline;
      gap: 20px;
      flex-wrap: wrap;
      padding: 12px 32px 24px 32px;
      overflow: auto;
    }

    .video {
      &--poster {
        width: 100%;

        &-img {
          height: 108px;
          position: relative;
        }

        .video__wrap::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 95%;
          background: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный цвет */
          z-index: 1;
          pointer-events: none;
          border-radius: 10px;
        }
      }


      &__info-date {
        flex-wrap: nowrap;
        white-space: nowrap;
      }

      &__time {
        left: 10px;
        width: max-content;
        z-index: 1;
      }

      &__channels {
        z-index: 1;
      }
    }

    &__footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 32px;
      margin-top: 32px;
      border-top: 1px solid #ECECEC;
      gap: 20px;
      margin-top: auto;

      button {
        width: 50%;
        height: 45px;
        display: block;
        border-radius: 10px;
        border: 1px solid #1ABB78;
        color: #1abb78;
        background: #FFF;
        outline: 0;
        font-weight: 450;
        font-size: 18px;
        line-height: 25px;

        &.disabled {
          opacity: 0.5;
        }

        &:hover {
          background-color: #1ABB781A;
        }

        &:active {
          background-color: #1ABB7833;
          color: #148F5C;
        }
      }

      .save_btn {
        background: #1ABB78;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: rgba(41, 217, 144, 1);
          border-color: rgba(41, 217, 144, 1);
        }

        &:active {
          background: rgba(31, 176, 115, 1);
          border-color: rgba(31, 176, 115, 1);
        }

        &:disabled {
          background: rgba(170, 240, 211, 1);
          border-color: rgba(170, 240, 211, 1);
        }

        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }

  .modal-video {
    width: 23%;
    position: relative;

    .form-check-input {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 10;
      border: 1.5px solid #A8A8AC;
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-top: 0;
      background: inherit;

    }

    .form-check-input:checked {
      background-color: #1ABB78;
      border-color: #1ABB78;
    }

    .form-check-input:focus {
      outline: 0;
      box-shadow: none;
    }

    .form-check {
      margin-right: 30px;
    }

    .video__channels--badge{
      max-width: 120px;
    }
  }
}