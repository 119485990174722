.tooltip-settings-auto-chain{
    width: 24px;
    height: 24px;
    margin-left: auto;
    cursor: pointer;


  &--manual{
    margin-left: auto;
    cursor: pointer;
      path{
        fill: #A8A8AC ;
      }
  }
}