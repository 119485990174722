@import "../styles/mainVariables";

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: Regular, serif;
  background-color: #F7F8F9;
  scroll-behavior: auto !important;
}
* {
  outline: 0;
}

p{
  margin: 0 !important;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
}

#root {
  //overflow: auto;
  //background-color: #F2F2F2;
}

.upload_video_answer {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 10000;
}


.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

.allotment-pane{
  height: 100%;
}

.dashboard {
  display: flex;
  min-height: calc(100vh - 20px);
  min-width: 1200px;
  justify-content: flex-start;
  background-color: #F2F2F2;

  .main-container {
    //width: 100%;
    min-height: 100vh;
    height: fit-content;
    //padding-left: 208px;
    flex-grow: 1;
    //margin-top: 60px;
    overflow: auto;
    transition: all 0.2s;

    .page-title{
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.01em;
      color: black;
      width: 100%;
      margin: 10px 0 20px 0;
    }

    .pagination {
      padding: 0 32px;
    }

    //&.full{
    //  padding-left: $menuWidthSmall;
    //}
  }
}

.radio_list {
  width: 100%;
  margin-bottom: 20px;

  .form-check-input:checked {
    background-color: #1ABB78;
    border-color: #1ABB78;
  }

  .form-check-input:focus {
    outline: 0;
    box-shadow: none;
  }

  .form-check {
    margin-right: 30px;
  }
}


details > summary{
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
  color: rgba(152, 152, 166, 1);
}

.markdown-paragraph + .markdown-checkbox {
  margin-top: -1rem;
}

.markdown-checkbox input[type=checkbox] {
  display: none;
  cursor: pointer;
}

.markdown-checkbox + br {
  display: none;
}

.markdown-checkbox{
  margin-bottom: 4px;
  label{
    position: relative;
  }

  input[type=checkbox]{
    border: 1px solid rgba(0, 0, 0, 0.25);
  }

  input[type=checkbox]:checked + label:before{
    background-color: #1ABB78;
    border: 1px solid #1ABB78;
  }

  input[type=checkbox] + label:before {
    content:'';
    -webkit-appearance: none;
    border: 1px solid #dedede;
    border-radius: 4px;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  input[type=checkbox]:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 8px;
    width: 6px;
    height: 11px;
    border: 1px solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}

details > summary::marker{
  content: '';
}

details > summary::after {
  content: "\276F︎";
  transform: rotate(90deg);
  display: inline-block;
  margin-left: 0.5ch;
  flex-shrink: 0;
  padding-left: 3px;
}

details[open] > summary::after {
  transform: rotate(270deg);
  padding-right: 3px;
  padding-left: 0;
}

.details.open {
  .details-hidden-text {
    display: block;
  }
}
  .details{
    .details-hidden-text{
      display: none;
    }
}

h1 + details,
h2 + details,
h3 + details,
h4 + details,
h5 + details,
h6 + details,
p + details {
  margin-top: -0.5rem;
}

.markdown-paragraph + details{
  margin-top: -1rem;
}


.custom-scroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: white;
  border: 1px solid #F2F2F2;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 10px;
  border: 1px solid #F2F2F2;
  min-height: 40px;
  min-width: 40px;
}


.video-preview-block{
  display: flex;
  flex-direction: column;
  padding: 12px 16px;

  &--title{
    font-size: 14px;
    font-weight: 450;
    line-height: 16px;
    text-align: left;
    color: #D0D0D0;
    padding-bottom: 12px;
    border-bottom: 1px solid #D0D0D0;
  }
  &--text{
    font-size: 14px;
    font-weight: 450;
    line-height: 18px;
    text-align: left;
    color: #D0D0D0;
    padding-top: 12px;

    .markdown-paragraph{
      padding-bottom: 1rem;
    }
  }
}

.ant-tooltip-inner{
  background: rgba(24, 24, 24, 0.87);
}

.ant-modal-mask{
  z-index: 1010 !important;
}
.ant-modal-wrap{
  z-index: 1011 !important;
}