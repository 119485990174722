.calendars-block{
  width: 70%;
  padding: 24px 40px;

  &--title{
    padding-bottom: 16px;
    display: block;
  }

  &__top{
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid #CDCDCD;
    padding-bottom: 16px;
  }

  &__bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding-bottom: 26px;
    padding-top: 16px;
  }

  &__info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #CDCDCD;
    padding-bottom: 16px;
    gap: 20px;
    &--text{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      p{
        text-transform: unset !important;
      }
    }
  }

  &__body{
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--list{
      display: flex;
      flex-direction: column;
      gap: 12px;

      &-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .label{
        padding: 4px 13px;
        border-radius: 60px;
      }

      input{
        margin-top: 3px;
      }
    }
  }
}