.video{
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;

  &__wrap{
    position: relative;
  }

  &--poster{
    object-fit: fill;
    border-radius: 10px;
    width: 22%;
    max-width: 210px;
    position: relative;
    flex-grow: 1;

    @media screen and (min-width: 1440px){
      object-fit: cover;
    }

    &-img{
      width: 100%;
      height: 120px;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
      border-radius: 12px;
    }
  }


  &--loader{
    position: absolute;
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--name{
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &__info{
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
    flex-direction: row !important;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
    &--wrap{
      max-width: 55%;
      flex-direction: column;
      display: flex;
      gap: 4px;
    }
    &-date{
      display: flex;
      flex-wrap: wrap;
      color: rgba(152, 152, 166, 1);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
    }
  }
  &__channels {
    position: absolute;
    bottom: 12px;
    left: 8px;
    display: flex;
    align-items: center;
    gap: 4px;

    &--badge{
      font-weight: 450;
      font-size: 14px;
      line-height: 16px;
      color: white;
      border-radius: 6px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.6);
      max-width: 148px;
      white-space: nowrap;
      overflow: hidden;
      @media screen and (max-width: 1440px){
        max-width: 120px;
      }

      @media screen and (max-width: 1290px){
        max-width: 100px;
      }

      svg {
        width: 13px;
        height: 13px;
        flex-shrink: 0;
      }

      p{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &--chevron{
        display: flex;
        align-items: center;
        gap: 2px;
        margin-left: 8px;

        &-icon{
          path{
            fill: var(--chain_сolor);
          }
        }

        span{
          color: var(--chain_сolor);
        }

        //svg:last-child {
        //  margin-left: 2px;
        //}
      }
    }

    .dote {
      width: 7px;
      height: 7px;
      background-color: #33BCC5;
      display: block;
      border-radius: 50%;
      margin-right: 4px;
      flex-shrink: 0;
    }
  }
  &__time {
    font-weight: 450;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    border-radius: 10px;
    padding: 6px 8px;
    position: absolute;
    margin: 0;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.4);

    p {
      margin: 0;
    }
  }
}

.user-icons__more, .user-icons__item{
  width: 28px;
  height: 28px;
}

.channels_count {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.60);
  padding: 4px 8px;
  font-weight: 450;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin: 0;
  cursor: pointer;
}

.channels_list {
  min-width: 100px;
  border: 0;
  box-shadow: 0 5px 15px rgba(22, 22, 26, 0.1);
  padding: 14px;
  position: absolute;
  top: 126px;
  left: 0;
  background-color: white;
  border-radius: 10px;
  z-index: 100;

  .channel_item {
    color: #4F40F7;
    padding: 5px 10px;
    border-radius: 60px;
    margin-bottom: 8px;
  }
}

.ant-popover-inner-content{
  .chain_list {
    width: 148px;
    border: 0;
    border-radius: 10px;
    z-index: 100;
    background: #00000099;
    padding: 8px;

    &__wrap{
      .open-bottom{
        background-color: inherit;
        right: var(--distanceRight);
        z-index: 1000;

        .chains-dropdown{
          background: white;
          border-radius: 12px;
        }
      }
    }
    &__wrap{
      .open-top{
        background-color: inherit;
        margin-bottom: -18px !important;
        left: 0;

        .menu-item{
          background-color: white;
        }
      }
    }
    .chain_item:not(:last-child){
      svg {
        width: 12px;
        height: 12px;

        path {
          fill: var(--chain_сolor);
        }
      }
    }

    .chain_item:not(:last-child){
      margin-bottom: 4px;
    }

    .chain_item {
      color: white;
      padding: 4px 0;
      text-decoration: none;
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      height: 18px;

      svg{
        flex-shrink: 0;
      }

      p{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }


    }
  }
}

.test{
  .ant-popover-inner{
    background-color: inherit;
    box-shadow: none;
    padding: 0;
  }
}

.popover_manage-chain{
  .ant-popover-inner{
    padding: 0;
  }
}