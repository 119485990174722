.note-block{
  border-radius: 12px;
  border: 1px solid #1ABB7880;
  background: white;
  flex: 100%;
  overflow: hidden;

  .actions{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    gap: 5px;
  }

  &__header{
    width: 100%;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-bottom: 1px solid #E6E6E6;

    a{
      text-decoration: none;
    }


    &--wrap{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      span:first-child{
        text-overflow: ellipsis;
        overflow: hidden;
      }
      span{
        white-space: nowrap;
      }
    }

    &--video{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      cursor: pointer;

      svg{
        color: #828282;
      }

      span{
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        width: 155px;
      }

      &:hover{
        svg, span{
          color: #1FB073 !important;
        }
      }
    }
  }
  &__body{
      width: 100%;
      padding: 8px 16px 16px 16px;

    &--content{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-height: 316px;
        overflow: auto;

      details{
        margin-top: -0 !important;
      }

      &--markdown{
        overflow: hidden;

        .markdown-paragraph{
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &--item{
        display: flex;
        justify-content: flex-start;
        gap: 10px;

        .markdown-paragraph + .markdown-checkbox{
          margin: 0 !important;
          cursor: not-allowed;
          opacity: 0.5; /* Чекбокс станет полупрозрачным */
          pointer-events: none; /* Отключает взаимодействие с элементом */
        }

        .star{
          width: 16px;
          height: 16px;
          flex-shrink: 0;

          path{
            fill: #1ABB78;
            stroke: #1ABB78;
          }
        }

        .action-icon{
          width: 16px;
          height: 16px;
          flex-shrink: 0;
        }
      }

      .action{
        display: flex;
        flex-direction: column;
      }

        .markdown-text-person, .markdown-text-video-time {
          color: #37A87B;
          font-size: 14px;
          line-height: 20px;
          cursor: pointer;
          font-weight: 500;
          &:hover{
            color:#45C090
          }
        }

      span, h6, p{
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}




