.chat-wrapper{
  &__header{
    padding: 16px 24px;
    border-radius: 8px 8px 0 0;
  }
}

.ai-chats{
  background-color: white;
  border-radius: 12px;

  &__header{
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #E6E6E6;
    padding: 16px 24px;

    &--burger{
      cursor: pointer;
      color: #1abb78;
      width: 20px;
      height: 20px;
    }

    &--edit-icon{
      cursor: pointer;
      color: #1abb78;
      width: 20px;
      height: 20px;
    }

    &--right-block{
      margin-left: auto;
      display: flex;
      align-items: center;
    }

    &--switcher{
      display: flex !important;
      align-items: center;
      justify-content: space-around;
      flex-direction: row-reverse;
      gap: 15px;
      padding: 0;
      width: 100px;
      padding: 0 !important;

        input{
          cursor: pointer;
          height: 16px;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"><circle r="3" fill="white"/></svg>') !important;
          margin-bottom: 3px !important;

        }
        .form-check-input{
          background-color: rgba(168, 168, 172, 1);
        }

        .form-check-input:checked{
          background-color: #1ABB78;
          border-color: #1ABB78;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"><circle r="3" fill="%23fff"/></svg>') !important;
        }
        .form-check-input:focus{
          box-shadow: none;
          border-color: #9898A6 !important;
        }

        label{
          font-size: 14px;
          font-weight: 450;
          line-height: 16px;
        }
    }
  }

  &__container{
    display: flex;
    align-items: flex-start;
    overflow: hidden;
  }

  &__left-side{
    transition: width 0.5s ease-in-out;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 12px 0 0 12px;
    box-shadow: 5px 0px 15px 0px #16161A1A;
    background-color: #F2F4F7;
    z-index: 1;
  }
  &__right-side{
    width: 600px;
  }
}

.chat {
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    border-radius: 12px 12px 0 0;

    &--title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: white;
    }

    svg {
      width: 24px;
      height: 24px;
      transform: rotate(-90deg);
      cursor: pointer;

      path {
        fill: white;
      }
    }

    &--message {
      background: #1ABB78;
    }

    &--search {
      background: #F2F2F2;

      span {
        color: black;
      }

      svg {
        path {
          fill: #7F7F7F;
        }
      }
    }
  }

  &__footer {
    width: 100%;
    padding: 16px 24px 20px 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-top: 0.6px solid #E6E6E6;
    background-color: white;
    border-radius: 0 0 12px 12px;

  }

  &__tabs {
    display: flex;
    width: max-content;
    padding: 2px;
    border-radius: 6px;
    background: #E6E6E6;
    cursor: pointer;
    gap: 4px;

    &_icon-search {
      width: 16px;
      height: 16px;

      path {
        stroke: #A8A8AC;
      }
    }

    &_icon-octopus {
      width: 16px;
      height: 16px;
    }

    &-octoppus {
      padding: 2px 14px !important;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 14px;
    }

    &--active {
      background: #1ABB78;
      border-radius: 4px;
      box-shadow: 2px 2px 5px 0px #0000004D;

      .chat__tabs_icon-search {
        path {
          stroke: white;
        }
      }

      .chat__tabs_icon-octopus {

      }
    }
  }

  .chat-input {
    width: 100%;
    display: flex;
    align-items: flex-end;
    background: #EFEFEF;
    border-radius: 8px;
    padding: 7px 0px 7px 12px;
    gap: 8px;
    border: 1px solid #EFEFEF;
    position: relative;

    &:focus,
    &:focus-within {
      border: 1px solid #1ABB78;
    }

    &__left-icon {
      width: 16px;
      height: 16px;
    }

    &__right-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
      right: 20px;

      &--active {
        cursor: pointer;
        position: absolute;
        right: 20px;

        path {
          fill: #1ABB78;
        }
      }
    }

    .cssload-loader{
      position: absolute;
      right: 20px;
    }

    &__input {
      background: inherit;
      border: none;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      width: 100%;
      max-height: 120px;
      resize: none;
      height: 20px;
      overflow: auto !important;
      padding: 0;
      padding-right: 40px;

      &:active, &:focus {
        border: none;
        outline: 0;
      }
    }
  }
}