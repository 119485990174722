.texts {
  height: 100%;
  overflow-y: hidden;
  position: relative;
  margin-left: 16px;
  padding-right: 4px;

  &__title{
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    padding-left: 40px;
    margin-bottom: 24px;
  }

  &__wrap{
    display: flex;
    flex-direction: column;
  }

  &--deadline{
    margin-top: 8px;
  }

  &__top-block{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .actions-menu{
      opacity: 0;
      transition: all 0.2s;
      margin-left: auto;
    }
  }

  &__assigne{
    margin-top: 10px;

    span:first-child{
      font-weight: 400;
     opacity: 0.5;
    }
  }

  &__assigne-user{
    color: black;
    border: 1px solid black;
    padding: 4px 8px;
    display: inline;
    border-radius: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    margin-left: 5px;

    &:hover{
      background-color: #D1F1E4;
    }
  }

  &__item {
    display: flex;
    align-items: start;
    margin-bottom: 20px;

    .marked {
      background-color: rgba(26, 187, 120, 0.35);
    }
    &--active{
      .transcription-text{
        color: black;
        .text-markdown{
          p{
            color: black !important;
            font-weight: 500 !important;
          }
        }
       div{
       }
      }
    }

    &__action {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 8px;
      margin-top: 4px;

      &__time {
        width: fit-content;
        background-color: #46454E;
        border-radius: 12px;
        padding: 0 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 13px;
        line-height: 135%;
        color: white;
        cursor: pointer;
        margin-bottom: 12px;

        &:hover {
          background-color: #1abb78;
        }
      }

      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0;
        transition: all 0.2s;

        svg{
          color: #7F7F7F;
        }
      }
    }

    &__text {
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #222222;

      .author_name {
        color: #9898A6;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;

        &__text {
          cursor: pointer;
          line-height: 28px;
        }

        .edit_person_icon {
          opacity: 0;
        }

        &:hover {
          .edit_person_icon {
            opacity: 1;
            width: 16px;
            height: 16px;
            margin-left: 4px;
            cursor: pointer;
          }
        }
      }

      .transcription-text {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;

        .text-markdown{
          font-weight: 400;

          h5{
            font-size: 18px;
          }
          h6{
            font-size: 14px;
          }
          .markdown-paragraph{
            color: #4A4A4A;
            font-size: 14px;
            font-weight: 450;
            line-height: 20px;
          }

          .markdown-text-person, .markdown-text-video-time {
            color: #37A87B;
            font-size: 14px;
            line-height: 20px;
            cursor: pointer;
            font-weight: 500;
            &:hover{
              color:#45C090
            }
          }
        }

        .highlight-text {
          background-color: #fcfa53;
        }

        .actions-menu {
          top: -27px;

          .menu-button {
            svg {
              rect {
                fill: black;
              }
            }
          }

          .dropdown-content {
            top: 36px;
          }
        }
      }

      .actions-menu {
        opacity: 0;
        transition: all 0.2s;

        .menu-button {
          margin-right: 6px;
        }
      }

      .text_update {
        width: calc(100% - 24px);
        .autocomplete_input{
          margin-bottom: 8px;
          &:active, &:hover {
            .autocomplete_input-wrap {
              border: 1px solid #1ABB78;
            }
          }
        }
        .autocomplete_input-wrap{
          padding: 12px 20px;
          border-radius: 10px;
          border: 1px solid #E6E6E6;
          width: 100%;

          input {
            width: 100%;
            border: 0;
            &:focus {
              outline: 0;

            }
          }
        }
        textarea {
          width: 100%;
          padding: 12px 20px;
          border-radius: 10px;
          border: 1px solid #E6E6E6;
          background: #FFF;

          &:focus {
            outline: 0;
            border: 1px solid #1ABB78;
          }
        }

        .update_buttons {
          display: flex;
          align-items: center;
          justify-content: right;

          button {
            width: 105px;
            height: 32px;
            display: block;
            border-radius: 10px;
            border: 1px solid #1ABB78;
            color: #1abb78;
            background: #FFF;
            outline: 0;
            font-weight: 450;
            font-size: 18px;
            line-height: 25px;

            &.disabled {
              opacity: 0.5;
            }
          }

          .save_btn {
            background: #1ABB78;
            color: white;
            margin-left: 12px;
          }
        }
      }
    }

    &.active-text,.texts__item--active {
      .texts__item__text {
        .transcription-text {
          span {
            opacity: 1;
          }

        }
      }
    }

    &:hover {
      .texts__item__action {
        .texts__item__action__btn, &__btn-mark {
          opacity: 1;
        }
      }

      .actions-menu {
        opacity: 1;
      }
    }
  }

  .text-tooltip {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    padding: 6px 10px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 14px;
    transform: translateX(50%);
    z-index: 1000;
    cursor: pointer;

    svg {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -16px; /* Adjust this value to control the triangle's position */
      left: 50%;
      transform: translateX(-50%);
      border-width: 8px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }

  &.custom-scroll {
    overflow-y: auto;
  }

  @media only screen and (max-width: 1100px) {
    //width: 100%;
    //margin-top: 10px;
    padding-top: 24px;

    &__item {
      display: flex;
      align-items: start;

      &__action {
        &__btn {
          opacity: 1;
        }
      }
    }
  }
}

.edit-texts-wrapper {
  width: calc(100% - 24px);
  overflow: hidden;
  margin: 0 0 24px 24px;
  //padding-right: 24px;

  .input_group, .texts-search-wrapper {
    width: 100%;
    border-radius: 20px;
    background: white;
    display: flex;
    height: 44px;
    padding: 4px 12px;
    align-items: center;
    //gap: 8px;
    overflow: hidden;

    input {
      width: 100%;
      background-color: transparent;
      border: 0;
      font-size: 14px;
      margin-left: 8px;

      &:focus {
        border: 0;
        outline: 0;
      }
      &::placeholder{
        font-size: 14px;
      }
    }

    svg{
      width: 20px;
      height: 20px;
    }

    &:first-child {
      margin-bottom: 8px;
    }

    &:nth-child(2) {
      svg {
        path {
          fill: #1abb78;
        }
      }
    }

  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 12px;

    button {
      display: flex;
      height: 32px;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid #1ABB78;
      color: #1abb78;
      background: #FFF;
      outline: 0;
      font-weight: 450;
      font-size: 18px;
      line-height: 25px;

      &.disabled {
        opacity: 0.5;
      }
    }

    .save_btn {
      background: #1ABB78;
      color: white;
      margin-left: 16px;
    }
  }

  .texts-search-wrapper {
    position: unset;
    width: 100%;
  }
}
