.link-content-wrapper {

  .link-title {
    background: #FFF;
    color: black;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    padding: 12px 16px;
    border-bottom: 1px solid #ECECEC;
    border-radius: 0;
  }

  .link-content {
    .link-switch {
      margin-bottom: 16px;

      .form-check-input {
        height: 24px;
        width: 40px;
        margin-right: 8px;
      }
    }
    &__link-copy{
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      margin-bottom: 24px;

      svg{
        width: 16px;
        height: 16px;
        path{
          stroke: #1ABB78;
        }
      }
      span{
        color: #1ABB78;
        font-size: 14px;
        font-weight: 450;
        line-height: 20px;
      }
    }

    .link-types {
      .form-check {
        margin-bottom: 12px;
      }

      .form-check-input {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }

    .form-check {
      display: flex;
      align-items: center;
      color: #212121;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }


    .form-check-input:checked {
      background-color: #1ABB78;
      border-color: #1ABB78;
    }

    .form-check-input:focus {
      border-color: #1ABB78;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(26, 187, 120, .25);
    }


    .calendar-wrapper {
      position: relative;

      .date-dropdowns {
        width: 290px;
        position: absolute;
        top: 12px;
        left: 12px;
        z-index: 1;
        padding: 0 24px;
        display: flex;
        justify-content: space-between;

        .menu-wrapper {
          .menu-button {
            display: flex;
            padding: 8px;
            gap: 8px;
            align-items: center;
            justify-content: space-between;
            border-radius: 8px;
            border: 1px solid #CDCDCD;
            background: white;
            color: #212121;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            cursor: pointer;

            svg {
              width: 16px;
              height: 16px;
            }
          }

          .dropdown-content {
            left: 0;
            top: 30px;
            border-radius: 0 0 8px 8px;
            border: 1px solid #CDCDCD;
            height: 200px;
            overflow-y: auto;

            .date-item {
              display: flex;
              padding: 4px 8px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              color: #212121;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;

              &:hover {
                background: #D1F1E4;
              }
            }

            &::-webkit-scrollbar {
              width: 4px;
              right: 3px;
            }

            &::-webkit-scrollbar-thumb {
              right: 3px;
              background-color: rgba(63, 67, 80, 0.24);
            }
          }

          &.day {
            width: 54px;
          }
          &.month {
            width: 102px;
          }
          &.year {
            width: 70px;
          }
        }
      }
    }
  }

  .buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    //margin-top: 24px;
    border-top: 1px solid #ECECEC;
    gap: 20px;

    button {
      width: 50%;
      height: 45px;
      display: block;
      border-radius: 10px;
      border: 1px solid #1ABB78;
      color: #1abb78;
      background: #FFF;
      outline: 0;
      font-weight: 450;
      font-size: 18px;
      line-height: 25px;

      &.disabled {
        opacity: 0.5;
      }
    }

    .save_btn {
      background: #1ABB78;
      color: white;

      &.disabled {
        opacity: 0.5;
      }
    }
  }

  .react-datepicker {
    border: 0;
    border-radius: 6px;
    background: var(--Color_background_secondary, #FFF);
    box-shadow: 0 5px 15px 0 rgba(22, 22, 26, 0.10);
    padding: 12px;
    width: 315px;

    &__month-container {
      width: 300px;
      border-image: unset;
    }

    &__header {
      //height: 88px;
      background-color: white;
      border: 0;
    }

    &__current-month {
      visibility: hidden;
      height: 38px;
    }

    &__navigation {
      top: 20px;

      &--previous {
        left: 12px;
        top: 12px;
        background-image: url("../../../../image/icons/ic_down.svg");
        background-repeat: no-repeat;
        transform: rotate(90deg) translate(12px, 20px);

        &:before {
          //background-color: red;
          //display: none!important;
        }
      }

      &--next {
        right: 12px;
        top: 12px;
        background-image: url("../../../../image/icons/ic_down.svg");
        background-repeat: no-repeat;
        transform: rotate(-90deg) translate(4px, 20px);
      }
    }

    &__navigation-icon {
      background-repeat: no-repeat;

      &::before {
        //&::before{
        display: none;
        //}}
      }
    }

    &__day-name {
      width: 2rem;
      color: #7E7E7E;
      font-size: 12px;
      height: 24px;
      margin-bottom: -16px;
    }

    &__day-name,
    &__day,
    &__time-name {
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      margin: 2px;

      &--selected {
        background-color: #1ABB78;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
      }

      &--outside-month {
        color: #19161D;
        opacity: 0.3;
      }

      &:hover {
        background-color: rgba(26, 187, 120, 0.4);
        border-radius: 50%;
        color: rgb(0, 0, 0);
        //margin: 0;
        //width: 2rem;
        //height: 2rem;
      }
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--in-range {
    //border-radius: 0.3rem;
    background-color: #1ABB78;
    border-radius: 50%;
    width: 28px;
    height: 28px;
  }

  .react-datepicker__year-text--keyboard-selected {
    //border-radius: 0.3rem;
    background-color: rgba(26, 187, 120, 0.4);
    border-radius: 50%;
    color: white;
    width: 28px;
    height: 28px;
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
  .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
  .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
  .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
    background-color: rgba(26, 187, 120, 0.4);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: white;
  }

  .calendar .react-datepicker__day-name,
  .calendar .react-datepicker__day,
  .calendar .react-datepicker__time-name {
    width: 28px;
    height: 28px;
  }
}

