
:root {
  --brandColor: #BCA477;
  --menuWidth: 224px;
  --menuWidthSmall: 88px;
}

$menuWidth: var(--menuWidth);
$menuWidthSmall: var(--menuWidthSmall);

//--------Colors---------

$brandColor: var(--brandColor);
$borderColor: #F2F2F2;
$timelineHeight: 64px;
$personAndEventHeight: 50px;

//@media (max-width: 1680px) {
//  :root {
//    --mainContent: 1200px;
//  }
//}

