.notification-menu {

  .menu-button {
    .notification_btn {
      border: 0;
      outline: 0;
      background-color: transparent;
      width: 36px;
      height: 36px;
      margin-left: 12px;
      transition: all 0.2s;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:hover {
        border-radius: 10px;
        background: rgba(26, 187, 120, 0.15);
      }

      .has_notification {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #DB1F26;
        border: 2px solid #F8CECF;
        border-radius: 50%;
        top: 6px;
        right: 6px;
      }
    }

    &.active {
      .notification_btn {
        border-radius: 10px;
        background: rgba(26, 187, 120, 0.15);
      }
    }
  }

  .dropdown-content {
    //left: 50%;
    top: 66px;
    overflow: hidden;
    //right: unset;
    //padding: 20px 16px;
    padding-bottom: 20px;

    .header {
      display: flex;
      padding: 12px 16px;
      flex-direction: column;
      align-items: flex-start;
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      border-bottom: 1px solid #E6E6E6;
    }

    .filters-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;

      .filters {
        display: flex;
        align-items: center;

        &-btn {
          color: #A8A8AC;
          font-size: 16px;
          font-style: normal;
          font-weight: 450;
          line-height: 20px;
          text-transform: capitalize;
          margin-right: 12px;
          padding: 8px 0;

          &.active {
            border-bottom: 2px solid #1ABB78;
            color: black;
          }

          .count {
            color: #A8A8AC;
            margin-left: 4px;
          }
        }
      }
    }

    .notification_list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-height: 40vh;
      border: 0;
      //margin-top: 20px;
      background-color: white;
      border-radius: 0;
      z-index: 100;
      overflow-y: auto;

      .notification {
        width: 100%;
        display: flex;
        align-items: start;
        position: relative;
        padding: 8px 16px;
        border-bottom: 0.7px solid #E6E6E6;
        transition: all 0.2s;

        &-img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          margin-right: 8px;
          display: flex;
          padding: 8px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          background: #DDF5EB;
          position: relative;

          svg {
            width: 16px;
            height: 13px;
          }

          .mark-icon {
            width: 12px;
            height: 12px;
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            svg {
              width: 8px;
              height: 8px;
            }
          }
        }

        &-info {
          .name {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 8px;
          }

          .date {
            color: #A8A8AC;
            font-size: 10px;
            font-style: normal;
            font-weight: 450;
            line-height: 12px;
          }
        }
        .new-mark-wrap{
          width: 30px;
          height: 30px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
        }

        .new-mark {
          width: 10px;
          height: 10px;
          margin: 10px;
          background-color: #1abb78;
          border-radius: 50%;
          display: block;
          //padding: 20px;
        }

        &.new {
          background-color: #DDF5EB;
        }

        &:hover {
          background-color: #DDF5EB;
        }
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #a8a7ad;
      }
    }
  }
}
