.integrations-block{
  width: 100%;
  overflow: hidden;
  max-width: 1200px;
  background-color: #F2F2F2;

  &__header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
  }

  &__wrap{
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }
}