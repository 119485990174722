.notes{
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-top: 20px;
  margin-bottom: 50px;

  @media screen and (max-width: 1440px){
    margin: 20px 32px 40px 32px;
  }

  &__loader{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  .pagination{
    padding: 0 !important;
  }
  .breadcrumb{
    width: 100%;
    margin-bottom: 0;
    .breadcrumb-item.active{
      span{
        color: black;
      }
    }
    .breadcrumb-item{
      height: fit-content;
      a, span{
        color: #9898A6;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-decoration: unset;
      }
      &::before{
        font-weight: bold;
      }
    }
  }
  .empty-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 100px;
  }
}
.notes_container {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}