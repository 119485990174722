.role_modal {
  .modal-dialog, .modal-content{
    width: 818px !important;
  }
  &__wrap{
    display: flex;
    flex-direction: column;
    width: 818px;
  }
  &__wrapper {
    display: flex;
  }

  &__title {
    color: black;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 32px 24px 32px;

    svg {
      cursor: pointer;

      path {
        fill: #cccccc;
      }
    }
  }

  &__permissions {
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 20px 10px;
    padding: 0 32px 24px 32px;

    .permission_item {
      &__item{
        display: flex;
        align-items: center;
        gap: 8px;
        svg{
          cursor: pointer;
          color: #7F7F7F;
          flex-shrink: 0;
        }

        .form-check{
          display: flex;
          align-items: center;
        }
      }
      &__title {
        color: black;
        opacity: 0.5;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 8px;
        display: flex;
        align-items: self-end;
        gap: 10px;

        svg{
          cursor: pointer;
        }
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 6px;
        label{
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }

  .input_group {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    color: #222;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    padding: 0 32px 24px 32px;

    .input-icon {
      position: absolute;
      right: 16px;
      top: 46px;
      cursor: pointer;
    }

    input, textarea {
      padding: 15px 20px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid #A6A6A6;
      background: #FFF;
      outline: 0;

      &::placeholder {
        color: #A6A6A6;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }

    textarea {
      height: 100%;
    }

    &.full {
      width: 100%;
    }

    &__error {
      color: red;
      font-size: 12px;
      padding-top: 4px;
    }
  }

  .input_group, .select_group {

    label {
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #000000;
      opacity: 0.5;
      margin-bottom: 5px;
    }
  }

  .input-form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .reis-select {
    .reis-btn {
      width: 100%;
      display: flex;
      height: 56px;
      padding: 16px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 8px;
      border: 0.7px solid #A6A6A6;
      background: #FFFFFF;
      color: #A6A6A6;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      &__name {
        width: calc(100% - 40px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        color: #222;

        .reis-count{
          color: #A6A6A6;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 25px;
          margin-left: 8px;
        }
      }
    }

    .dropdown-content {
      max-height: 476px;
      overflow-y: auto;
    }

    &__item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      display: flex;
      height: 55px;
      padding: 16px 20px;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      width: 100%;

      &__check {
        width: 20px;
      }

      &__name {
        width: calc(100% - 20px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }

  }

  .select_group {
    padding: 0 32px 20px 32px;

    &__list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      .reis-item {
        border-radius: 6px;
        background: rgba(26, 187, 120, 0.10);
        color: #1ABB78;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        padding: 4px 12px;
        align-items: center;
        gap: 4px;
        margin-right: 16px;
        margin-bottom: 10px;
      }
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px 32px;
    margin-top: 34px;
    border-top: 1px solid #ECECEC;

    button {
      width: 100%;
      height: 45px;
      display: block;
      border-radius: 10px;
      border: 1px solid #1ABB78;
      color: #1abb78;
      background: #FFF;
      outline: 0;
      font-weight: 450;
      font-size: 18px;
      line-height: 25px;

      &.disabled {
        opacity: 0.5;
      }
    }

    .save_btn {
      background: #1ABB78;
      color: white;
      margin-left: 16px;
    }
  }

  .form-check-input {
    width: 18px;
    height: 18px;
    border: 1px solid rgba(0, 0, 0, .25);
    margin-right: 10px;
    margin-top: 0;
    cursor: pointer;
  }

  .form-check-input:checked {
    background-color: #1ABB78;
    border-color: #1ABB78;
    width: 18px;
    height: 18px;
  }

  .form-check-input:focus {
    outline: 0;
    box-shadow: none;
  }

  .check_part {
    .form-check-input:checked {
      background-color: #A5A3A9;
      border-color: #A5A3A9;
    }
  }
}


@media only screen and (max-width: 1000px) {
  .modal-dialog {
    max-width: 800px !important;
    width: 100vw !important;

    .modal-content {
      width: 94% !important;
    }
  }
}

.permission-tooltip{
  .ant-tooltip-arrow{
    bottom: 1px !important;
  }
  ul{
    margin: 0;
  }
}