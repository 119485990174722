.profile-page {
  margin-top: unset;

  &-header {
    //height: 72px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #0000000f;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 25px;
    color: #000000;
    padding:24px 32px;
  }

  &__form {
    width: 100%;
    max-width: 824px;
    background-color: white;
    border-radius: 24px;
    margin: 40px auto;

    .accordion{
      padding-bottom: 0;
    }

    &__wrapper {
      display: flex;
      justify-content: space-between;
      padding: 32px;
    }

    &__media {
      width: 240px;

      .actions {
        display: flex;
        justify-content: center;
        margin-top: 16px;

        button {
          background-color: transparent;
          border: 0;
          outline: 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;

          &:first-child {
            margin-right: 24px;
          }
        }
      }
    }

    &__content {
      width: calc(100% - 260px);
      height: inherit;
      //display: flex;
      //flex-wrap: wrap;
      //justify-content: space-between;

      &__top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        .title {
          //width: 100%;
          font-weight: 500;
          font-size: 20px;
          line-height: 25px;
          margin-bottom: 0;
        }

        button {
          background-color: transparent;
          border: 0;
          outline: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          color: #1ABB78;
        }
      }

      .social-icons {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .icon {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background: #F2F2FA;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 14px;
          cursor: pointer;
        }
      }
    }

    .row-item{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      margin-bottom: 16px;
    }
    .input_group {
      display: flex;
      flex-direction: column;
      width: 48%;
      position: relative;

      .input-icon {
        position: absolute;
        right: 16px;
        top: 38px;
        cursor: pointer;
      }

      label {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #000000;
        opacity: 0.5;
        margin-bottom: 4px;
      }

      input, textarea {
        padding: 12px;
        width: 100%;
        height: 48px;
        border-radius: 10px;
        background: #EFEFEF;
        border: 0;
        outline: 0;
      }

      &.full {
        width: 100%;
        height: calc(100% - 190px);

        textarea {
          width: 100%;
          height: 100%
        }
      }

      &__error {
        color: red;
        font-size: 12px;
        padding-top: 4px;
      }

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &__settings_tab {
    margin: 32px;
    border: 2px solid #CDCDCD;
    box-shadow: 0px 3px 11px 0px #2855391F;
    border-radius: 12px;

    .nav-tabs {
      padding: 32px 20px 0 20px;
      border: 0;
      display: flex;
      justify-content: flex-start;
      gap: 90px;

      .nav-item {
        //background-color: #ECECEC38;
        border: 0 !important;

        &:first-child {
          .nav-link {
          }
        }
      }

      .nav-link {
        border: 0 !important;
        display: flex;
        padding: 0;
      }

      .tab-title {
        color: #A8A8AC;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        display: flex;
        align-items: center;
        padding-bottom: 8px;

        svg {
          margin-left: 8px;
        }
      }

      .nav-link.active {
        border: 0;
        border-radius: 0;
        background-color: transparent;
        border-bottom: 2px solid #1ABB78 !important;

        .tab-title {
          color: black;
        }
      }
    }

    .tab-content {
      padding: 28px 20px;

      .remove-user{
          width: 100%;
          margin-top: 20px;

          &__wrap{
            display: flex;
            flex-direction: column;
            gap: 16px;

            &--title{
              font-size: 20px;
              font-weight: 500;
              line-height: 25px;
            }
            &--description{
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              span{
                font-size: 16px;
                font-weight: 400;
                line-height: 20.8px;
                color: #9898A6;
              }

              button{
                display: flex;
                align-items: center;
                gap: 10px;
                background-color: transparent;
                border: 0;
                width: max-content;
                padding: 4px 24px;
                white-space: nowrap;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                color: #DB1F26;
                &:hover{
                  color: #E5484E;

                  svg{
                    path{
                      fill: #E5484E;
                    }
                  }
                }

                svg{
                  width: 20px;
                  height: 20px;

                  path{
                    fill: #DB1F26;
                  }
                }
              }
            }
          }
        }
      .user-info-settings {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 0 16px;
        &__permissions-info{
          width: 100%;
          display: flex;
          align-items: flex-start;
          gap: 10px;
          border: 1px solid #FFA800;
          border-radius: 12px;
          padding: 20px;
          margin-bottom: 20px;

          span{
            font-size: 16px;
            font-weight: 400;
            line-height: 20.8px;
            color: #9898A6;
          }

          svg{
            width: 24px;
            height: 24px;
            flex-shrink: 0;
          }
        }
        &--description{
          font-size: 16px;
          font-weight: 400;
          line-height: 20.8px;
          text-align: left;
          color: #9898A6;
          width: 100%;
          padding: 0 0 28px 0;
        }

        .input_group{
          margin-bottom: 0;
          flex: 45%;
        }

      }
    }
  }
  .accordion-wrapper{
    .roles-accordion{
      &__list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        &--item {
          padding: 4px 12px;
          border-radius: 60px;
          color: #F74040;
          background: #FEEBEB;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
      .accordion-item {
        //padding: 24px 32px;
        //border-radius: 0;
        //border: 0;
        //border-top: 1px solid #0000000f;
        //border-bottom: 1px solid #0000000f;

        .accordion-header {

          .accordion-button {
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            color: black;
            display: flex;
            align-items: center;
            padding: 0;
            border: 0!important;

            //svg {
            //  margin-left: 8px;
            //  margin-top: 4px;
            //}

            .arrow-icon {
              transform: rotate(180deg);
            }

            &.collapsed{
              display: flex;
              align-items: center;
              .arrow-icon {
                transform: rotate(0deg);
              }
            }

            &::after{
              display: none;
            }
          }

          .accordion-button:focus {
            z-index: 3;
            border-color: #ECECEC;
            outline: 0;
            box-shadow: unset;
          }

          .accordion-button:not(.collapsed) {
            color: unset;
            background-color: white;
            box-shadow: unset;
          }
        }

        .accordion-body {
          padding: 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .contact-item {
            width: 46%;
            margin-bottom: 24px;
            padding-right: 2%;

            .input_group {
              width: 100%;
              margin-bottom: 12px;
              position: relative;
              transition: all 0.2s;

              &__delete {
                position: absolute;
                right: -34px;
                bottom: 14px;
                opacity: 0;
                cursor: pointer;

                path {
                  fill: #A8A8AC
                }
              }

              &__error {
                color: red;
                font-size: 12px;
                padding-top: 4px;
              }

              &:hover {
                .input_group__delete {
                  opacity: 1;
                }
              }
            }

            .add-contact {
              border: 0;
              color: #1ABB78;
              background-color: white;
              font-size: 16px;
              font-style: normal;
              font-weight: 450;
              line-height: 25px;

              svg {
                margin-right: 5px;

                rect {
                  fill: #1ABB78;
                }
              }
            }
          }
        }
      }

      &.system {
        //border-top: 0;
        margin-top: 0;
      }
  }

  .form-switch {
    display: flex;
    align-items: center;

    .form-check-label {
      color: #77777D;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-left: 10px;
    }

    .form-check-input {
      width: 36px !important;
      height: 22px;

      &:checked {
        background-color: #1abb78;
        border-color: #1abb78;
      }
    }
  }

  .language {
    display: flex;
    flex-direction: column;
    .form-check{
      margin-bottom: 20px;
    }
    .form-check-input:checked {
      background-color: #1ABB78;
      border-color: #1ABB78;
    }

    .form-check-input:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .language-info{
    width: 100%;
    border-radius: 12px;
    border: 1px solid #FFA800;
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    svg{
      width: 24px;
      height: 24px;
    }

    span{
      width: fit-content;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      opacity: 0.6;
    }
  }

  .buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px;
    margin-top: 32px;
    border-top: 1px solid #ECECEC;
    gap: 20px;

    button {
      width: 50%;
      height: 45px;
      display: block;
      border-radius: 10px;
      border: 1px solid #1ABB78;
      color: #1abb78;
      background: #FFF;
      outline: 0;
      font-weight: 450;
      font-size: 18px;
      line-height: 25px;

      &.disabled {
        opacity: 0.5;
      }
    }

    .save_btn {
      background: #1ABB78;
      color: white;

      &.disabled {
        opacity: 0.5;
      }
    }
  }
}

.add-social-modal {
  padding: 32px;

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
  }

  input {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    margin: 24px 0;
    border: 1px solid #9898A6;
    padding: 0 16px;

    &.error-social {
      border: 1px solid red;
    }

    &:focus {
      border: 1px solid #9898A6;
      outline: 0;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 48%;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1ABB78;
      color: white;
      border-radius: 10px;
      font-size: 18px;
      line-height: 25px;
      border: 0;

      &.cancel-btn {
        background-color: white;
        border: 1px solid #1ABB78;
        color: #1ABB78;
      }
    }
  }
}

#menu-tooltip {

  .tooltip-inner {
    max-width: 300px;
    padding: 8px 10px;
    text-align: left;
    .title{
      color: #F5F5F5;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .description{
      color: #F5F5F5;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 8px;
    }
  }
}

.settings-content{
  .roles-accordion{
    &__wrap {
      background-color: transparent !important;
      padding: 20px !important;
      border: 0.6px solid #CDCDCD !important;
      border-radius: 8px !important;
      width: 100%;
      margin-top: 28px;

      &--header {
        button {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          justify-content: space-between;
          background-color: transparent !important;
          font-size: 20px !important;
          font-weight: 600;
          line-height: 28px !important;
        }
      }
    }
  }


}
