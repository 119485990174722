.delete-user-modal{
  &__mask{
    z-index: 2000 !important;
  }
  &__content{
    padding: 0 !important;
    width: 680px;
  }
  &__wrapper{
    z-index: 2050 !important;
  }
  .ant-modal-close, .ant-modal-footer{
    display: none;
  }
  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    border-bottom: 1px solid rgba(236, 236, 236, 1);
    padding: 16px 24px;

    &--title{
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
    }

    svg{
      cursor: pointer;
    }
  }

  &__body{
    padding: 16px 24px;

    &--text{
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
    }
  }
  &__footer{
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    border-top: 1px solid rgba(236, 236, 236, 1);

    button{
      width: 100%;
    }

    &--delete {
      width: 308px;
      display: flex;
      padding: 8px 20px;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid #DB1F26;
      color: #DB1F26;
      font-size: 18px;
      font-style: normal;
      font-weight: 450;
      line-height: 25px;
      background-color: white;
    }

    &--close{
      width: 50%;
      height: 45px;
      display: block;
      border-radius: 10px;
      border: 1px solid #1ABB78;
      color: #1abb78;
      background: #FFF;
      outline: 0;
      font-weight: 450;
      font-size: 18px;
      line-height: 25px;
    }
  }
}