.update-video {

  &-header {
    display: flex;
    padding: 24px 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #ECECEC;
    color: black;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;

    svg {
      cursor: pointer;
    }
  }


  &-content {
    padding: 12px 32px;
    display: flex;
    flex-wrap: wrap;
    .auto-chains-channels{
      width: 50%;
      margin-top: 20px;
    }

    .auto-chains-channels--title{
      padding-top: 0;
    }
    .buttons-wrapper{
      display: none !important;
    }
    .video_title {
      width: 100%;
      font-size: 20px;
      color: #000000;
      margin-bottom: 16px;
      font-weight: 500;
      line-height: 28px;
    }

    .description-input {
      width: 100%;
      height: 55px;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #A6A6A6;
      background: #FFF;

      &:focus {
        border-color: #1abb78;
        outline: 0;
      }
    }

    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #000000;
      white-space: nowrap;
      margin-bottom: 20px;
    }

    .save-btn {
      width: calc(100% - 32px);
      height: 44px;
      background-color: #1ABB78;
      color: white;
      border: 0;
      //opacity: 0.5;
      border-radius: 10px;
      margin: 16px;

      &.disabled {
        opacity: 0.5;
      }
    }

    .link-part {
      width: 315px;
      max-width: calc(100% - 327px);
      margin-top: 20px;

      .link-content-wrapper{
        padding: 0 16px;
      }
      .title {
        padding: 0 16px;
        margin-bottom: 20px;
      }
    }
  }

  .channels-wrapper {
    position: relative;
  }

  &-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 24px 32px;
    margin-top: 34px;
    border-top: 1px solid #ECECEC;

    button {
      width: calc(50% - 8px);
      height: 45px;
      display: block;
      border-radius: 10px;
      border: 1px solid #1ABB78;
      color: #1abb78;
      background: #FFF;
      outline: 0;
      font-weight: 450;
      font-size: 18px;
      line-height: 25px;

      &.disabled {
        opacity: 0.5;
      }
    }

    .save_btn {
      background: #1ABB78;
      color: white;
      margin-left: 16px;
    }
  }

  &.hide {
    display: none;
  }
}

.modal-dialog{
  width: 100% !important;
  max-width: 714px !important;
}

.mini-modal{
  width: 100% !important;
  max-width: 440px !important;

  .auto-chains-channels{
    width: 100% !important;
    border: none !important;
    padding: 0 !important;
  }
}