@mixin accordion-mixin {

  .accordion-item {
    border: 0.6px solid #CDCDCD;
    border-radius: 6px !important;

    .accordion-header {
        padding: 20px;
      .accordion-button {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        color: black;
        display: flex;
        align-items: center;
        padding: 0;
        border: 0 !important;

        svg {
          margin-left: 8px;
        }

        .arrow-icon {
          transform: rotate(180deg);
            position: absolute;
            top: 0;
            right: 0;
        }

        &.collapsed {
          display: flex;
          align-items: center;

          .arrow-icon {
            transform: rotate(0deg);
          }
        }

        &::after {
          display: none;
        }
      }

      .accordion-button:focus {
        z-index: 3;
        border-color: #ECECEC;
        outline: 0;
        box-shadow: unset;
      }

      .accordion-button:not(.collapsed) {
        color: unset;
        background-color: white;
        box-shadow: unset;
      }

    }

    .accordion-body {
      padding: 5px 20px 20px !important;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 16px 37px;

      .contact-item {
        width: 100%;
        max-width: 332px;

        .input_group {
          width: 100%;
          margin-bottom: 12px;
          position: relative;
          transition: all 0.2s;

          &__delete {
            position: absolute;
            right: -26px;
            top: 40px;
            opacity: 0;
            cursor: pointer;

            path {
              fill: #A8A8AC
            }
          }

          &__error {
            color: red;
            font-size: 12px;
            padding-top: 4px;
          }

          &:hover {
            .input_group__delete {
              opacity: 1;
            }
          }
        }

        .add-contact {
          border: 0;
          color: #1ABB78;
          background-color: white;
          font-size: 14px;
          font-style: normal;
          font-weight: 450;
          line-height: 16px;
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 8px 4px;

          svg {
            color: #1ABB78;
          }
        }
      }
    }
  }

  .accordion-item:not(:first-of-type) {
    border-top: 1px solid #ECECEC;
  }

  .tooltip-icon {
    margin-left: 8px;
  }
}