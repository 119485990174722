.channels-container {
  display: flex;
  justify-content: space-between;
  padding-right: 0!important;
  height: auto;
  .empty-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 100px;

    &--text {
      color: #9898A6;
      font-size: 16px;
      font-weight: 450;
      line-height: 20px;
      text-align: center;
    }
  }
  .channels {
    width: calc(100% - 240px);
    display: flex;
    flex-direction: column;
    color: #111826;
    flex-wrap: wrap;
    font-style: normal;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    min-width: 750px;
    padding-bottom: 40px;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.05);

    &__filters-list{
      width: 100%;
      padding: 0 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 964px;

      .filters-list{
        border: none;
        margin-top: 4px;
      }
    }

    &__header{
      max-width: 964px;
      width: 100%;
      padding: 0 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      .page-title{

        margin:  0 !important;
      };
    }

    &-wrap{
      display: flex;
      align-items: flex-start;
      width: 100%;
      background-color: white;
    }
    .persons_menu{
      left: -11vw;
    }


    hr {
      border: 1px solid #e8ebee;
      width: 100%;
      margin: 0;
    }

    &__section {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 964px;

      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 0 12px 0;
        width: 100%;
        &__name {
          font-weight: bold;
          margin: 0;
        }

        &__view-all {
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: nowrap;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: #a8a8ac;
          white-space: nowrap;
          align-items: center;
          cursor: pointer;

          &-text {
            margin: 0;
          }
        }

      }

      &-videos {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;
        width: 100%;

        &-empty {
          width: 100%;
          height: 100%;
          text-align: center;
          font-size: 13px;
        }
          .video_card{
            width: calc(25% - 24px);
            margin: 0;
          }
      }
    }
  }

  .page-title{
    max-width: 900px;
    margin: 16px 0 16px 0 !important;
  }
}

.loader-wrap{
  width: calc(100% - 240px);
  height: calc(100vh - 320px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.recent-videos{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 0 40px 44px 40px;

  &--title{
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    max-width: 900px;
    width: 100%;
    margin: 0;
  }

  &__list{
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 24px;
    max-width: 900px;
    width: 100%;
  }
}

