.marked{
  background-color: rgba(26, 187, 120, 0.35);
}

.protocol-item {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #46454E;
  padding: 8px 24px 20px 24px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;

  &__actions{
    display: flex;
    flex-direction: column;
  }
  h6{
    font-size: 14px;
  }
  .markdown-paragraph{

    .markdown-text-person, .markdown-text-video-time{
      color: #37A87B;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      font-weight: 500;

      &:hover{
        color:#45C090
      }
    }
  }


  &__btn {
    width: 32px;
    height: 32px;
    background: #22C55E;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    cursor: pointer;
    transition: all 0.2s;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
    z-index: 1;
    opacity: 0;
  }

  &__dots {
    opacity: 0;
    margin-bottom: 12px;
    cursor: pointer;
  }

  .text {
    margin-bottom: 12px;
    //min-height: 48px;
    font-size: 14px;
    line-height: 24px;


  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .left {
      width: calc(100% - 72px);

      .name {
        font-weight: 600;
        font-size: 16px;
        line-height: 135%;
        color: #1D1929;
      }

    }

    .right {
      width: 72px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: end;

      .highlights-star{
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }


      .ellipsis-icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      .time {
        position: absolute;
        bottom: 0;
        right: 0;
        font-weight: 500;
        font-size: 13px;
        line-height: 135%;
        text-align: right;
        border-radius: 40px;
        border: 1px solid #000;
        opacity: 0.35;
        padding: 4px 8px;
        color: black;
        cursor: pointer;
        transition: all 0.2s;
        &:hover{
          background-color: #1abb78;
          border-color: #1abb78;
          color: white;
          opacity: 1;
        }
      }
    }
  }

  &__actions {


    &--start{
      width: 16px;
      height: 16px;
      path{
        stroke: #1abb78;
      }
    }

    &--start.fill{
      path{
        fill: #1abb78;
      }
    }

    &--file{
      width: 16px;
      height: 16px;
      color: #1abb78;

    }

    &__close {
      display: none;
    }


    &__content {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #F5F5F5;
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.06);
      border-radius: 12px;
      transform: translateY(-50%);
    }

    &__item {
      height: 42px;
      padding: 0 17px;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      color: black;
      cursor: pointer;
      border-bottom: 1px solid #E8EBEE;

      &.delete {
        color: #DB1F26;
        border-bottom: 0;
      }

      svg {
        margin-right: 6px;

      }
      .share-icon{
        path{
          stroke: black;
        }
      }
      .edit-icon{
        path{
          fill: black;
        }
      }
    }
  }


  .share-menu{
    .dropdown-content{
      top: 18px;
      .share-menu-item{
        padding: 10px 14px;
        svg{
          margin-right: 8px;
        }
      }
    }
  }

  &:hover {
    .protocol-item__btn, .protocol-item__dots {
      opacity: 1;
    }
  }

}

.autocomplete_input {
  position: relative;

  .persons_list {
    position: absolute;
    width: 100%;
    z-index: 100;
    max-height: 300px;
    overflow: auto;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(22, 22, 26, 0.1);
    padding: 12px;
  }

  .person_item {
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    img {
      border-radius: 50%;
      background-color: #f2f3fc;
      margin-right: 8px;
    }

    .person_info {
      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: black;
      }

      .email {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #9898A6;
      }
    }
  }
}