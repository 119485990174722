.chain {
  .channels-page__wrap{
    padding-right: 0 !important;
  }
  &__footer{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 40px 0 0 0;
    max-width: 1200px;

    .pagination{
      padding: 0 !important;
    }
  }
  .empty-info{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 100px;

    &--text{
      color: #9898A6;
      font-size: 16px;
      font-weight: 450;
      line-height: 20px;
      text-align: center;
    }
  }
  nav{
    margin-right: auto;
  }
  &__header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1200px;
    gap: 12px;
  }
  .filters-list{
    border: none;
  }

  &__add-btn {
    margin-left: auto;
    width: 100%;
    max-width: 187px;
    height: 36px;
    background: #1ABB78;
    color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    cursor: pointer;
    transition: all 0.2s;
    z-index: 1;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    gap: 10px;

    svg {
      width: 16px;
      height: 16px;
    }

    &.disabled {
      opacity: 0.5;
    }

    &:hover {
      background-color: #29D990;
    }
  }

  &__wrap {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 24px;
    margin-top: 14px;

    &--video {
      position: relative;
      width: 23%;
      max-width: 282px;

      .video__channels--badge{
        max-width: 148px;
      }

      &:hover {
        .video__detach {
          display: block;
        }
      }

      .video {
        &__detach {
          position: absolute;
          top: 8px;
          right: 12px;
          z-index: 10;
          display: none;

          &--icon {
            border-radius: 50%;
            width: 29px;
            height: 29px;
            background-color: rgba(0, 0, 0, 0.7);

            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
              width: 13px;
              height: 13px;

              path {
                stroke-opacity: 1;
                stroke: white;
              }
            }
          }

          .menu-tooltip {
            font-size: 13px;
          }
        }
      }
    }

    .video {
      &--poster {
        width: 100%;
        max-width: 282px;

        &-img {
          height: 151px;
        }
      }

      &__info-date {
        flex-wrap: nowrap;
        white-space: nowrap;
      }

      &__time {
        left: 10px;
        width: max-content;
      }
    }
  }
}


.toasblock {
  display: flex;
  align-items: center;
  gap: 20px;

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: white;
    }

    &--text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #9898A6;
    }
  }
}