.filters-all{
  .ant-popover-inner{
    padding: 0;
  }
  &__container{
    display: flex;
    flex-direction: column;
    min-width: 144px;
  }
  &__header{
    padding: 16px 16px 8px 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    border-bottom: 1px solid #ECECEC;
  }

  &__list{
    padding: 8px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}