.personal-videos {
  background: rgba(232, 232, 232, 1);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 40px;
  max-width: 980px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 8px;
    max-width: 900px;
    width: 100%;

    &--title {
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
      margin: 0;
    }

    &--link {
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      text-align: left;
      text-decoration: underline;
      cursor: pointer;
      margin-left: auto;
      a{
        color: rgba(152, 152, 166, 1);
      }

      &:hover {
        color: rgb(129, 129, 140);
      }
    }

    &--icon {
      margin-top: -4px;
    }
  }

  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    width: 100%;
    max-width: 900px;
  }

  .count {
    color: #9898A6;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    cursor: pointer;
    transition: all 0.2s;
  }
}
