.channel-form{
  width: 60%;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  &__header{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg{
      cursor: pointer;
      margin-left: auto;
    }
  }


  &--title{
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
  }
  &--sub-title{
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  &--subtitle{
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }

  &__input-wrap{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      input {
        width: 100%;
        height: 56px;
        border-radius: 8px;
        border: 1px solid #A6A6A6;
        background: #FFF;
        padding: 16px;

        &:focus {
          outline: 0;
        }
      }

      .count {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        color: rgba(24, 24, 24, 1);
        opacity: 0.6;
        white-space: nowrap;
      }

      &.create{
        input{
          width: 100%;
        }
      }
  }

  .managers{
    &--add{
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      padding: 8px 2px;
      width: max-content;
      background: none;
      border: none;

      &:hover{
        &--title{
          color: rgba(41, 217, 144, 1);
        }
        svg {
          rect {
            fill: rgba(41, 217, 144, 1);
          }
        }
      }

      svg {
        width: 16px;
        height: 16px;
        rect {
          fill: rgba(31, 176, 115, 1);
        }
      }

      &--title{
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        color: rgba(31, 176, 115, 1);
      }
    }

    &__search-block {
      display: flex;
      align-items: center;

      input {
        width: 100%;
        display: flex;
        height: 32px;
        padding: 4px 12px;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 0;
        border-radius: 10px;
        border: 1px solid #D9DEE2;

        &:focus {
          outline: 0;
          border: 1px solid #1ABB78;
        }
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      padding-left: 12px;
      button {
        background-color: transparent;
        border: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      .close-btn {
        color: #7F7F7F;
      }

      .save-btn {
        color: #1abb78;

        &.disabled {
          opacity: 0.5;
        }
      }
    }

    &__list{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 16px;
      max-height: 330px;
      overflow: auto;

      &--item{
        width: 48%;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        img{
          background: white;

          svg{
            path{
              fill: red;
            }
          }
        }
      }
      &.participants{
        padding: 16px;
      }

      &.all{
        margin-top: 16px;
      }

      .disabled{
        .form-check-input:checked {
          background-color: #A5A3A9;
          border-color: #A5A3A9;
        }

      }
    }

  }

  .participants{
      position: relative;
      svg{
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      input{
        width: 100%;
        height: 32px;
        padding: 14px 0 14px 40px;
        border-radius: 20px;
        border: 1px solid #D9DEE2;
        &:focus {
          outline: 0;
          border: 1px solid #1ABB78;
        }
      }


  }

  .form-check-input {
    width: 18px;
    height: 18px;
    border: 1px solid rgba(0, 0, 0, .25);
    margin-right: 10px;
  }

  .form-check-input:focus{
    box-shadow: none;
  }

  &__footer{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #ECECEC;
      gap: 8px;

      button {
        width: 100%;
        height: 45px;
        display: block;
        border-radius: 10px;
        outline: 0;
        font-weight: 450;
        font-size: 18px;
        line-height: 25px;

        &.disabled {
          opacity: 0.5;
        }
      }

    &--save_btn {
      background: #1ABB78;
      color: white;
      border: 1px solid transparent;
      color: white;
    }

    &--close_btn {
      border: 1px solid #1ABB78;
      color: #1abb78;
      background: #FFF;
    }
  }

  .form-check-input:checked {
    background-color: #1ABB78;
    border-color: #1ABB78;
    width: 18px;
    height: 18px;
  }

  .check-icon{
    display: flex;
    align-items: center;
    color: #212121;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    .count{
      opacity: 0.6;
      margin-left: 12px;
    }
  }

  .check-types{
    display: flex;
    align-items: center;
    align-items: center;

    .form-check-inline:first-child{
      width: 50%;
    }

  }
}

.info-text{
  font-size: 16px;
  font-weight: 450;
  line-height: 20px;
  color: rgba(152, 152, 166, 1);
  padding: 26px 40px;

}