.auto-chains-share-link {
  width: 50%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #E6E6E6;
  padding: 0 12px;

  &--title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    padding: 8px 0 24px 0;
  }


  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &--switch {
      display: flex !important;
      align-items: center;
      margin-bottom: 4px !important;

      label {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
      }

      .form-check-input {
        width: 40px !important;
        height: 24px;
        margin-right: 8px;
        background-color: #A8A8AC;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
        border: none;
        cursor: pointer;
      }

      .form-check-input:checked {
        background-color: #1ABB78;
        border-color: #1ABB78;

        &:focus {
          background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
        }
      }
      .form-check-input:focus {
        box-shadow: none !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
      }

      .form-check-input:focus {
        border-color: #1ABB78;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(26, 187, 120, .25);
      }
    }

    &--toggle {

    }

    &--calendar {
      position: relative;
      box-shadow: 0px 5px 15px 0px #16161A1A;
      border-radius: 6px;


      .rmdp-day-picker, .rmdp-wrapper, .rmdp-wrapper, .rmdp-header, .rmdp-calendar, .rmdp-day-picker {
        width: 100%;
      }

      .rmdp-header {
        margin-top: 10px;
      }

      .rmdp-header-values {
        opacity: 0;
      }

      .rmdp-top-class {
        width: 325px;
      }

      .rmdp-day-picker {
        padding: 0 30px 12px 30px;

        & > div {
          width: 100%;
        }
      }

      .rmdp-arrow {
        border-color: black;
        padding: 3px;
      }

      .rmdp-arrow-container:hover {
        background-color: transparent;
        box-shadow: none;

        .rmdp-arrow {
          border-color: #1FB073;
        }
      }

      .rmdp-week-day {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #7E7E7E;
      }

      .rmdp-header-values {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      .rmdp-today {
        span {
          background-color: #1ABB78 !important;
        }
      }

      .rmdp-day.rmdp-selected span:not(.highlight) {
        background-color: #96e3c4 !important;
        box-shadow: none;
      }

      .rmdp-range {
        border-radius: 50%;
        box-shadow: none;
        height: 28px;
        width: 28px;
        background-color: #D1F1E4;

        span {
          color: black;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }

      .rmdp-day {
        box-shadow: none;
        height: 28px;
        width: 28px;

        span {
          height: 28px;
          position: initial;
        }
      }

      .rmdp-week {
        margin-bottom: 4px;
      }

      .rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover {
        background-color: #1ABB78;
      }

      .rmdp-shadow {
        box-shadow: none;
      }

      .rmdp-calendar {
        padding: 0;
      }
    }

    &--inputs {
      display: flex;
      align-items: center;
      gap: 8px;
      position: absolute;
      z-index: 2000;
      left: 34px;
      top: 12px;

      .ant-select-item-option-selected {
        background-color: #1FB073 !important;
      }

      .ant-select-selector {
        border-color: #CDCDCD !important;
        box-shadow: none !important;
      }

      .ant-select-open {
        .ant-select-arrow {
          svg {
            transform: rotate(90deg);
          }
        }
      }

      .ant-select-arrow {
        svg {
          transform: rotate(-90deg);

          path {
            fill: black !important;
          }
        }
      }
    }
  }
}