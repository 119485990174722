@import "../../../../styles/mainVariables";

.video_details {

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &__protocol{
    .markdown-paragraph,h6, summary{
      font-size: 16px !important;
    }
  }


  &__wrap{
    //background-color: #F7F8F9;
    width: 100%;
    min-width: 1000px;
    //height: 100%;
    flex-grow: 1;
    margin-top: 32px;
    position: relative;
    //overflow-y: auto;
    transition: all 0.2s;
    //overflow-x: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    //padding: 0 32px;
    //max-width: 1200px;
    //margin: 0 32px;
  }


  nav{
    width: 100%;
  }


  &__header {
    display: flex;
    //align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    //margin-bottom: 28px;
    padding: 12px 32px 12px 32px;
    height: 88px;
    &__bread{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .breadcrumb {
        align-items: center;
        line-height: 24px;

        .breadcrumb-item {
          height: 20px;
          white-space: nowrap;
          display: flex;
          align-items: baseline;
          cursor: pointer;
          padding-left: 0;

          &:first-child {
            padding-left: 0;
          }

          &:before {
            padding: 0 4px;
          }

          a {
            color: #A8A8AC;
            font-size: 14px;
            font-style: normal;
            font-weight: 450;
            text-decoration: unset;
          }
        }

        .breadcrumb_name {
          font-size: 14px;
          line-height: 16px;
          font-weight: bold;
          margin: 0 0 20px;
          display: inline-block;
          color: black;
          max-width: 500px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .notification_btn{
        margin: 0 !important;
      }
      .custom-button{
        width: 102px;
        span{
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }

    &__back_btn {
      width: 40px;
      height: 40px;
      border: 1px solid $borderColor;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__description {
      width: 100%;
      &--title{
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        letter-spacing: -0.01em;
        color: black;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 80%;
      }

      &--wrap{
        display: flex;
        align-items: center;
        gap: 20px;
      }
      &--loader{
        cursor: pointer;
      }
    }

    &__date {
      color: #A8A8AC;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 16px;
    }

  }

  .full_screen {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 16px;
    left: 16px;
    overflow: hidden;
    background-color: transparent;
    border: 0;

    span {
      transition: all 0.2s;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: #000000;
      opacity: 0.5;
    }

    svg {
      position: absolute;
      z-index: 1;
    }

    &:hover {
      span {
        background-color: #1ABB78;
        opacity: 1;
      }
    }
  }

  .react-resizable-handle {
    display: none;
  }
  &.small_video.no-full{
    .draggable-video {
      left: 248px;
    }
  }
  &.small_video {

    .video_details__player{
      margin-bottom: 0;
    }
    .draggable-video {
      position: fixed;
      bottom: 14px;
      left: 128px;
      z-index: 1000;
      height: unset;

      .video-wrapper {
        position: relative;
        padding-top: 56.25%;
        cursor: pointer;
      }
    }

    .video_details__texts {
      .protocol {
        //margin-top: 280px;
      }
    }

    .video-wrapper .pin_video {
      top: 16px;
      right: 24px;
    }

    .notification-area {
      opacity: 0;
      margin-left: auto;
    }

    .react-resizable-handle {
      display: block;
    }
  }

  .small_video_play {
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    path {
      fill: white;
    }
  }

  .duration {
    position: absolute;
    left: 16px;
    bottom: 10px;
    color: white;
    font-size: 12px;
  }
  &__bottom{
    //height: 100vh;
    height: calc(100vh - 140px);
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1440px){
      max-width: 1200px;
    }

  }
  &__top {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    height: calc(100vh - 90px);


    &--container{
      display: flex;
      gap: 20px;
      height: calc(100vh - 315px);
    }

    @media screen and (min-width: 1440px){
      max-width: 1200px;
    }

    .notification-area {
      //min-width: 304px;
      //max-width: 341px;
      width: 50%;
      height: calc(100vh - 315px);
      background-color: white;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      gap: 16px;
      transition: width 1s ease;
      max-width: 341px;
      min-width: 301px;

      @media screen and (min-width: 1440px){
        width: 33%;
      }

      &__processing{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin-top: 100px;
        padding: 0 16px;

        &--title{
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          text-align: center;
          color: #9898A6;
          margin-top: 8px;
        }
        &--description{
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          text-align: center;
          color: #9898A6;
        }
      }

      &__header {
        color: black;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid rgba(230, 230, 230, 1);
        padding: 9px 16px 16px;

        @media screen and (min-width: 1440px){
          max-width: 1200px;
        }

        svg {
          path {
            fill: #cccccc
          }
        }
      }

      &__date {
        color: #19161D;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 250% */
        letter-spacing: 0.3px;
        opacity: 0.4;
        margin-bottom: 9px;
      }

      &__content {
        //height: 150px;
        width: 100%;
        padding: 16px;

        .notification-item {
          width: 100%;
          overflow: auto;
          color: #19161D;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 250% */
          letter-spacing: 0.3px;
          margin-bottom: 4px;

          .markdown-paragraph{
            padding-bottom: 1rem;
          }

          .time {
            opacity: 0.4;
            margin-right: 4px;
            margin-bottom: 4px;
            display: block;
          }

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background: white;
            border: 1px solid $borderColor;
          }

          &::-webkit-scrollbar-thumb {
            background-color: lightgray;
            border-radius: 10px;
            border: 1px solid $borderColor;
            min-height: 40px;
            min-width: 40px;
          }
        }
      }

      .area-bottom {
        margin-top: auto;
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }

      &::-webkit-scrollbar-track {
        background: white;
        border: 1px solid $borderColor;
      }

      &::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 10px;
        border: 1px solid $borderColor;
        min-height: 40px;
        min-width: 40px;
      }
    }

    .preview{
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: center;
      padding: 0 16px 16px;

      &--title{
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        text-align: center;
        color: rgba(152, 152, 166, 1);
      }
      &--description{
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        color: rgba(152, 152, 166, 1);
      }

      &__tags{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 8px;

        &--tag{
          background: rgba(152, 152, 166, 0.6);
          padding: 2px 8px;
          border-radius: 6px;
          color: white;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;
        }
      }
    }
  }

  &__video {
    width: calc(100%);
    height: calc(100vh - 315px);
    background-color: black;
    overflow: hidden;
    border-radius: 12px;



    .video-wrapper {
      position: relative;
      height: 100%;
      cursor: pointer;
      //padding-top: 56.25%;
      padding-right: 100%;
      &:hover{
        .play-btn{
          display: block;
        }
      }
      video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        object-fit: contain;
      }

      .pin_video {
        position: absolute;
        top: 16px;
        right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        cursor: pointer;
        border: 0;
        overflow: hidden;
        background-color: transparent;

        span {
          transition: all 0.2s;
          position: absolute;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background: #000000;
          opacity: 0.5;
        }

        svg {
          position: absolute;
          z-index: 1;
        }

        &:hover {
          span {
            background-color: #1ABB78;
            opacity: 1;
          }
        }

        &.pined {
          background-color: #1ABB78;
          opacity: 1;
        }

        &.pin-hide {
          display: none;
        }


      }

      .play-btn{
        background: none;
        cursor: pointer;
        display: none;
        border: none;
        width: 82px;
        height: 82px;
        position: absolute;
        margin-inline: auto;
        inset-inline: 0;
        inset-block-start: 50%;
        transform: translateY(-50%);

        &:active{
          outline: none;
        }
        &:hover{
          outline: none;
         svg{
           color: #1FB073;
           rect{
             opacity: 1 ;
           }
         }
        }
        &:focus{
          outline: none;
        }

        svg{
          flex-shrink: 0;
         rect{
           opacity: 0.4 ;
         }
          //margin-left: 7px;
        }
      }
    }

    &.resizable {
      width: 100%;
      height: 100%;
    }
  }

  .timeline-events {
    width: 100%;
    height: 5px;
    border-radius: 10px;
    background: rgba(34, 34, 34, 0.10);
    margin-top: 12px;
    display: flex;
    position: relative;

    .event {
      display: flex;
      border-radius: 10px;
      height: 100%;
    }

    .block {
      height: 100%;
    }
  }

  &__player {
    display: flex;
    flex-direction: column;
    //overflow: hidden;
    width: 100%;
    position: relative;
    margin-top: 20px;
    border-radius: 12px;
    //margin-bottom: 120px;

    @media screen and (min-width: 1440px){
      max-width: 1200px;
    }

    .marks {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    &__left {
      width: 256px;
      border-right: 1px solid $borderColor;
      background: white;
    }

    &__persons {
      &__item {
        height: 48px;
        display: flex;
        align-items: center;
        //border-bottom: 1px solid $borderColor;
        padding: 0 14px 0 14px;

        .check-btn {
          width: 40px;
          height: 40px;
          position: relative;

          .check-icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }


        &__img {
          border-radius: 50%;
          width: 30px;
          height: 30px;
          margin-right: 12px;
          flex-shrink: 0;
        }
        &-delete{
          margin-left: 12px;
          display: flex;
          align-items: center;
          svg {
            width: 15px;
            height: 15px;
            path {
              fill: #A8A8AC;
            }
          }
        }

        &__info {
          width: calc(100% - 40px);
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 500;
          color: #34303E;
          font-size: 13px;

          .author{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 150px;
            display: block;
          }

          .person_id {
            font-weight: 600;
            color: #BBBABF;
          }

          .time-wrapper {
            color: #9898A6;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            display: flex;
            align-items: center;
            margin-left: auto;

            .percent {
              display: flex;
              width: 38px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              border-radius: 5px;
              color: #FFF;
              font-size: 11px;
              font-style: normal;
              font-weight: 450;
              line-height: 20px;
              margin-left: 6px;
            }
          }
        }

        &__more {
          transform: rotate(90deg);
          cursor: pointer;
          margin-left: auto;
        }


      }
    }

    &__timeline-wrapper {
      width: 100%;
      padding: 12px 24px;
      border-radius: 12px;
      background: #FFF;
      position: relative;

      .timeline-marks {
        position: absolute;
        left: 24px;
        top: 80px;
      }

      .timeline {
        width: 100%;
        position: relative;
        padding: 10px 0 12px 0;
        margin-top: 3px;
        //overflow: auto;

        &__current-time-vert-line {
          position: absolute;
          top: 3px;
          bottom: 0;
          left:-2px;
          width: 16px;
          height: 16px;
          background: #1ABB78;
          border-radius: 50%;
          content: ' ';
          z-index: 100;
        }

        .talk-block {
          .block {
            height: 100%;
          }

          &.chapter {
            //background: white;
            .block {
              width: calc(100%);
              border-radius: 4px;
              background: #2222221a;
              cursor: pointer;
              height: 4px;
              transition: all 0.2s;
              position: relative;

              .chapter-info {
                width: 360px;
                border-radius: 4px;
                background: #181818;
                padding: 6px 16px;
                position: absolute;
                bottom: 10px;
                visibility: hidden;
                color: white;
                //white-space: nowrap;
                transform: translateX(-50%);
              }
            }


            &:hover {
              margin-top: -1px;
              transition: margin 0.5s ease-out;

              .block{
                height: 6px;
              }

              .chapter-info {
                visibility: visible;
              }
            }
          }
        }

        &__control {
          width: 100%;
          height: 5px;
          border-radius: 10px;
          background: white;
          position: relative;


          .timeline-time {
            padding: 16px;
            background: black;
            color: white;
            position: absolute;
            //visibility: visible;
          }
        }

        &::-webkit-scrollbar {
          width: 7px;
          height: 7px;
        }

        &::-webkit-scrollbar-track {
          background: white;
          border: 1px solid #F2F2F2;
        }

        &::-webkit-scrollbar-thumb {
          background-color: lightgray;
          border-radius: 10px;
          border: 1px solid #F2F2F2;
          min-height: 40px;
          min-width: 40px;
        }
      }

      .timeline-time {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        color: #828282
      }

      .controls-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;

        .speakers {
          .speakers-menu {

            .dropdown-content {
              left: 0;
              right: unset;
              padding-bottom: 16px;

              form {
                max-height: 360px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                  width: 4px;
                }

                &::-webkit-scrollbar-thumb {
                  background-color: #a8a7ad;
                }
              }
            }

            &__header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 12px 20px 12px 45px;
              color: black;
              white-space: nowrap;
              font-size: 16px;
              font-style: normal;
              font-weight: 450;
              line-height: 135%;
              position: relative;

              .speakers-icon {
                margin-right: 12px;
              }

              .check-icon {
                position: absolute;
                left: 14px;
              }

              .count {
                color: #9898A6;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
              }
            }
          }

          .speakers-btn {
            padding: 7px 12px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            border: 2px solid #E6E6E6;
            background-color: white;
            color: black;
            white-space: nowrap;
            font-size: 12px;
            font-style: normal;
            font-weight: 450;
            line-height: 135%;

            .speakers-icon {
              width: 16px;
              height: 16px;
            }

            .speakers-arrow {
              margin-left: 8px;
            }
          }
        }

        .controls {
          display: flex;
          align-items: center;

          .control_btn {
            width: 28px;
            height: 28px;
            background-color: transparent;
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              width: 100%;
              height: 100%;
            }
          }

          .controls__play {
            margin: 0 40px;
            width: 32px;
            height: 32px;

            svg {
              width: 100%;
              height: 100%;
            }
          }

          .speed-icon {
            margin-left: 80px;
            border-bottom: 2px dashed #6c6a72;
            border-top: 2px dashed #6c6a72;
            color: #6c6a72;
            font-size: 14px;
          }

          .speed-menu {

            .dropdown-content {
              top: 30px;
            }

            &__item {
              padding: 4px 16px 4px 26px;
              position: relative;

              &.active {
                &:after {
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  width: 8px;
                  height: 8px;
                  content: ' ';
                  background-color: #1ABB78;
                  border-radius: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }

        .zoom-wrap {
          display: flex;
          align-items: center;

          svg {
            cursor: pointer;

            &.disabled {
              path {
                fill: #9c9797;
              }
            }
          }

          .range-slider__wrap {
            width: 120px;
            margin: 0 8px;
          }

          button {
            border: 0;
            background-color: transparent;
            color: #1ABB78;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 0;

            &:after {
              content: '';
              width: 10px;
              height: 2px;
              border-radius: 2px;
              background-color: #1ABB78;
            }
          }

          &__in {
            &:before {
              content: '';
              width: 2px;
              height: 10px;
              border-radius: 2px;
              background-color: #1ABB78;
              position: absolute;
              top: -4px;
              right: 4px;
            }
          }

          .range-slider__wrap {

            input {
              &::-webkit-slider-thumb {
                appearance: none;
                width: 10px;
                height: 10px;
                background: #1ABB78;
                border: none;
                border-radius: 50%;
                cursor: pointer;
                margin-top: -4px;
              }

              &::-webkit-slider-runnable-track {
                background: #1ABB78;
                height: 2px;
              }
            }
          }

        }

      }

      &__time {
        height: 64px;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $borderColor;
        width: -moz-min-content;
        width: min-content;
        background: white;
        margin-top: 12px;

        .segment {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: end;
          overflow: hidden;
          position: relative;

          .line {
            width: 3px;
            height: 9px;
            background: #8E8C94;
            margin-bottom: 2px;
          }

          .dots {
            display: flex;
            justify-content: space-between;
            width: calc(100% - 40px);

            .dot {
              width: 3px;
              height: 4px;
              background: #C4C4C4;
              margin-bottom: 12px;
            }
          }

          .text {
            width: 100%;
            font-weight: 600;
            font-size: 10px;
            line-height: 130%;
            color: #A5A3A9;
            margin-bottom: 14px;
          }
        }
      }

      &__events {
        height: calc(100% - #{$timelineHeight});
        overflow: hidden;

        .event {
          //height: $personAndEventHeight;
          height: 20px;
          margin: 16px 0 26px 0;
          border-bottom: 1px solid rgba(160, 172, 189, 0.2);
          display: flex;
          flex-direction: row;
          /*width: min-content;*/
          min-width: 100%;
          background-color: white;

          .person-block {
            width: 0;
            align-self: flex-end;

            .block {
              height: 20px;
              background: #E0E0E0;
              border-radius: 20px;
            }
          }

          .talk-block {
            width: 0;
            align-self: flex-start;
            position: absolute;

            svg{
              width: 12px;
              height: 12px;
            }

            .block {
              height: 20px;
              border: 1px solid #DCDBDE;
              border-radius: 20px;
              background-image: linear-gradient(
                              135deg,
                              #DCDBDE 12.5%,
                              #ffffff 12.5%,
                              #ffffff 50%,
                              #DCDBDE 50%,
                              #DCDBDE 62.5%,
                              #ffffff 62.5%,
                              #ffffff 100%
              );
              background-size: 6px 6px;
            }
          }

          .positive-block {
            width: 0;
            align-self: flex-end;
            margin-bottom: 6px;

            .block {
              height: 16px;
              background: rgba(55, 255, 32, 0.2);
              border: 1px solid rgba(13, 56, 0, 0.2);
              border-radius: 8px;
            }
          }

          .fear-block {
            width: 0;
            align-self: flex-end;
            margin-bottom: 6px;

            .block {
              height: 16px;
              background: rgba(255, 204, 16, 0.44);
              border: 1px solid rgba(234, 193, 41, 0.8);
              border-radius: 8px;
            }
          }

          .negative-block {
            width: 0;
            align-self: flex-end;
            margin-bottom: 6px;

            .block {
              height: 16px;
              background: rgba(245, 41, 41, 0.44);
              border: 1px solid rgba(234, 41, 41, 1);
              border-radius: 8px;
            }
          }

          .surprise-block {
            width: 0;
            align-self: flex-end;
            margin-bottom: 6px;

            .block {
              height: 16px;
              background: rgba(223, 41, 245, 0.44);
              border: 1px solid #ba29ea;
              border-radius: 8px;
            }
          }

          .disgust-block {
            width: 0;
            align-self: flex-end;
            margin-bottom: 6px;

            .block {
              height: 16px;
              background: #9a6d3e;
              border: 1px solid #522a00;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }

  //.allotment-module_splitView__L-yRc,
  //.allotment-module_splitView__L-yRc > .allotment-module_splitViewContainer__rQnVa > .allotment-module_splitViewView__MGZ6O {
  //  overflow: unset;
  //}

  &__texts {
    display: flex;
    justify-content: space-between;
    padding: 24px 0 0 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    @media screen and (min-width: 1440px){
      max-width: 1200px;
    }

    .SplitPane {
      //height: calc(100vh - 120px) !important;
      margin: 0 30px;
    }

    .texts-header{
      height: 56px;
      display: flex;
      padding: 4px 24px 12px 24px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-bottom: 1px solid #D8D8D8;
      margin-left: 16px;
      margin-bottom: 12px;

      &__title{
        color: #212121;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
      }
    }

    .Pane {
      min-width: 400px;
    }

    .text-wrapper {
      margin-left: 40px;
      border: 0;

      &__processing{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin-top: 60px;
        margin-left: 40px;

        &--title{
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          text-align: center;
          color: #9898A6;
          margin-top: 8px;
        }
        &--description{
          font-size: 16px;
          font-weight: 450;
          line-height: 20px;
          text-align: center;
          color: #9898A6;
          max-width: 375px;
        }
      }

      .nav-item {
        position: relative;
        z-index: 100;
        //display: none;
        margin-right: 20px;

        .nav-link {
          &.active {
            .texts-update-menu {
              //z-index: 0!important;
            }
          }
        }

        button {
          background-color: transparent;
          border: 0;
          padding: 0;
          color: black;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 25px;
          opacity: 0.35;
          display: flex;

          &.active {
            opacity: 1;
          }
        }
      }
    }

    .tab-content {
      padding-top: 20px;
      height: 100%;

      .tab-pane {
        height: 100%;
        height: calc(100% - 97px);
        overflow: hidden auto;
      }
      .tab-pane::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }

      .tab-pane::-webkit-scrollbar-track {
        background: white;
        border: 1px solid #F2F2F2;
      }

      .tab-pane::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 10px;
        border: 1px solid #F2F2F2;
        min-height: 40px;
        min-width: 40px;
      }
    }

    .ai-notes {
      height: auto;
      width: 92%;
      margin-left: auto;
      margin-bottom: 4%;

      .accordion-item {
        background-color: white;
        //margin-bottom: 20px;
        padding: 24px;
        border-radius: 6px;
        height: 100%;

        .accordion-header {
          padding: 0;

          .accordion-button {
            width: 100%;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            color: black;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            border: 0 !important;

            svg {
              margin-left: 8px;
            }

            .arrow-icon {
              transform: rotate(180deg);
            }

            &.collapsed {
              display: flex;
              align-items: center;

              .arrow-icon {
                transform: rotate(0deg);
              }
            }

            &::after {
              display: none;
            }
          }

          .accordion-button:focus {
            z-index: 3;
            border-color: #ECECEC;
            outline: 0;
            box-shadow: unset;
          }

          .accordion-button:not(.collapsed) {
            color: unset;
            background-color: white;
            box-shadow: unset;
          }

        }

        .accordion-collapse {
          height: calc(100% - 20px);
        }

        .accordion-body {
          padding: 0;
          height: 100%;

          .texts {
            //padding-top: 20px;
            margin-left: 0;
            height: 100%;
          }
        }
      }
    }
  }

  .custom-scroll::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  .custom-scroll::-webkit-scrollbar-track {
    background: white;
    border: 1px solid $borderColor;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 10px;
    border: 1px solid $borderColor;
    min-height: 40px;
    min-width: 40px;
  }

  .form-check {
    display: flex;
    align-items: center;
  }

  //.form-check-input {
  //  width: 20px;
  //  height: 20px;
  //  border: 1px solid rgba(0, 0, 0, .25);
  //  margin-right: 10px;
  //  margin-bottom: 5px;
  //}
  //
  //.form-check-input:checked {
  //  background-color: #1ABB78;
  //  border-color: #1ABB78;
  //  //width: 20px;
  //  height: 20px;
  //}
  //
  //.form-check-input:focus {
  //  //border-color: transparent;
  //  outline: 0;
  //  box-shadow: none;
  //}

  .upload_video_answer {
    border-radius: 8px;
    background: #181818;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.20), 0 6px 30px 0 rgba(0, 0, 0, 0.12), 0 16px 24px 0 rgba(0, 0, 0, 0.14);
    color: white;
    text-align: left;
    display: flex;
    width: 343px;
    padding: 16px 20px;
    align-items: center;
    gap: 12px;

    svg {
      width: 28px;
      height: 28px;
      flex-shrink: 0;
    }

    .title {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.408px;
      margin-bottom: 8px;
    }

    .description {
      color: #C8C5C5;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.078px;
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 1100px) {

    &__header {
      display: flex;
      align-items: center;
      //height: 66px;
    }

    &__texts {
      flex-direction: column-reverse;
    }
  }
}

.custom-tooltip {
  margin: 0;
  opacity: 1 !important;
  div {
    background: transparent;
  }

  .tooltip-item {
    border-radius: 4px;
    background: #181818;
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;

    &.text {
      margin-bottom: 14px;
    }

    &.time {
      margin: auto;
      width: fit-content;
      border-radius: 12px;
    }
  }
}

.btn-scroll{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  &--btn{
    background: rgba(34, 34, 34, 0.6);
    border-radius: 41px;
    width: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      path{
        stroke: white;
      }
    }
  }
  &--hidden{
    display: none;
  }
}

.icon-disabled {
  svg {
    path{
      fill: #DADADA !important;
    }
  }
}

.toasblock{
  display: flex;
  align-items: center;
  gap: 20px;

  &__wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--title{
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: white;
    }
    &--text{
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #9898A6;
    }
  }
}

.texts-edit{
  height: calc(100% - 164px) !important;
}

.pages{
  position: fixed;
  right: 20px;
  top: 50%;
  z-index: 20;

  display: flex;
  flex-direction: column;
  gap: 10px;

  div{
    background: #6c6a72;
    width: 16px;
    height: 32px;
    border-radius: 8px;
    background: rgba(218, 218, 218, 1);
    cursor: pointer;
  }

  .active{
    background: rgba(149, 149, 149, 1);
  }
}


.ai-container{
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  gap: 24px;

  &__block{
    display: flex;
    flex-direction: column;
    border-top: 1px solid #CDCDCD;
    margin-bottom: 16px;

    &--title{
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      padding-top: 24px;
      padding-left: 24px;
      //margin-bottom: -8px;
    }
  }
}

.pilot-banner{
  background: rgba(254, 237, 203, 1);
  padding: 14px 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  &--icon{
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
  a {
    color: rgba(74, 74, 74, 1);
  }
}