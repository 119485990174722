.chats-list{
  height: 100%;

  &__header{
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 12px 24px 34px 24px;

    &--switcher{
      input{
        cursor: pointer;
        height: 16px;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"><circle r="3" fill="white"/></svg>') !important;

      }

      .form-check-label{
        margin-left: 10px;
      }

      .form-check-input{
        background-color: rgba(168, 168, 172, 1);
      }

      .form-check-input:checked{
        background-color: #1ABB78;
        border-color: #1ABB78;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"><circle r="3" fill="%23fff"/></svg>') !important;
      }
      .form-check-input:focus{
        box-shadow: none;
        border-color: #9898A6 !important;
      }

      label{
        font-size: 14px;
        font-weight: 450;
        line-height: 16px;
      }
    }

    &--edit-icon{
      cursor: pointer;
      color: #1abb78;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }
  }

  &__new-chat{
    padding: 0 20px;
  }

  &__search {
    width: 100%;
    display: flex;
    align-items: center;

    &-wrap {
      border: 1px solid #D9DEE2;
      border-radius: 20px;
      padding: 2px 10px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;

      input {
        border: none;
        outline: 0;
        padding: 0;
        background-color: #F2F4F7;
        width: 100%;

        &:focus-visible {
          border: none;
        }
      }

      &:active {
        border-color: #1FB073;
      }

      &:focus,
      &:focus-within {
        border: 1px solid #1ABB78;
      }
    }


  }

  &__container{
    //margin: 0 0 20px 0;
    overflow: hidden auto;
    min-width: 300px;

    &-title{
      margin: 0 24px 0 24px;
      display: block;
      position: sticky;
      top: 0;
      background-color: #F2F4F7;
      padding-bottom: 20px;
    }
  }

  &__feed{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0 24px 0 24px;

    &--item{
      cursor: pointer;
      padding: 8px 12px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 20px;


      &:hover{
        background: #D1F1E4;
      }

      &-text{
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-badge{
        width: 10px;
        height: 10px;
        background-color: #1abb78;
        border-radius: 50%;
        flex-shrink: 0;
        margin-left: auto;
      }

      &-active{
        background: #BBEBD7;
      }
    }
  }
}