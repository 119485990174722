.profile-media {
  width: fit-content;

  .actions {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    button {
      background-color: transparent;
      border: 0;
      outline: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      &:first-child {
        margin-right: 24px;
      }
    }
  }

  img {
    width: 240px;
    height: 240px;
    border-radius: 12px;
    object-fit: cover;
  }
}
