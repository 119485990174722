.filter-note-channels {
  width: 267px;
  .ant-popover-inner {
    padding: 0;
  }

  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }


  &__container {
    display: flex;
    flex-direction: column;
    min-width: 144px;
  }

  &__chains{
    padding: 12px 8px 8px 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: auto;

    &--item{
      display: flex;
      align-items: center;
      gap: 4px;
      span{
        color: var(--chain_color) !important;
        max-width: 110px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      svg{
        path{
          fill: var(--chain_color);
        }
      }
    }
  }

  &__list {
    padding: 0 16px 16px 16px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
    max-height: 240px;
    height: 100%;

    &--item{
      label{
        background-color: var(--chain_bg);
        color: var(--chain_color);
        padding: 4px 12px;
        border-radius:60px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 150px;
      }
      input{
        margin-top: 2px;
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 4px 8px;
    border: 1px solid #CDCDCD;
    border-radius: 8px;
    width: max-content;

    &:hover {
      border-color: #1FB073;
    }

    svg:first-child {
      width: 20px;
      height: 18px;
      flex-shrink: 0;
    }

    &--text {
      font-size: 14px;
      font-weight: 450;
      line-height: 16px;
      color: #212121;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 150px;
      width: 100%;
    }

    &--arrow {
      transform: rotate(-90deg);
      flex-shrink: 0;

      path {
        fill: black;
      }
    }

    &--arrow.open {
      transform: rotate(90deg);

      path {
        fill: black;
      }
    }
  }

  &__label.selected {
    background-color: #DDF5EB;
    border-color: #1ABB78;
  }

  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 16px;
    border-top: 1px solid #E6E6E6;

    button {
      width: 100%;
    }
  }

  &__tooltip {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}