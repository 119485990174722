@import "../../styles/mainVariables";

.ant-layout{
  .main-container {
    margin: 0 32px;
  }
}
.ant-layout-content{
  margin: 0 32px;
  //background-color: #F2F2F2;
}

.layout {
  display: flex;
  min-height: calc(100vh - 20px);
  min-width: 1200px;
  justify-content: flex-start;
  background-color: #F2F2F2;

  .main-container {
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    padding-left: 208px;
    flex-grow: 1;
    margin-top: 61px;
    overflow: auto;
    //transition: all 0.2s;

    .page-title{
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.01em;
      color: black;
      width: 100%;
      margin: 10px 0 20px 0;
    }

    .pagination {
      padding: 0 32px;
    }

    &.full{
      padding-left: $menuWidthSmall;
    }
  }
}