@import "../_mixins.scss";

.recognition{
  width: 100%;
  margin-top: 48px;

  &__wrap{
    display: flex;
    flex-direction: column;
    gap: 12px;

    &--title{
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
    }
    &--description{
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      color: #9898A6;
    }
  }

  &__accordion{
    @include accordion-mixin();
    margin-top: 8px;
    .accordion-header {
      padding: 14px 20px 12px 20px !important;
    }
    .accordion-button {
      font-size: 16px !important;
      line-height: 20px !important;
    }

    //&--item{
    //  background-color: transparent !important;
    //  padding: 11px 20px !important;
    //  border: 0.6px solid #CDCDCD !important;
    //  border-radius: 8px !important;
    //}
    //
    //&--header{
    //  button{
    //    display: flex;
    //    align-items: center;
    //    flex-direction: row-reverse;
    //    justify-content: space-between;
    //    background-color: transparent !important;
    //    font-size: 16px !important;
    //    font-weight: 600;
    //    line-height: 20px !important;
    //  }
    //}
  }
}