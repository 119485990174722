.recap-title-block{
  display: flex;
  align-items: center;
  gap: 8px;

  .text-markdown{
    color: black;
    font-size: 14px;
    font-weight: 450;
    line-height: 20px;
    cursor: default;
  }

  span{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
  &--text{
    color: #37A87B;
    cursor: pointer;
    &:hover{
      color:#45C090
    }
  }
  &--to{
    color: #4A4A4A;
  }
}