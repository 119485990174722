.extension-block{
  width: 70%;
  padding: 24px 40px;

  &--title{
    padding-bottom: 16px;
    display: block;
  }
  &__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-bottom: 1px solid #CDCDCD;
    padding-bottom: 16px;

  }

  &__body{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    margin-top: 12px;

    button{
      width: 72px;
    }
  }

}