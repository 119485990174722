.message-block{
  width: 100%;
  padding: 16px 10px 16px 0;
  background-color: white;
  //height: calc(100vh - 325px);
  //height: 372px;
  &::after{
    content: '';
    width: 20px;
  }

  &__wrap{
    overflow: hidden auto;
    height: 100%;
    min-width: 500px;
  }
  &__list{
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-end;
  }

  .message{
    width: 100%;
    padding: 8px  32px 8px 24px;
    display: flex;
    align-items: flex-start;
    gap: 12px;

    img{
      width: 36px;
      height: 36px;
      flex-shrink: 0;
      border-radius: 50%;
    }

    &__avatar-bot{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F2F2F2;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      border: 1px solid #CDCDCD;
    }

    &__block{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      //padding-right: 30px;

      &_title{
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;

        &--name{
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
        }

        &--date{
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;

          display: flex;
          align-items: center;
          gap: 2px;
          color: #9898A6;
          white-space: nowrap;
        }
      }

      &--text{
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(74, 74, 74, 1);
        a{
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &--text-loader{
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #9898A6;
      }

      &--text-error{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #FA8C8C;
      }

      &__details{
        &--header{
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: rgb(152, 152, 166);
          .markdown-paragraph{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        summary{
          text-decoration: none !important;
          display: flex;
          gap: 8px;

        }
        &--body{
          padding: 5px 0 5px 10px;
          color: rgb(152, 152, 166);
        }
      }


    }
  }
}

.clean-chat{
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #E6E6E6;
  padding: 16px 24px;

  &__video-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;

    &--name{
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color: rgba(152, 152, 166, 1);
      white-space: nowrap;
      text-overflow: ellipsis ;
      overflow: hidden;
      width: 100%;
      text-align: center;
    }
    &--date{
      font-size: 14px;
      font-weight: 450;
      line-height: 20px;
      color: rgba(152, 152, 166, 1);
    }
  }

  &--disabled{
    .clean-chat--btn{
      svg{
        opacity: 0.5;
      }
    }
  }

  &--btn{
    color: #1ABB78;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    cursor: pointer;
  }

  &--burger{
    cursor: pointer;
    color: #1abb78;
    width: 20px;
    height: 20px;
  }


  &--right-block{
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &--edit-icon{
    cursor: pointer;
    color: #1abb78;
    width: 20px;
    height: 20px;
  }

  span{
    white-space: nowrap;
  }

  &--switcher{
    display: flex !important;
    align-items: center;
    justify-content: space-around;
    flex-direction: row-reverse;
    gap: 15px;
    padding: 0;
    width: 100px;
    padding: 0 !important;

    input{
      cursor: pointer;
      height: 16px;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"><circle r="3" fill="white"/></svg>') !important;
      margin-bottom: 3px !important;

    }
    .form-check-input{
      background-color: rgba(168, 168, 172, 1);
    }

    .form-check-input:checked{
      background-color: #1ABB78;
      border-color: #1ABB78;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"><circle r="3" fill="%23fff"/></svg>') !important;
    }
    .form-check-input:focus{
      box-shadow: none;
      border-color: #9898A6 !important;
    }

    label{
      font-size: 14px;
      font-weight: 450;
      line-height: 16px;
    }
  }

}