
.save-tag-btn {
  width: 100%;
  background-color: #1ABB78;
  color: white;
  border: 0;
  border-radius: 10px;
  display: block;
  height: 40px;
  margin: 0;
}

.tabs-dropdown {
  width: 100%;

  .dropdown-content {
    width: 443px;
    right: unset;
    left: 50%;
  }
}

.profile-tags-title {
  color: black;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 12px 0;
}

.profile-tags {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 8px;
  margin-bottom: 16px;

  &__item {
    width: fit-content;
    padding: 4px 12px;
    background-color: white;
    color: #1ABB78;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 4px;
      width: 12px;
      height: 12px;
      cursor: pointer;

      path {
        fill: #1ABB78;
      }
    }
  }
}

.profile-tags{
  .actions-menu{
    width: 100%;
  }
}

.popover_tags {
  width: 304px;
  z-index: 100000;

  .ant-popover-inner {
    padding: 0;
  }


  &__list {
    border-bottom: 1px solid #0000000f;
    padding: 16px 24px;

    .tags-form {
      display: flex;
      flex-direction: column;

    }

    .buttons {
      display: flex;
      align-items: center;
      padding-left: 12px;

      button {
        background-color: transparent;
        border: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin: 0;
      }

      .close-btn {
        color: #7F7F7F;
      }

      .save-btn {
        color: #1abb78;

        &.disabled {
          opacity: 0.5;
        }
      }
    }


    .create-tag {
      width: 100%;
      background-color: transparent;
      border: 0;
      outline: 0;
      color: #1ABB78;
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 16px;

      svg {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }

      span {
        font-size: 14px;
        font-weight: 450;
        line-height: 16px;
        text-align: left;
      }


      input {
        width: 100%;
        border-radius: 6px;
        border: 1px solid #D9DEE2;
        padding: 4px 12px;

        &:focus {
          outline: 0;
          border: 1px solid #1abb78;
        }
      }
    }

    .tag-item {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      padding: 0;
      position: relative;

      .label {
        max-width: calc(100% - 60px);
        padding: 4px 12px;
        border-radius: 6px;
        background-color: white;
        color: #1ABB78;
        border: 1px solid #D9D9D9;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        height: 26px;

        &.editing-tag {
          width: 100%;

          &:focus {
            //border: 0;
            outline: 0;
          }
        }
      }

      .tag-menu {
        //position: absolute;
        //right: 0;
        //top: 50%;
        //transform: translateY(-50%);
        //cursor: pointer;
        //z-index: 1001;
        margin-left: auto;

        .dropdown-content {
          top: 26px;
        }
      }

      .icon-delete, .icon-edit, {
        position: absolute;
        right: 0;
        cursor: pointer;
        opacity: 0;

        path {
          fill: #A8A8AC;
        }
      }

      .icon-delete {
        right: 34px;
      }

      &-edit {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      input{
        line-height: 15px;
      }

      &:hover {
        .tag-menu, .icon-delete, .icon-edit, .icon-menu {
          opacity: 1;
        }
      }
    }

    .check-wrapper {
      height: 20px;
      //display: flex;
      //align-items: center;
    }

    .form-check {
      display: flex;
      align-items: center;
    }

    .form-check-input {
      width: 20px;
      height: 20px;
      border: 1px solid rgba(0, 0, 0, .25);
      margin-right: 10px;
      margin-top: 0;
    }

    .form-check-input:checked {
      background-color: #1ABB78;
      border-color: #1ABB78;
      width: 20px;
      height: 20px;
    }

    .form-check-input:focus {
      //border-color: transparent;
      outline: 0;
      box-shadow: none;
    }
  }

  &--title {
    padding: 12px 16px;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    white-space: nowrap;
    border-bottom: 1px solid #0000000f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;

      path {
        fill: #d8d8d8;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 1.5px solid #1ABB78;
    cursor: pointer;

    span {
      color: #1ABB78;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      transition: all 0.2s;
    }

    svg {
      width: 24px;
      height: 24px;

      path {
        stroke: #1ABB78;
      }
    }

    &:hover {
      svg {
        path {
          fill: #1ABB781A;
        }
      }

      background-color: #1ABB781A;
    }

    &:active {
      svg {
        path {
          fill: #1ABB7833;
        }
      }

      background-color: #1ABB7833;
    }
  }
}