
.auto-chains-modal{
  padding: 0 !important;

  .ant-modal-close, .ant-modal-footer {
    display: none;
  }

  &--wrapper{
    .ant-modal{
      width: 740px !important;
    }
    z-index: 1010 !important;
  }
  &--wrapper.mini {
        .ant-modal {
          width: 380px !important;
      }
    .auto-chains-modal__body{
      height: auto;
    }
    .auto-chains-modal__header{
      padding: 20px 24px;
    }
    .auto-chains-modal__footer{
      padding: 16px 24px 20px 24px;
    }
  }

  &__header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E6E6E6;
    padding: 20px 32px;


    &--wrap{
      display: flex;
      align-items: center;
      gap: 12px;
      max-width: 90%;

      svg{
        width: 24px;
        height: 24px;
        color: #A8A8AC;
        margin-top: 6px;
      }
    }

    &--close{
      color: #7F7F7F;
      width: 24px;
      height: 24px;
      cursor: pointer;
      path{
        stroke-width: 0.8px;
      }
    }

    &--title{
      font-size: 22px;
      font-weight: 500;
      line-height: 27px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__body{
    display: flex;
    height: 500px;
    padding: 12px 20px;

    &--types{
      .form-check-label{
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  &__footer{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 16px 32px 20px 32px;
    border-top: 1px solid #E6E6E6;

    button{
      width: 100%;
    }
  }

  &__accept{
    width: 100%;
    padding: 12px 32px;

    &--container.auto{
      border: 1px solid #FFA800;
      margin-top: 10px;

      svg{
        path{
          fill: #FFA800;
        }
      }
    }

    &--container{
      border-radius: 10px;
      border: 1px solid #A8A8AC;
      display: flex;
      align-items: self-start;
      gap: 12px;
      padding: 14px 12px;

      svg{
        flex-shrink: 0;
        path{
          fill: #9898A6;
        }
      }
    }

    &--description{
      span{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #9898A6;
        margin-bottom: 10px;
        display: block;
      }

      .form-check-label{
        color: #9898A6;
      }
    }
  }
}