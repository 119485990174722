.pagination{
  gap: 4px;
  .page-item:first-child .page-link{
    border-radius: 10px;
  }
  .page-item:last-child .page-link{
    border-radius: 10px;
  }
  .page-item, .page-link {
    width: 32px;
    height: 32px;
  }

  .page-item.active{
    .page-link{
      background-color:#1ABB78;
      color: white;
      border-color: #1ABB78;
    }
  }
  .page-link{
    border-radius: 10px;
    box-shadow: none;
    background-color: white;
    color: black;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;


    display: flex;
    align-items: center;
    justify-content: center;
    &:focus{
      box-shadow: none;
    }

    svg{
      width: 12px;
      height: 12px;
    }

    span{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover{
      background: #E9F9F2;
      border-color: transparent;
      color: black;
    }
  }

  .pagination-ellipsis{
    .page-link{
      background-color: transparent;
      border: none;
      cursor: default;
    }
  }

  .pagination-info{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 24px;

    .ant-select-selector{
      border-color: #E9F9F2 !important;
      box-shadow: none !important;
      &:hover{
        border-color: #1ABB78 !important;
      }
    }
    .ant-select-open {
      border-color: #1ABB78 !important;

      .ant-select-arrow {
        svg {
          transform: rotate(90deg);
        }
      }
    }

    .ant-select-arrow {
      svg {
        transform: rotate(-90deg);

        path {
          fill: black !important;
        }
      }
    }
  }
}

.page-select{
   border: 1px solid #1ABB78;
  .ant-select-item {
    background-color: white !important;
    &:hover{
      background-color: #E9F9F2 !important;
    }
  }
  .ant-select-item-option-selected {
    background-color: #E9F9F2 !important;
  }
}