@import "../../../../../../modules/UserInfo/components/_mixins.scss";

.user_info_modal {
  flex-direction: column;
  .accordion{
    padding-bottom: 0;
  }
  &__media{
    width: 240px;
  }
  &__wrapper {
    display: flex;
    padding: 32px;
    justify-content: space-between;
  }

  &__content {
    width: calc(100% - 260px);
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .accordion-item{
      background-color: rgba(236, 236, 236, 0.22);
    }
  }

  &__tags {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    &__item {
      width: fit-content;
      padding: 3px 12px;
      border-radius: 60px;
      background-color: white;
      color: #1ABB78;
      border: 1px solid #D9D9D9;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      margin: 0 6px 12px 0;
      display: flex;
      align-items: center;

      svg {
        margin-left: 4px;
        width: 12px;
        height: 12px;
        cursor: pointer;

        path {
          fill: #1ABB78;
        }
      }
    }
  }

  .row-item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    margin-bottom: 20px;
  }

  .input_group {
    display: flex;
    flex-direction: column;
    width: 48%;
    position: relative;

    .input-icon {
      position: absolute;
      right: 16px;
      top: 38px;
      cursor: pointer;
    }

    label {
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #000000;
      opacity: 0.5;
      margin-bottom: 5px;
    }

    input, textarea {
      padding: 12px;
      width: 100%;
      height: 48px;
      background: #EFEFEF;
      border-radius: 10px;
      border: 0;
      outline: 0;
    }

    textarea {
      height: 100%;
    }

    &.full {
      width: 100%;
      height: calc(100% - 200px);

      textarea {
        width: 100%;
        height: 100%
      }
    }

    &__error {
      color: red;
      font-size: 12px;
      padding-top: 4px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .input-form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .form-switch {
    display: flex;
    align-items: center;

    .form-check-label {
      color: #77777D;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-left: 10px;
    }

    .form-check-input {
      width: 36px !important;
      height: 22px;

      &:checked {
        background-color: #1abb78;
        border-color: #1abb78;
      }
    }
  }

  .contact-header {
    position: relative;

    .arrow-icon {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.info_modal {
  .loader-wrap{
    height: 90vh;
    width: 834px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    width: 834px;

    .user_info_modal{
      width: 834px;

    }

    .user-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: baseline !important;
      padding: 24px 32px;
      border-bottom: 1px solid #ECECEC;

      svg{
       path{
         stroke-opacity: 1;
       }
      }

      .title {
        //width: 100%;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 0;

        .copy-link {
          color: #8E8C94;
          font-size: 14px;
          //margin-left: 6px;
          cursor: pointer;
          display: block;
        }
      }

      button {
        background-color: transparent;
        border: 0;
        outline: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #1ABB78;
      }

      svg{
        cursor: pointer;
      }
    }

    .nav-tabs {
      border: 0;
      padding: 32px 32px 0 32px;
      border-top: 1px solid #D9D9D9;

      .nav-link {
        border: 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        opacity: 0.5;
        color: #000000;

        .count {
          font-size: 15px;
          line-height: 25px;
          opacity: 0.2;
          margin-left: 4px;
        }

        &.active {
          color: #000000;
          opacity: 1;
        }
      }
    }

    .tab-content {
      padding: 28px 20px 32px 20px;
    }

    .protocol-list {
      display: flex;
      flex-wrap: wrap;
      max-height: 300px;
      overflow-y: auto;

      .protocol-item {
        width: 48%;
        height: fit-content;
        background-color: white;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: #46454E;
        padding: 16px 24px;
        border: 1px solid #D9D9D9;
        border-radius: 16px;
        position: relative;
        display: block;
        margin-right: 2%;
        margin-bottom: 16px;

        &__content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          .left {
            width: calc(100% - 60px);

            .video-title {
              display: flex;
              align-items: center;
              width: 100%;

              .span {
                color: #7E7E7E;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
              }

              .video-link {
                display: block;
                align-items: center;
                text-decoration: none;
                color: #1ABB78;
                width: calc(100%);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-left: 6px;
              }
            }

            .name {
              font-weight: 600;
              font-size: 16px;
              line-height: 135%;
              color: #1D1929;
            }

            .text {
              margin-bottom: 8px;
              height: 54px;
              overflow: hidden;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 130%; /* 18.2px */
              color: #46454E;
            }
          }

          .right {
            width: 60px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: end;

            .highlights-star {
              position: absolute;
              top: 0;
              right: 0;
              cursor: pointer;
            }

            .time {
              position: absolute;
              bottom: 0;
              right: 0;
              font-weight: 500;
              font-size: 13px;
              line-height: 135%;
              text-align: right;
              color: #A8A8AC;
            }
          }
        }
      }

      .custom-scroll::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }

      .custom-scroll::-webkit-scrollbar-track {
        background: white;
        border: 1px solid #F2F2F2;
      }

      .custom-scroll::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 10px;
        border: 1px solid #F2F2F2;
        min-height: 40px;
        min-width: 40px;
      }
    }

    .settings_tab {
      margin: 32px;
      border: 2px solid #CDCDCD;
      box-shadow: 0px 3px 11px 0px #2855391F;
      border-radius: 12px;

      .nav-tabs {
        padding: 32px 20px 0 20px;
        border: 0;
        display: flex;
        justify-content: space-between;

        .nav-item {
          //background-color: #ECECEC38;
          width: 48%;
          border: 0 !important;

          &:first-child {
            .nav-link {
            }
          }
        }

        .nav-link {
          border: 0 !important;
          display: flex;
          padding: 0;
        }

        .tab-title {
          color: #A8A8AC;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          display: flex;
          align-items: center;
          padding-bottom: 8px;

          svg {
            margin-left: 8px;
          }
        }

        .nav-link.active {
          border: 0;
          border-radius: 0;
          background-color: transparent;
          border-bottom: 2px solid #1ABB78 !important;

          .tab-title {
            color: black;
          }
        }
      }

      .tab-content {

        .user-info-settings {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            display: block;
            width: 100%;
            margin-bottom: 28px;
            opacity: 0.6;
          }

          .input_group {
            margin-bottom: 0;
          }

          .warning-info{
            width: 100%;
            border-radius: 12px;
            border: 1px solid #FFA800;
            display: flex;
            padding: 12px;
            align-items: center;
            gap: 12px;
            align-self: stretch;
            margin-top: 20px;

            svg{
              width: 24px;
              height: 24px;
            }

            span{
              width: fit-content;
              color: #000;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 19px;
              opacity: 0.6;
            }
          }
        }
      }
    }


    .accordion {
      margin: 0;
      .roles-accordion {
        &__list {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;

          &--item {
            padding: 4px 12px;
            border-radius: 60px;
            color: #F74040;
            background: #FEEBEB;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
      &.system {
        //border-top: 0;
        margin-top: 0;
        .connect_person{
          display: flex;
          span{
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            opacity: 0.6;
          }
        }
      }
    }

    .buttons-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 32px;
      margin-top: 32px;
      border-top: 1px solid #ECECEC;
      gap: 20px;

      button {
        width: 50%;
        height: 45px;
        display: block;
        border-radius: 10px;
        border: 1px solid #1ABB78;
        color: #1abb78;
        background: #FFF;
        outline: 0;
        font-weight: 450;
        font-size: 18px;
        line-height: 25px;

        &.disabled {
          opacity: 0.5;
        }
      }

      .save_btn {
        background: #1ABB78;
        color: white;

        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }


  .remove-user{
    width: 100%;
    margin-top: 48px;

    &__wrap{
      display: flex;
      flex-direction: column;
      gap: 16px;

      &--title{
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
      }
      &--description{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        span{
          font-size: 16px;
          font-weight: 400;
          line-height: 20.8px;
          color: #9898A6;
        }

        button{
          display: flex;
          align-items: center;
          gap: 10px;
          background-color: transparent;
          border: 0;
          width: max-content;
          padding: 4px 24px;
          white-space: nowrap;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          color: #DB1F26;
          &:hover{
            color: #E5484E;

            svg{
              path{
                fill: #E5484E;
              }
            }
          }

          svg{
            width: 20px;
            height: 20px;

            path{
              fill: #DB1F26;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .modal-dialog {
    max-width: 800px !important;
    width: 100vw !important;

    .modal-content {
      width: 94% !important;
    }
  }
}

.roles-accordion{
  @include accordion-mixin();
  margin-top: 28px;
}