.autocomplete_input {
  position: relative;

  input {
    width: 100%;
    border: none;
    &:focus {
      outline: 0;
      border: none;
    }
  }

  &-wrap{
    display: flex;
    align-items: center;
  }

  .persons_list {
    position: absolute;
    width: 100%;
    z-index: 100;
    max-height: 300px;
    overflow: auto;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(22, 22, 26, 0.1);
    padding: 12px;
  }

  .person_item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;

    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #f2f3fc;
      margin-right: 8px;
    }

    .person_info {
      width: calc(100% - 40px);
      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: black;
      }

      .email {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #9898A6;
        margin-top: 2px;
      }
    }
  }
}