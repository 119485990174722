.chains-dropdown{
  display: flex;
  flex-direction: column;
  width: 320px;
  cursor: default;

  &__wrap{
    padding: 8px 16px 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 270px;
    overflow: hidden auto;
  }

  &__header{
    padding: 12px 16px;
    border-bottom: 1px solid rgba(236, 236, 236, 1);

    &--title{
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
    }
  }

  &__add{
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    padding: 8px 2px;
    width: max-content;

    &:hover{
      &--title{
        color: rgba(41, 217, 144, 1);
      }
      svg {
        rect {
          fill: rgba(41, 217, 144, 1);
        }
      }
    }

    svg {
      width: 16px;
      height: 16px;
      rect {
        fill: rgba(31, 176, 115, 1);
      }
    }

    &--title{
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      color: rgba(31, 176, 115, 1);
    }
  }

  .input-wrap{
    display: flex;
    align-items: center;
    gap: 12px;

    &__input {
      width: 100%;
      height: 32px;
      padding: 6px 12px;
      border-radius: 10px;
      border: 1px solid #A6A6A6;
      background: #FFF;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &:focus {
        border-color: #1abb78;
        outline: 0;
      }
    }

    &__buttons{
      display: flex;
      align-items: center;
      gap: 8px;
      button {
        width: 50%;
        height: 32px;
        display: block;
        border-radius: 10px;
        border: none;
        color: rgba(127, 127, 127, 1);
        background: #FFF;
        outline: 0;
        font-weight: 450;
        font-size: 12px;
        line-height: 16px;

        &.disabled {
          opacity: 0.5;
        }
      }

      .save_btn {
        color: #1ABB78;

        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }

  .chain-item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 6px 0 6px 0;

    &--name{
      text-decoration: none;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &:hover{
      a{
        svg{
          path{
            fill: var(--chain_сolor);
          }
        }
      }
    }

    .actions-menu{
      margin-left: auto;
    }

    &__badge{
      padding: 4px 8px;
      background: var(--chain_bg);
      border-radius: 10px;
      height: 24px;
      display: flex;
      align-items: center;
      gap: 4px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      svg{
        width: 12px;
        height: 12px;
        flex-shrink: 0;

        path{
          fill: var(--chain_сolor);
        }
      }

      &--text{
        font-size: 14px;
        font-weight: 450;
        line-height: 16px;
        text-align: left;
        color: var(--chain_сolor);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .form-check-input:disabled {
      cursor: not-allowed;
      pointer-events: auto;
    }
    .form-check-input{
      z-index: 10;
      border: 1px solid  #A8A8AC;
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-top: 2px;
    }
    .form-check-input:checked {
      background-color: #1ABB78;
      border-color: #1ABB78;
    }

    .form-check-input:focus {
      outline: 0;
      box-shadow: none;
    }

    .form-check {
      margin-right: 30px;
    }
  }
}

.toasblock{
  display: flex;
  align-items: center;
  gap: 20px;

  &__wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--title{
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: white;
    }
    &--text{
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #9898A6;
    }
  }
}

 .upload_video_answer {
    border-radius: 8px;
    background: #181818;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.20), 0 6px 30px 0 rgba(0, 0, 0, 0.12), 0 16px 24px 0 rgba(0, 0, 0, 0.14);
    color: white;
    text-align: left;
    display: flex;
    width: 343px;
    padding: 16px 20px;
    align-items: center;
    gap: 12px;
}

 .popover_edit-menu{
   .ant-popover-inner{
     padding: 0;
     border-radius: 12px;

   }
    &--settings{
      cursor: pointer;
      margin-left: auto;
    }

   &--item{
     display: flex;
     align-items: center;
     padding: 16px;
     cursor: pointer;
     color: black;
     font-style: normal;
     font-weight: 400;
     border-bottom: 1px solid rgba(0, 0, 0, 0.05);
     &:last-child{
       border-radius: 0 0 12px 12px !important;
     }
     &:first-child{
       border-radius: 12px 12px  0 0 !important;
     }

     > svg {
       margin-right: 14px;
     }

     &:hover{
       background-color: #E9F9F2;
       border-color: #E6E6E6;
       border-radius: 12px;
     }
     &:active{
       background-color: #BBEBD7;
       border-color: #E6E6E6;
     }
   }
 }