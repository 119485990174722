.channels-container {
  display: flex;
  justify-content: space-between;
  padding-right: 0!important;
  //margin: 0 -32px !important;
  .empty-info{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 100px;

    &--text{
      color: #9898A6;
      font-size: 16px;
      font-weight: 450;
      line-height: 20px;
      text-align: center;
    }
  }
  .channels-page {
    width: calc(100%);
    display: flex;
    flex-direction: column;
    color: #111826;
    flex-wrap: wrap;
    font-style: normal;
    //padding: 0 32px;
    margin-top: 20px;
    align-items: center;

    .filters-list{
      width: 100%;
      max-width: 1200px;
      border: none;
    }

    &__info{
      display: flex;
      align-items: center;
    }

    &__wrap{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      max-width: 1200px;
      gap: 12px;

      .filters-all{
        margin-left: auto;
      }
    }


     .channels__section-videos{
      max-width: 1200px;
      width: 100%;
       margin-top: 14px;


       .video{
         &--poster{
           width: 23%;
           max-width: 270px;

           &-img{
             height: 151px;
             width: 100%;
           }

           .video__channels--badge{
             max-width: 148px;
           }
         }
         &__info-date{
           flex-wrap: nowrap;
           white-space: nowrap;
         }
         &__time{
           left: 10px;
           width: max-content;
         }

         &__info--wrap{
           max-width: 70%;
         }
       }
    }


    .breadcrumb{
      width: 100%;
      margin-bottom: 0;
      .breadcrumb-item.active{
        span{
          color: black;
        }
      }
      .breadcrumb-item{
        height: fit-content;
        a, span{
          color: #9898A6;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          text-decoration: unset;
        }
        &::before{
          font-weight: bold;
        }
      }
    }

    &__name {
      font-size: 24px;
      line-height: 25px;
      font-weight: bold;
      display: inline-block;
      color: black;
    }

    .video_card{
      width: calc(25% - 18px);
    }
  }

  &__footer{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 40px 0 0 0;
    max-width: 1200px;

    .pagination{
      padding: 0 !important;
    }
  }

}

.channels-info{
  &--text{
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: rgba(152, 152, 166, 1);
    text-transform: lowercase;
    cursor: pointer;
  }
}

.chains-menu{
  .dropdown-content {
    right: unset;
    left: 0;
    top: 26px;
  }
}