.user-video-container {
  display: flex;
  justify-content: center;
  padding-right: 0 !important;
  overflow: hidden !important;
  align-items: flex-start;

  &__footer{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 40px 0 0 0;
    max-width: 1200px;

    .pagination{
      padding: 0 !important;
    }
  }

  .empty-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 100px;

    &--text {
      color: #9898A6;
      font-size: 16px;
      font-weight: 450;
      line-height: 20px;
      text-align: center;
    }
  }
  .user-videos-page {
    width: calc(100%);
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    color: #111826;
    flex-wrap: wrap;
    font-style: normal;
    margin-top: 20px;
    margin-bottom: 40px;

    &__header{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .filters-list{
      border: none;
    }

    .breadcrumb {
      width: 100%;
      margin: 0;

      .breadcrumb-item {
        margin-bottom: 0;
        a {
          color: #9898A6;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          text-decoration: unset;
        }

        &::before {
          font-weight: bold;
        }
      }

      .breadcrumb_name {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        //margin: 0 0 20px;
        display: inline-block;
        color: black;

        a {
          color: black;
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    &__name {
      font-size: 24px;
      line-height: 25px;
      font-weight: bold;
      margin: 0 0 20px;
      display: inline-block;
      color: black;
    }

    &__videos {
      flex-wrap: wrap;
      width: 100%;
      gap: 24px;
      grid-template-columns: repeat(4, minmax(100px, 290px));
      display: grid;
      margin-top: 14px;

      &--item{
        position: relative;
        .video {
          &--poster {
            width: 23%;
            max-width: 270px;

            &-img {
              height: 151px;
              width: 100%;
            }

            .video__channels--badge {
              max-width: 148px;
            }
          }
        }
        &:hover {
          .user-videos-page__videos--detach {
            display: block;
          }
        }
      }
      &--detach{
          position: absolute;
          top: 8px;
          right: 12px;
          z-index: 10;
          display: none;

          &--icon {
            border-radius: 50%;
            width: 29px;
            height: 29px;
            background-color: rgba(0, 0, 0, 0.7);

            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
              width: 13px;
              height: 13px;

              path {
                stroke-opacity: 1;
                stroke: white;
              }
            }
          }
      }

      .video__time{
        left: 12px;
        right: unset;
      }
      .video--poster{
        max-width: unset;
        width: 100%;
      }
      .video__channels--badge{
        max-width: unset;
      }
      .user-group__list--avatar, .user-group__list--more{
        width: 28px !important;
        height: 28px !important;
      }
      video{
        height: 180px;
      }
    }

    .video_card {
      width: calc(25% - 24px);
    }

    &__loader-wrap{
      width: 100%;
      margin-top: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

