.calendar {
  min-height: calc(100% - 80px) !important;
  margin-left: auto;
  height: 100%;
  overflow: hidden;
  max-width: 248px;

  &__header {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px; /* 109.091% */
    letter-spacing: 0.22px;
    padding: 12px;
    border-bottom: 1px solid #E6E6E6;

    svg {
      margin-left: 8px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .spinner {
      animation: rotate 2s linear infinite;
    }

    @keyframes rotate {
      100% {
        transform: rotate(-360deg);
      }
    }
  }

  &__content {
    background-color: white;
    min-height: calc(100% - 60px);
    padding: 0 12px 12px;

  }

  &__day-item {
    min-width: 199px;
    .day-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0 24px 0;

      &__title {
        color: #111;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 120% */
        letter-spacing: 0.2px;
      }

      &__date {
        color: #A3A2A5;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 171.429% */
        letter-spacing: 0.14px;
      }
    }
  }

  &__event {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    gap: 4px;
    border-radius: 10px;
    border: 1.5px solid #E0E0E3;
    background: #FFF;

    &:not(:last-child){
      margin-bottom: 16px;
    }

    &__loop{
      margin-left: auto;
      cursor: pointer;
      color: #A8A8AC;
    }

    &--vks{
      color: #FA8C8C;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    &__title {
      width: 100%;
      color: #19161D;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 156.25% */
      letter-spacing: 0.16px;
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        width: calc(100% - 30px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        width: 18px;
        height: 18px;
        margin-left: 6px;
      }
    }

    &__row {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 5px;
      color: #A3A2A5;;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 178.571% */
      letter-spacing: 0.42px;

      .see-video{
        svg {
          margin-right: 6px;
          width: 16px;
          height: 16px;
        }
      }

      &:last-child {
        color: #1ABB78;
        margin-top: 8px;
        justify-content: space-between;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;

        .social {
          display: flex;
          align-items: center;
          text-decoration: unset;
          color: #1abb78;
          width: 100%;

          .social-icon {
            width: 24px;
            height: 24px;
            cursor: pointer;
            margin-right: 6px;
          }

          .custom_switch {
            display: flex;
            width: 48px;
            height: 24px;
            padding: 2px;
            align-items: center;
            flex-shrink: 0;
            border-radius: 20px;
            background: rgba(142, 140, 148, 0.4);
            cursor: pointer;

            svg {
              path {
                fill: #8E8C94;
              }
              width: 25px;
              height: 25px;
              .icon-rect{
                stroke: #8E8C94
              }
            }

            &.enabled {
              transition: all 0.2s;
              background: rgba(8, 118, 72, 0.40);

              svg {
                margin-left: auto;
                margin-right: 0;
                width: 25px;
                height: 25px;

                path {
                  fill: #1ABB78;
                }
                .icon-rect{
                  stroke: #1ABB78;
                }
              }
            }
          }
        }

        .social:first-child {
          cursor: pointer;
        }
      }
    }
  }

  &__auth {
    padding: 20px 0 0;
    text-align: center;
    display: flex;
    flex-direction: column;

    img {
      margin-bottom: 28px;
    }

    &__title {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.2px;
      margin-bottom: 16px;
    }

    &__description {
      color: #9898A6;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }

    &--icons{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-bottom: 20px;

      svg{
        width: 40px;
        height: 40px;
      }
    }
  }

  .passed{
    .calendar__event__row {

      &:last-child {
        color: #8E8C94;
        margin-top: 8px;
        justify-content: space-between;

        .social {
          display: flex;
          align-items: center;
          text-decoration: unset;
          color: #8E8C94;
          cursor: default;

          .social-icon{
            cursor: default;
            path{
              fill: #8E8C94;
            }
            rect{
              stroke: #8E8C94;
            }
          }
            &.see-video{
              cursor: pointer;
              svg {
                width: 16px;
                height: 16px;
              }
            }

          .custom_switch{
            cursor: default;
          }
        }
      }
    }
  }

  .react-datepicker {
    border: 0;
    border-radius: 0;
    margin-bottom: -16px;

    &__month-container {
      border-image: unset;
    }

    &__day, &__day-name{
      margin: 0.068rem !important;
    }
    &__month{
      margin: 0 !important;
    }

    &__header {
      //height: 88px;
      background-color: white;
      border: 0;
    }

    &__current-month {
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #030303;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      span {
        color: #86888A;
        margin-left: 4px;
      }
    }

    &__navigation {
      top: 22px;
      height: 16px;
      width: 16px;
      background-size: contain;

      &--previous {
        background-image: url("../../../image/icons/new/ArrowLongIcon.svg");
        background-repeat: no-repeat;
        background-size: contain;

        &:before {
          //background-color: red;
          //display: none!important;
        }
      }

      &--next {
        background-image: url("../../../image/icons/new/ArrowLongIcon.svg");
        background-repeat: no-repeat;
        transform: rotateY(180deg);
      }
    }

    &__navigation-icon {
      background-repeat: no-repeat;

      &::before {
        //&::before{
        display: none;
        //}}
      }
    }

    &__day-name {
      width: 2rem;
      color: #7E7E7E;
      font-size: 12px;
      height: 24px;
      margin-bottom: -16px;
    }

    &__day-name,
    &__day,
    &__time-name {
      width: 2rem;

      &--selected {
        background-color: #1ABB78;
        border-radius: 50%;
        width: 28px;
        height: 28px;
      }

      &--outside-month {
        color: #19161D;
        opacity: 0.3;
      }

      &:hover {
        background-color: rgba(26, 187, 120, 0.4);
        border-radius: 50%;
        color: rgb(0, 0, 0);
        width: 28px;
        height: 28px;
      }
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--in-range {
    //border-radius: 0.3rem;
    background-color: #1ABB78;
    border-radius: 50%;
    width: 28px;
    height: 28px;
  }

  .react-datepicker__year-text--keyboard-selected {
    //border-radius: 0.3rem;
    background-color: rgba(26, 187, 120, 0.4);
    border-radius: 50%;
    color: white;
    width: 28px;
    height: 28px;
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
  .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
  .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
  .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
    background-color: rgba(26, 187, 120, 0.4);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: white;
  }
}

.calendar .react-datepicker__day-name,
.calendar .react-datepicker__day,
.calendar .react-datepicker__time-name {
  width: 28px;
  height: 28px;
}

.success_modal {
  &__wrapper{
    .ant-modal-footer{
      display: none;
    }
    .ant-modal-close{
      display: none;
    }
    .ant-modal-content{
      padding: 24px;
    }
  }
  &__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;


    &--title{
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.01em;
    }
    &--description{
      color: #9898A6;
      font-size: 14px;
      font-weight: 450;
      line-height: 20px;
      text-align: center;
    }
    &--img{
      width: 256px;
      height: 256px;
    }
    button{
      width: 100%;
    }
  }
  }


.upload_video_answer{
  background-color: #181818 !important;
}

.toasblock{
  display: flex;
  align-items: center;
  gap: 20px;

  &__wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--title{
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: white;
    }
    &--text{
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #9898A6;
    }
  }
}