.chains-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 1200px;
  width: 100%;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 20px;
  padding-bottom: 4px;

  a {
    text-decoration: none;
  }

  &__popup {
    &_list {
      display: flex;
      flex-direction: column;
      gap: 8px;

    }

    a {
      text-decoration: none;
    }
  }

  &__item {
    padding: 4px 8px;
    background: var(--chain_bg);
    border-radius: 10px;
    height: 24px;
    display: flex;
    align-items: center;
    gap: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;
      flex-shrink: 0;

      path {
        fill: var(--chain_сolor);
      }
    }


    &--text {
      font-size: 14px;
      font-weight: 450;
      line-height: 16px;
      text-align: left;
      color: var(--chain_сolor);
      max-width: 150px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &--see-all {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #9898A6;
    text-decoration: underline;
    cursor: pointer;
  }
}