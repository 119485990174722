.sort-select {
  .ant-popover-inner {
    padding: 16px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &--item {
      display: flex;
      align-items: center;
      gap: 8px;

      label {
        color: #4A4A4A;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 4px 8px;
    border: 1px solid #CDCDCD;
    border-radius: 8px;
    width: max-content;

    &_wrap {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &:hover {
      border-color: #1FB073;
    }

    svg:first-child {
      width: 20px;
      height: 18px;
      flex-shrink: 0;
    }

    &--text {
      font-size: 14px;
      font-weight: 450;
      line-height: 16px;
      color: #212121;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 160px;
      width: 100%;
    }

    &--arrow {
      transform: rotate(-90deg);
      flex-shrink: 0;

      path {
        fill: black;
      }
    }

    &--arrow.open {
      transform: rotate(90deg);

      path {
        fill: black;
      }
    }
  }

  &__label.selected {
    background-color: #DDF5EB;
    border-color: #1ABB78;
  }
}