.channel-info{
  height: 100%;
  max-height: 408px;
  overflow: auto;

  &__item{
    &--title {
      padding: 12px 16px;
      border-bottom: 1px solid #ECECEC;
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    &_list{
        padding: 0 16px 16px 16px;
        display: grid;
        grid-template-columns: repeat(1, minmax(100px, 275px));
        gap: 12px;
    }

    &_list.large{
        grid-template-columns: repeat(2, minmax(100px, 275px)) !important;
    }

    &--text{
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #9898A6;
      padding: 0 16px 20px 16px;
    }
  }
}