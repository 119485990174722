.list-item-roles {
  width: 100%;
  height: 80px;
  border-radius: 16px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 8px;

  &__content {
    display: flex;
    align-items: center;
    width: calc(100% - 180px);

    .item_col {
      width: 28%;
      margin-right: 5%;

      .users_count {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: white;
        color: #77777D;
        border: 2px solid #F2F2F2;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        font-size: 13px;
        line-height: 15px;
        cursor: pointer;
        text-decoration: none;
      }

      .role_name {
        cursor: pointer;
        position: relative;
        display: inline-block;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-decoration-line: underline;
        transition: all 0.2s;
        padding-bottom: 10px;
        padding-right: 20px;

        .role-permissions-view {
          width: 190px;
          position: absolute;
          top: 20px;
          left: 20px;
          display: inline-flex;
          padding: 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          border-radius: 12px;
          background: #FFF;
          box-shadow: 0 5px 15px 0 rgba(22, 22, 26, 0.10);
          opacity: 0;
          z-index: -1;

          .permission_item {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            &__title {
              color: #000;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              white-space: nowrap;
            }

            &__length {
              color: #9898A6;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              margin-left: 4px;
            }
          }
        }

        &:hover {
          .role-permissions-view {
            opacity: 1;
            z-index: 1000;
            display: block;
          }
        }

        &:hover {
          color: #1abb78;

        }
      }
    }

  }

  .edit_btn {
    border: 0;
    outline: 0;
    background-color: transparent;
  }

  &__delete {
    svg {
      cursor: pointer;
      width: 24px;
      height: 24px;

      path {
        fill: #a6a6a6
      }
    }
  }
}
