.filter-date {
  .ant-popover-inner {
    padding: 0;
  }

  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    min-width: 144px;
  }

  &__list {
    padding: 0 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;


    &--item {
      label {
        width: 100%;
        max-width: 204px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 4px 8px;
    border: 1px solid #CDCDCD;
    border-radius: 8px;
    width: max-content;

    &:hover {
      border-color: #1FB073;
    }

    svg:first-child {
      width: 20px;
      height: 18px;
      flex-shrink: 0;
    }

    &--text {
      font-size: 14px;
      font-weight: 450;
      line-height: 16px;
      color: #212121;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 120px;
      width: 100%;
    }

    &--arrow {
      transform: rotate(-90deg);
      flex-shrink: 0;

      path {
        fill: black;
      }
    }

    &--arrow.open {
      transform: rotate(90deg);

      path {
        fill: black;
      }
    }
  }

  &__label.selected {
    background-color: #DDF5EB;
    border-color: #1ABB78;
  }

  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 16px;
    border-top: 1px solid #E6E6E6;

    button {
      width: 100%;
    }
  }

  &__tooltip {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__panel {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px 16px;

    &--item {
      display: flex;
      align-items: center;

      span {
        margin-right: auto;
      }

      gap: 8px;

      .ant-select-item-option-selected {
        background-color: #1FB073 !important;
      }

      .ant-select-selector {
        border-color: #CDCDCD !important;
        box-shadow: none !important;
      }

      .ant-select-open {
        .ant-select-arrow {
          svg {
            transform: rotate(90deg);
          }
        }
      }

      .ant-select-arrow {
        svg {
          transform: rotate(-90deg);

          path {
            fill: black !important;
          }
        }
      }
    }
  }
}

.filter-calendar {
  .rmdp-day-picker, .rmdp-wrapper, .rmdp-wrapper, .rmdp-header, .rmdp-calendar, .rmdp-day-picker {
    width: 100%;
  }

  .rmdp-day-picker {
    & > div {
      width: 100%;
    }
  }

  .rmdp-arrow {
    border-color: black;
    padding: 3px;
  }

  .rmdp-arrow-container:hover {
    background-color: transparent;
    box-shadow: none;

    .rmdp-arrow {
      border-color: #1FB073;
    }
  }

  .rmdp-week-day {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #7E7E7E;
  }

  .rmdp-header-values {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .rmdp-today {
    span {
      background-color: #1ABB78 !important;
    }
  }

  .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #96e3c4 !important;
    box-shadow: none;
  }

  .rmdp-range {
    border-radius: 50%;
    box-shadow: none;
    height: 28px;
    width: 28px;
    background-color: #D1F1E4;

    span {
      color: black;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .rmdp-day {
    box-shadow: none;
    height: 28px;
    width: 28px;

    span {
      height: 28px;
      position: initial;
    }
  }

  .rmdp-week {
    margin-bottom: 4px;
  }

  .rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover {
    background-color: #1ABB78;
  }

  .rmdp-shadow {
    box-shadow: none;
  }

  .rmdp-calendar {
    padding: 0;
  }
}