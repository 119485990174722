.channels-layout-container{
  margin: 0 !important;
  .channels-container{
    margin: 0 !important;
  }
}

.channels-wrap{
  display: flex;
  justify-content: center;
}

.channels-block{
  width: 100%;
  overflow: hidden;
  max-width: 1200px;
  //height: 100%;

  &__header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    &--title{
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }

    &--btn{
      display: flex;
      align-items: center;
      border: none;
      gap: 8px;
      background-color: #1ABB78;
      border: 2px solid #1ABB78;
      border-radius: 10px;
      padding: 8px 16px;

      span{
        font-size: 14px;
        font-weight: 450;
        line-height: 16px;
        color: white;
      }

      svg {
        width: 16px;
        height: 16px;
        path {
          stroke: white;
        }
      }

      &:hover{
        background-color: #29D990;
        border-color: #29D990;
      }
      &:active {
        background-color: #1FB073;
        border-color: #1FB073;
      }
    }
  }

  &__wrap{
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    &--item{
      width: 50%;
      padding: 24px 20px;
    }
  }

  &__loader{
    width: 60%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
  }
}