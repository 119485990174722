.link-btn {
  cursor: pointer;
  overflow: hidden;
  color: #303030;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;

  &:hover {
    color: #1abb78;
  }

  &.is-owner{
    //color: #1abb78;
  }
}

.link-dropdown {

  .link-content{
    padding: 20px;
  }

  .link-title {
    background: #FFF;
    color: black;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    padding: 12px 16px;
    border-bottom: 1px solid #ECECEC;
    border-radius: 0;
  }

  .dropdown-content {
    width: 357px;
    right: 70%;
    left: unset;
    overflow: hidden;
  }
}



