.settings-dropdown{
  display: flex;
  flex-direction: column;
  width: 150px;
  border-radius: 12px;


  &__item{
    padding: 12px 16px;
    gap: 8px;
    cursor: pointer;
    border-bottom: 1px solid #E6E6E6;
    display: flex;
    align-items: center;

    &--delete{
      span, svg{
        color: red !important;
      }
    }

    &--arrow-top{
      transform: rotate(90deg);
    }
    &--arrow-down{
      transform: rotate(-90deg);
    }

    svg{
      width: 16px;
      height: 16px;
    }
    &:hover{
      background: #BBEBD7;
    }

  }
}