.log-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  background-color: #F2F2F2;

  &__logo {
    margin: 0 auto 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 49px;
    line-height: 125.5%;
    letter-spacing: -0.03em;
    color: black;
  }

  &__content {
    width: 416px;
    padding: 40px 48px;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: white;

    &-title {
      font-weight: 600;
      font-size: 30px;
      line-height: 25px;
      color: black;
      margin-bottom: 36px;
    }

    .input-label {
      color: #000000;
      opacity: 0.5;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 5px;
    }

    .input-wrapper {
      position: relative;

      .input-icon {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
      }

      &.error {
        input {
          border: 1px solid red;
        }
      }
    }

    &__input {
      width: 100%;
      padding: 15px 20px;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-clip: padding-box;
      border: 0;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      height: 55px;
      background: #F2F2FA;
      border-radius: 10px;

      &:focus {
        border: 0;
        outline: 0;
      }
    }

    &-header {
      &__text {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        margin-top: 0;
      }
    }

    &-login {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
    }

    &-password {
      display: flex;
      margin-bottom: 16px;
      flex-direction: column;

      &__input {
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);

        padding: 0.375rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }

    &-button {
      width: 100%;
      height: 55px;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
      color: #fff;
      background-color: #1ABB78;
      border: 0;
      cursor: pointer;
      opacity: 0.5;
      border-radius: 10px;
      margin-bottom: 24px;

      &.active {
        opacity: 1;
      }
    }

    &-text {
      font-weight: 450;
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      color: #000000;

      .link {
        margin-left: 5px;
        color: #1ABB78;
        text-decoration: none;
      }
    }
  }

  &__error {
    color: red;
    font-size: 12px;
    opacity: 0.5;
    margin-top: 3px;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 39px;

    color: #b2b2b2;
    text-align: center;
  }
}
