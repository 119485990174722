.filters-list{
  width: 100%;
  display: flex;
  align-items: baseline;
  padding: 20px 0 22px 0;
  border: none;

  &__items{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
}