.settings_modal {
  padding: 0 0 24px 0;

  .settings{
    &__header{
      padding: 24px 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #E6E6E6;

      svg{
        cursor: pointer;
        color: #7F7F7F;
        width: 24px;
        height: 24px;

        path{
          stroke-width: 0.5px;
        }
      }
      &--title {
        color: #000;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 100% */
      }
    }
  }

  .settings-content {
    .title {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 100% */
      padding: 32px;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      svg{
        cursor: pointer;
        color: #7F7F7F;
        width: 24px;
        height: 24px;
        margin-left: auto;

        path{
          stroke-width: 0.5px;
        }
      }
    }

    &__wrap{
      display: flex;
      flex-direction: column;
      padding: 0 32px;
      gap: 20px;
    }

    .calendar-info {
      display: flex;
      flex-direction: column;
      gap: 16px;
      border: 1px solid #CDCDCD;
      border-radius: 8px;
      padding: 12px 16px;

      .user-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__wrap {
          display: flex;
          align-items: center;

          svg {
            margin-right: 8px;
            width: 24px;
            height: 24px;
          }
        }

        &__info {
          &--title {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 156.25% */
          }

          &--name {
            color: black;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            span {
              color: #9898A6;
              margin-right: 4px;
            }
          }
        }
      }

      &__btn{
        font-size: 15px;
        font-style: normal;
        font-weight: 450;
        line-height: 25px;
        background: transparent;
        border: 0;
        outline: 0;
        width: max-content;
        &--logout {
          color: #DB1F26;
        }
        &--connect {
          color: #1abb78;
        }
      }

      &__list{
        display: flex;
        flex-direction: column;
        gap: 12px;

        &--item{
          display: flex;
          align-items: center;
          gap: 8px;

          .label {
            padding: 5px 13px;
            border-radius: 60px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            font-weight: 450;
            line-height: 18px;

            span{
              font-size: 14px;
              font-weight: 450;
              line-height: 18px;
            }
          }

          .form-check {
            display: flex;
            align-items: center;
          }

          .form-check-input {
            width: 20px;
            height: 20px;
            border: 1px solid rgba(0, 0, 0, .25);
            display: block;
            background-repeat: no-repeat;
            padding: 0;
          }

          .form-check-input:checked {
            background-color: #1ABB78;
            border-color: #1ABB78;
            width: 20px;
            height: 20px;
            padding: 0;
          }

          .form-check-input:focus {
            outline: 0;
            box-shadow: none;
          }
        }
      }
    }

    .buttons {
      padding: 16px 32px 0;
      border-top: 1px solid #0000000f;
      margin-top: 24px;
      display: flex;
      align-items: center;
      gap: 20px;
      button{
        width: 100%;
      }
    }
  }
}
