.participation-data {

  &__permissions-info{
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    border: 1px solid #FFA800;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;

    span{
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      color: #9898A6;
    }

    svg{
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }
  .mock-wrap{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 20px;

    &--item{
      box-shadow: 0 4px 12px 0 rgba(0,0,0,.16);
      border-radius: 10px;
      flex: 33% 1;
      max-width: 33%;
      height: 120px;
      width: 100%;
      span{
        display: none;
      }
      .ant-skeleton, .ant-skeleton-image{
        border-radius: 10px;
        position: relative;
        width: 100% !important;
        height: 120px !important;
      }
    }
  }

  &__wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 12px;

    &--title{
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
    }

    .count {
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      color: #9898A6;
      cursor: pointer;
      margin-left: auto;
      text-decoration: underline;
    }

    .count-highlight {
      font-size: 15px;
      font-weight: 500;
      line-height: 25px;
      color: #9898A6;
      margin-right: auto;
    }
  }

  .video_list {
    width: 100%;
    display: flex;
    justify-content: start;
    gap: 16px;
    margin-bottom: 24px;

    .video__time{
      left: 10px;
      right: unset;
    }

    &--wrap{
      position: relative;
      flex: 33%;
      width: 100%;
      max-width: 33%;
      &:hover {
        .video_list__detach {
          display: block;
        }
      }
    }
    &__detach {
      position: absolute;
      top: 8px;
      right: 12px;
      z-index: 10;
      display: none;

      &--icon {
        border-radius: 50%;
        width: 29px;
        height: 29px;
        background-color: rgba(0, 0, 0, 0.7);

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 13px;
          height: 13px;

          path {
            stroke: white;
            stroke-opacity: 1;
          }
        }
      }

      .menu-tooltip {
        font-size: 13px;
      }
    }

    .video--poster{
      width: 100%;
      max-width: 100%;
    }

    .more_videos {
      position: relative;
      width: 240px;
      cursor: pointer;
      height: 146px;

      svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      &__bottom {
        position: absolute;
        bottom: 66px;
        padding: 12px 16px;

        &__count {
          font-weight: 450;
          font-size: 32px;
          line-height: 37px;
          color: #9898A6;
          display: flex;
          flex-direction: column;

          .text {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #9898A6;
          }
        }
      }
    }
  }

  .empty_video {
    color: #9898A6;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 28px;
  }

  .highlights-list {
    display: grid;
    grid-template-columns: repeat(2, 345px);
    gap: 16px;
    grid-auto-rows: minmax(100px, auto);
    max-height: 322px;
    overflow-y: auto;

    .protocol-item {
      max-width: 348px;
      width: 100%;
      height: fit-content;
      background-color: transparent;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      color: #46454E;
      padding: 16px;
      border: 1px solid #1abb78;
      border-radius: 16px;
      position: relative;
      display: block;
      margin-right: 0;

      &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .left {
          width: 100%;

          .video-title {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 8px;
            margin-bottom: 8px;

            span {
              color: #9898A6;
              font-size: 14px;
              font-style: normal;
              font-weight: 450;
              line-height: 16px;
            }

            .video-link {
              display: flex;
              align-items: center;
              text-decoration: none;
              gap: 4px;
              &--text{
                width: 160px;
                color: #1ABB78;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            svg{
              width: 16px;
              height: 16px;
            }
          }

          .name {
            font-weight: 600;
            font-size: 16px;
            line-height: 135%;
            color: #1D1929;
          }

          .text {
            //width: calc(100% - 40px);
            margin-bottom: 8px;
            height: 54px;
            overflow: hidden;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            color: #46454E;
            position: relative;
            padding-right: 30px;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }

        .highlights-star {
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }

        .time {
          margin-left: 10px;
          color: #000;
          opacity: 0.35;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px;
          text-align: right;
          border: 0;
          padding: 0;
          z-index: 10;
          //cursor: pointer;

          &:hover {
            background-color: transparent;
          }
        }

      }

      &__author_wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
      }

      .author_block{
        width: 75%;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a8a7ad;
    }
  }

  .recognition-title {
    color: black;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    margin: 28px 0 24px 0;
    border-top: 1px solid #ECECEC;
    padding-top: 24px;
  }

  .connect_person {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    span {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      opacity: 0.6;
      padding-right: 20px;
    }
  }

  .add_img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    span {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      opacity: 0.6;
      padding-right: 20px;
    }

    &_button {
      display: flex;
      width: 306px;
      height: 40px;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      border-radius: 10px;
      border: 1.5px solid #1ABB78;
      background-color: white;
      color: #1abb78;
    }
  }

  .upload_video_answer {
    border-radius: 8px;
    background: #181818;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.20), 0 6px 30px 0 rgba(0, 0, 0, 0.12), 0 16px 24px 0 rgba(0, 0, 0, 0.14);
    color: white;
    text-align: left;
    display: flex;
    width: 343px;
    padding: 16px 20px;
    align-items: center;
    gap: 12px;

    svg {
      width: 28px;
      height: 28px;
      flex-shrink: 0;
    }

    .title {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.408px;
      margin-bottom: 8px;
    }

    .description {
      color: #C8C5C5;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.078px;
      margin-bottom: 0;
    }
  }

  &__notes{
    &--empty{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--subtitle{
      margin-bottom: 12px;
    }
    &--list{
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 1fr));
      gap: 16px;
      flex-wrap: wrap;
    }
    .note-block{
      height: 140px;
    }
    .note-block__body--content{
      max-height: 77px !important;
    }
  }
}

.remove-participation{
  width: 100%;
  margin-top: 48px;

  &__wrap{
    display: flex;
    flex-direction: column;
    gap: 12px;

    &--title{
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
    }
    &--description{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      span{
        font-size: 16px;
        font-weight: 400;
        line-height: 20.8px;
        color: #9898A6;
      }

      button{
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: transparent;
        border: 0;
        width: max-content;
        padding: 4px 24px;
        white-space: nowrap;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: #DB1F26;
        &:hover{
          color: #E5484E;

          svg{
            path{
              fill: #E5484E;
            }
          }
        }

        svg{
          width: 20px;
          height: 20px;

          path{
            fill: #DB1F26;
          }
        }
      }
    }
  }
}