.channels__section {
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  margin: 60px 0 0 0;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 24px 0;

    &__name {
      display: flex;
      align-items: center;

      .name {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
      }


      .link {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        text-decoration: underline;
        cursor: pointer;
        color: rgba(152, 152, 166, 1);
        margin-left: auto;

        &:hover {
          color: rgb(129, 129, 140);
        }
      }

      .count {
        color: #9898A6;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-left: 16px;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          color: black;
        }
      }

      .actions-menu {
        .menu-button {
          margin-left: 16px;
        }
      }
    }

    &__view-all {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: nowrap;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #a8a8ac;
      white-space: nowrap;
      align-items: center;
      cursor: pointer;

      a {
        color: #9898A6;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;


        &:hover {
          color: #898991;
        }
      }

      svg {
        height: 20px;
        margin-left: 8px;
      }
    }
  }

  &-videos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .more_videos {
      position: relative;
      width: calc(100% / 3 - 24px);
      cursor: pointer;
      z-index: 1;

      svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: -50px;
      }

      &__bottom {
        position: absolute;
        bottom: 106px;
        padding: 15px 24px;

        &__count {
          color: #9898A6;
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px;
          display: flex;
          flex-direction: column;

          .text {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #9898A6;
          }
        }
      }
    }

    &-empty {
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 13px;
    }

    /*   &__example {
         width: 279px;
         min-height: 167px;
         display: flex;
         flex-direction: column;
         position: relative;
         margin-right: 24px;
         margin-bottom: 10px;
         text-decoration: none;

         &-time {
           font-weight: 500;
           font-size: 12px;
           line-height: 14px;
           color: #ffffff;
           background: rgba(0, 0, 0, 0.7);
           border-radius: 4px;
           position: absolute;
           margin: 0;
           top: 135px;
           left: 18px;
           padding: 3px 5px;

           p {
             margin: 0;
           }
         }

         &-section {
           position: absolute;
           top: 135px;
           right: 40px;
           //background: #f1e0ff;
           border-radius: 4.16667px;
           font-weight: 600;
           font-size: 10px;
           line-height: 17px;
           //color: #c072ff;
           padding: 1.5px 9px;
         }

         &-number {
           position: absolute;
           top: 135px;
           right: 12px;
           background: rgba(0, 0, 0, 0.7);
           border-radius: 4px;
           font-weight: 500;
           font-size: 12px;
           line-height: 14px;
           color: #ffffff;
           margin: 0;
           padding: 3px 4px;
         }

         &-information {
           display: flex;
           flex-direction: row;
           justify-content: space-between;
           margin-top: 10px;

           &__name {
             margin: 0;
             margin-bottom: 10px;
           }

           &__date {
             color: #A8A8AC;
             margin: 0;
             font-size: 12px;
             line-height: 14px;
           }
         }

       }*/

    &__example-users {
      display: flex;

      &-item {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 2px solid white;

        &:not(:last-child) {
          transform: translateX(10px);
        }
      }
    }

    .video{
      &__info-date{
        flex-wrap: nowrap;
        white-space: nowrap;
      }
      &__time{
        width: max-content;
      }
    }
  }

  &.private {
    border-bottom: 1px solid rgba(152, 152, 166, 0.70);
    padding-bottom: 24px;
    min-height: 400px;
  }
}

.mockup{
  object-fit: cover;
  border-radius: 10px;
  width: 22%;
  max-width: 210px;
  position: relative;
  flex-grow: 1;
}

.chains-menu{
    .ant-popover-inner{
      padding: 0;
    }
    &--count{
      cursor: pointer;
      color: #9898A6;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }