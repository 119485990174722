.sorted-items-icon{
  cursor: pointer;
  width: 16px;
  height: 16px;
  color: #A8A8AC;
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    width: 16px;
    height: 16px;
  }

  &:hover{
    svg{
      color: #1abb78;
    }
  }

  .active{
    transform: rotate(180deg);
  }
  .selected{
    color: #1abb78;
  }
}