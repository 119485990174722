.user-icons {
  display: flex;
  justify-content: end;
  position: relative;
  cursor: pointer;
  .known-user{
    border: 2px solid #F2F2F2
  }
  &__more {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: white;
    color: #77777D;
    border: 2px solid #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    font-size: 13px;
    line-height: 15px;
    cursor: pointer;
    margin-left: -12px;
  }

  &__item {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid transparent;
    background-color: white;
    cursor: pointer;
  }

  &__item:not(:first-child){
    margin-left: -12px;
  }

  .persons_menu {
    display: flex;
    flex-wrap: wrap;
    width: 264px;
    max-height: 332px;
    overflow-y: auto;
    border: 0;
    box-shadow: 0 5px 15px rgba(22, 22, 26, 0.1);
    padding: 24px 14px 14px 14px;
    position: absolute;
    top: 44px;
    background-color: white;
    border-radius: 10px;
    z-index: 1000;
    justify-content: space-between;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #3F43503D;
    }
    svg {
      width: 14px;
      height: 14px;
      path{
        fill: #A8A8AC;
      }
    }
    .person_item {
      width: 100%;
      display: flex;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 8px;
      }
      &-wrap{
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        width: 100%;
        flex-grow: 0;
      }
      .person_info {
        .name {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: black;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          max-width: 180px;
        }

        .email {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #9898A6;
        }

        &-delete{
          margin-left: auto;
        }
      }
    }

    &.large {
      width: 580px;
      gap: 0 25px;

      .person_item {
        &-wrap{
          gap: 0 15px;
          flex: 0 1 45%;
        }
      }
    }
    &.left {
     right: 0;
    }
  }
}


.icon-disabled {
  svg {
    path{
      fill: #DADADA;
    }
  }
}