.custom-checkbox {
  display: flex !important;

  input {
    width: 20px;
    height: 20px;
    border: 1px solid rgba(0, 0, 0, .25);
    margin-right: 10px;
    margin-top: 0;
    cursor: pointer;
  }

  input:checked {
    background-color: #1ABB78;
    border-color: #1ABB78;
    width: 20px;
    height: 20px;
  }

  input:focus {
    outline: 0;
    box-shadow: none;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #4A4A4A;
    cursor: pointer;
  }
}