.signature{
  width: 100%;
  &__add-btn{
    display: flex;
    align-items: center;
    height: 32px;
    svg {
      margin-right: 5px;
      width: 14px;
      height: 14px;
      margin-top: 2px;

      rect {
        fill: #1ABB78;
      }
    }
  }
  &__form {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  &__container{
    border: 1px solid #CDCDCD;
    border-radius: 10px;
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 5px;
    width: 100%;
    max-width: 370px;
    position: relative;

    &:focus-within{
      border-color: #1ABB78;
    }

    &--input{
      width: 100%;
      border: none;
      border-radius: 10px;
      margin-right: 5px;
      &:focus {
        outline: 0;
        border: none;
      }
    }
    &--loader{
    }
  }

  &__list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap:10px;
    margin-top: 12px;

    &--item{
      border: 1px solid #1ABB78;
      background: #D1F1E4;
      border-radius: 26px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      height: 36px;
      padding: 0 10px;
      font-weight: 450;
      font-size: 16px;
      line-height: 20px;
      color: #1ABB78;

      svg {
        cursor: pointer;
        margin-left: 9px;
        margin-top: 2px;

        path {
          stroke: #1ABB78;
        }
      }
    }
  }
}

.button{
  background: none;
  border: none;
  color: #7F7F7F;
  min-width: 75px;
  padding: 0;

  &:disabled {
    opacity: 0.5
  }

  &--success{
    color: #1ABB78;
  }
}


.toaster{
  position: fixed;
  top: 16px;
  right: 16px;
  padding: 10px;
  z-index: 10;
  border: none !important;
  color: white !important;
  width: auto !important;
}

.error{
  &__block{
    display: flex;
    align-items: flex-start;
    gap: 10px;
    max-width: 350px;
    width: 100%;
    margin-top: 8px;

    svg {
      height: 18px;
      width: 32px;
    }
    &--text {
      font-size: 14px;
      line-height: 20px;
      color: #DB1F26;
    }
  }
}