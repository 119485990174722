.editor-dropdown{
  background: white;
  border-radius: 8px;
  box-shadow: 0px 3px 11px 0px #2855391F;
  display: flex;
  flex-direction: column;

  &__item{
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid #E6E6E6;
    padding: 12px 16px;
    cursor: pointer;

    svg{
      width: 24px;
      height: 24px;
    }

    &--active{
      span{
        color: #1abb78 !important;
      }
    }

    &:hover{
      span{
        color: #1abb78 !important;
      }
    }
  }
}