.persons-dropdown{
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 300px;
  box-shadow: 0px 3px 11px 0px #2855391F;
  border-radius: 12px;
  background: white;


  &--input-wrap {
    border: 1px solid #D9DEE2;
    border-radius: 20px;
    padding: 0 8px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "Open Sans", sans-serif;

    svg{
      width: 15px;
      height: 15px;
    }

    input {
      border: none;
      outline: 0;
      padding: 0;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      line-height: 22px;

      &:focus-visible {
        border: none;
      }
    }

    &:active {
      border-color: #1FB073;
    }

    &:focus,
    &:focus-within {
      border: 1px solid #1ABB78;
    }
  }

  &__find-users{
    max-height: 150px;
    overflow: auto;
    margin: 10px 0 15px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-family: "Open Sans", sans-serif;

    .person-item {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      gap: 12px;
      cursor: pointer;
      input{
        margin: 0;
      }

      .person-check{
        position: absolute;
        left: 0;
      }

      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: #f2f3fc;
        border: 2px solid #F2F2F2;
        margin-right: -4px;
        flex-shrink: 0;
      }

      .person_info {
        .name {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: black;
          width: 215px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .email {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          width: 215px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: #9898A6;
        }
      }
    }
  }
  .custom-checkbox{
    font-family: "Open Sans", sans-serif;
  }
}