.editor{
  margin-top: 8px;
  &__actions{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    flex-wrap: wrap;

    &--item{
      border: 1px solid #1abb78;
      border-radius: 4px;
      height: 24px;
      padding: 0 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover{
        background: #AAF0D3;
      }

    }
  }
}