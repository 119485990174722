.chat-block{
  &__popover{
    .ant-popover-inner{
      padding: 0;
      background: none;
    }
    width: 100%;
    max-width: 600px;

    @media screen and (max-width: 500px){
      max-width: 360px;
    }
  }

  &__tooltip{
    max-width: max-content;
    z-index: 10 !important;
    &--wrap{
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 12px 16px;
    }

    &--title{
      font-size: 14px;
      font-weight: 450;
      line-height: 16px;
    }

    &--text{
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}
.chat-badge{
  position: fixed;
  bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  &__container{
    padding: 8px 16px;
    background: #1ABB78;
    border-radius: 8px 8px 0 0;
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    &--count{
      background-color: red;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: absolute;
      top: -6px;
      right: -3px;
      padding: 2px 6px;
    }

    svg{
      width: 15px;
      height: 15px;

      path{
        fill: white;
      }
    }
  }
}