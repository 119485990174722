.channels-modal {
  z-index: 999999;

  .channel-form{
    width: 100%;
    padding: 24px;
    &__header{
      border-bottom: 1px solid #ECECEC;
      padding-bottom: 32px;
    }
  }

  .modal-content{
    width: 706px;
  }
}
