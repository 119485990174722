.filters-list{
  width: 100%;
  display: flex;
  align-items: baseline;
  padding: 16px 0;
  border-top: 1px solid #E6E6E6;

  &__items{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
}