@import "../../../styles/mainVariables";

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: $menuWidth;
  background: #111111;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #f7f8f9;
  position: fixed;
  left: 0;
  top: 0;
  flex-shrink: 0;
  height: 100%;
  z-index: 1000;
  overflow: hidden;
  padding: 16px 20px 28px 16px;
  transition: width 0.5s;

  &_item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;

    &--shevron-default{
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      cursor: pointer;
      transform: rotate(90deg);
      path{
        fill: white;
      }
    }

    &--shevron{
      transform: rotate(180deg);
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      cursor: pointer;
      path{
        fill: white;
      }
    }

    &--shevron.active{
      transform: rotate(0deg);
    }
  }


  &_link{
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    text-decoration: none;
    color: white;

    p {
      display: block;
      width: 115px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      line-height: 20px;
      font-weight: 450;
    }

    &:hover{
      .menu__sub_item-dot{
        color: #1abb78 !important;
      }
     color: #1abb78;
      span{
        color: #1abb78 !important;
      }

      svg{
        path{
          fill: #1abb78  !important;
          stroke: #1abb78  !important;
        }
      }

      p{
       color: #1abb78 !important;
      }
    }
  }

  &__logo {
    height: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32%;
    letter-spacing: -0.04em;
    color: #FFFFFF;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;

    &:hover {
      color: white;
    }

    &.small-menu {
      text-align: center;
      margin-left: unset;
    }
  }

  &__instruments {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    &-section {
      display: flex;
      flex-direction: row;
      margin-bottom: 4px;
      padding-left: 17px;
      text-align: center;
      text-decoration: none;
      align-items: center;
      opacity: 0.5;
      cursor: pointer;
      height: 48px;

      &__name {
        width: calc(100% - 50px);
        margin-left: 12px;
        margin-bottom: 0;
        max-width: none;
        color: #ffffff;
        display: flex;
        justify-content: space-between;

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &-instruments__active {
    // filter: brightness(0) invert(1);
    text-decoration: none;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    opacity: 1;
  }

  &__user-content {
    width: calc(100% - 32px);
    box-sizing: border-box;
    border-radius: 12px;
    //padding: 10px;
    margin-right: 181px;
    position: relative;
    bottom: 16px;
    cursor: pointer;
    background-color: transparent;
    display: flex;

    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-right: 12px;
      background-color: white;
    }

    .inner {
      width: calc(100% - 50px);

      .name {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
      }

      .company {
        display: flex;
        align-items: center;
        color: #1ABB78;

        svg {
          margin-right: 6px;
        }

        span {
          width: calc(100% - 40px);
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }
      }

      .user {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #FFFFFF;
        opacity: 0.35;
      }
    }

    &.small-menu {
      background-color: transparent;
    }
  }

  .menu-wrapper {
    width: $menuWidth;
    position: fixed;
    bottom: 0;

    .dropdown-content {
      right: unset;
      left: 16px;
      z-index: 1001;
    }

    &.open-top {
      bottom: 96px;
    }

    &.small-menu {
      width: $menuWidthSmall;

      .menu__user-content img {
        margin: 0;
      }
    }

  }

  .open-menu {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    padding-left: 16px;

    svg {
      transition: all 0.3s;
    }
  }

  &__sign-out {
    border: 1px solid #212121;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;
    margin-right: 181px;
    position: absolute;
    bottom: 16px;
    left: 16px;
    cursor: pointer;
  }

  .accordion {
    border: 0;
    .channels.accordion-body{
      max-height: 28vh;
      height: 100%;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none; /* Скрывает скролл */
      }
    }
    .accordion-item:not(:first-child){
      margin-top: 20px;
    }

    .accordion-item {
      border: 0;
      background-color: transparent;
      .integration-icon{
        &:hover{
          path{
            stroke: #1FB073  !important;
            fill: transparent !important;
          }
        }
      }
      .roles-icon{
        width: 20px;
        height: 20px;
        flex-shrink: 0;
          path{
            stroke: black !important;
          }

        &:hover{
          path{
            fill: #1abb78 !important;
          }
        }
      }

      .menu_link{
        svg{
          width: 20px;
          height: 20px;
        }
      }

      .accordion-header {
        border: 0;
        padding: 8px 12px;

        a {
          display: flex;
          color: white;
          text-decoration: none;
        }
      }

      &.small-menu {


        .accordion-header {
          padding: 8px;
          svg {
            width: 20px;
            height: 20px;
            path{
              fill: white;
            }
          }
        }
        .accordion-button {
          width: auto;
          margin: auto;
        }

        .menu__sub_item {
          width: 100%;
        }


      }

      .active_menu {
        background: white;
        border-radius: 12px;

        .menu_link.active_link{
          p{
            color: black;
          }
        }
        .menu_link{
          flex-shrink: 1;
          p{
            color: black;
          }
          svg{
            width: 20px;
            height: 20px;

            path{
              fill: rgba(168, 168, 172, 1);
            }
          }
          overflow: hidden;

          .roles-icon{
            flex-shrink: 0;
            path{
              fill:  rgba(168, 168, 172, 1);
              stroke: white !important;
            }
          }

          .integration-icon{
            flex-shrink: 0;
            path{
              fill:  transparent ;
              stroke: rgba(168, 168, 172, 1) !important;
            }
          }
        }
        .menu_item--shevron {
          //transform: rotate(0deg);
            path{
              fill: rgba(168, 168, 172, 1) !important;
            }
        }

        .menu_item--shevron-default{
          path{
            fill: rgba(168, 168, 172, 1) !important;
          }
        }

        .accordion-button {
          p{
            color: #3B3B3B;
          }
          //padding: 0 16px;
          svg{
            path{
              fill: rgba(168, 168, 172, 1) !important;
              stroke: rgba(168, 168, 172, 1);
            }
          }
        }
      }
      &.sub_menu {
        .accordion-body{
          padding: 16px 0 0 40px;
        }
        .accordion-header {
          .accordion-button {
            &:after {
              display: block;
              background-image: url("../../../image/icons/arrow-top.svg");
              transform: rotate(180deg);
            }
          }
        }
        .active_menu {
          .accordion-button {
            &:after {
              display: block;
              background-image: url("../../../image/icons/arrow-top-dark.svg");
              transform: rotate(180deg);
              opacity: 0.5;
            }
          }
        }
      }

      .menu__sub_item {
        width: 80%;
        color: rgba(152, 152, 166, 1);
        margin-bottom: 8px;
        text-decoration: none;
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;

        &:hover{
          color: #1abb78;
        }


        &.active_link {
          opacity: 1;
          color: #1abb78;

          .menu__sub_item-dot{
            color: #1abb78;
          }
        }

        &--wrap{
          display: flex;
          align-items: center;
          gap: 5px;

          &-icon{
            width: 16px;
            height: 15px;
          }

          span{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba(152, 152, 166, 1);
            text-decoration: none;
            font-weight: 400;
            font-size: 14px;
            line-height: 25px;
          }
        }


        &-dot{
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          color: #9898A6;
        }
      }
    }

    .accordion-item:last-of-type .accordion-button.collapsed{
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
    }
  }

  &.small {
    width: $menuWidthSmall;
    padding: 20px 16px 24px 16px;


    .sub_menu {
      .accordion-header{
      }
      .accordion-body {
        padding: 16px 0 0 !important;

        .active-link > svg {
          path {
            fill: rgba(26, 187, 120, 1);
          }
        }
      }
    }

    .menu__logo {
      height: 40px;
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        margin: auto;
      }
    }

    .accordion .accordion-item .menu__sub_item {
      display: flex;
      justify-content: center;
      margin-bottom: 8px;

      svg {
        width: 20px;
        height: 20px;
        path {
          fill: #77777D;
          stroke: #77777D;
        }
      }

      &.active_link {
        svg {
          path {
            fill: rgba(26, 187, 120, 1);
            stroke: rgba(26, 187, 120, 1);
          }
        }
      }
    }

    .open-menu {
      margin-bottom: 32px;
      width: 100%;
      padding: 0;

      svg {
        width: 40px;
        height: 36px;
        transform: rotateY(-180deg);
        margin: auto;
      }
    }
  }

  &.max-menu{
    background: #203864;
    .accordion {
      .accordion-item {
        .menu__sub_item {
          &.active_link {
            color: white;
          }
        }
      }
    }
  }
}

.tooltip-inner {
  background-color: #232323;
  color: #F7F8F9;
  margin-left: 10px;
}

.tooltip-arrow {
  border-bottom-color: #232323;
  margin-left: 10px;
}

.menu_item.small-menu{
  padding: 8px;
    .menu_link{
      overflow: visible !important;
      svg{
        flex-shrink: 0;
      }
    }
}