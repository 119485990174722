.auto-chains-channels {

  .chain-block {
    //margin-top: 12px;
    &--add-btn{
      padding: 12px 0;
      margin-left: 4px;
    }
    &__list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 24px;
    }
    &__input-wrap{
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 0 8px;

      &--input{
        width: 100%;
        height: 32px;
        padding: 6px 12px;
        border-radius: 10px;
        border: 1px solid #A6A6A6;
        background: #FFF;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        &:focus {
          border-color: #1abb78;
          outline: 0;
        }
      }

      &--buttons{
        display: flex;
        align-items: center;
        gap: 8px;

        button:first-child{
          color:  #7F7F7F;
        }
      }
    }

    &--title {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #9898A6;
      padding-left: 8px;
    }

    .custom-button {
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      padding: 4px 8px;

      &--ai{
        box-shadow: 0px 5px 15px 0px #16161A1A;
        padding: 8px;
        margin-bottom: 4px;
        .chain-block__item--badge{
          span{
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        &--wrap{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 4px 8px;
        }
        &--text{
          display: flex;
          align-items: center;
          gap: 4px;

          span, svg{
            color: #9898A6;
          }
          svg{
            width: 16px;
            height: 16px;
            flex-shrink: 0;
          }
          span{
            font-size: 10px;
            font-weight: 450;
            line-height: 12px;
          }
        }
      }

      &--badge {
        background-color: var(--chain_bg);
        padding: 4px 8px;
        border-radius: 60px;
        display: flex;
        align-items: center;
        gap: 4px;
        max-width: 80%;

        span {
          color: var(--chain_color);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          font-weight: 450;
          line-height: 18px;
        }

        svg {
          width: 12px;
          height: 12px;

          path {
            fill: var(--chain_color);
          }
        }
      }
    }
  }
}
