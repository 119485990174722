.list-item {
  width: 100%;
  height: 80px;
  border-radius: 16px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 24px;
  //cursor: pointer;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 196px);

    .item_col {
      width: 14%;
      margin-right: 5%;

      .role {
        width: max-content;
        max-width: 102px;
        height: 30px;
        background: rgba(26, 187, 120, 0.1);
        border-radius: 60px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        span{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .dropdown-content {
        top: 36px;
        right: unset;
        left: 0;
      }

      &.light{
        color: #9898A6;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }

    .person_tag_wrap{
      display: flex;
      align-items: center;
    }

    .person_tag {
      width: fit-content;
      padding: 4px 12px;
      border-radius: 6px;
      background-color: white;
      color: #1ABB78;
      border: 1px solid #D9D9D9;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      margin: 0 6px 0 0;
      display: flex;
      align-items: center;

    }

    .tags-count {
      height: 32px;
      border-radius: 60px;
      border: 2px solid #F2F2F2;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #77777D;
      padding: 5px 13px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .tags-list .tag-item {
      padding: 5px 13px;
    }

    .user_info {
      width: 26%;
      display: flex;
      align-items: center;
      overflow: hidden;

      &__img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 20px;
      }

      &__content {
        width: calc(100% - 68px);

        .name {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: black;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          &:hover{
            cursor: pointer;
            color: #1abb78;
          }
        }

        .email {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #9898A6;
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  &__actions {
    display: flex;
      width: 126px;

    .video_count {
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: black;
      text-decoration: none;
      cursor: pointer;

      svg {
        margin-right: 10px;
        color: #A8A8AC;
      }
    }
  }

  .edit_btn {
    border: 0;
    outline: 0;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'ss04' on;
    color: #A8A8AC;
  }

 &.persons{
   .list-item__content{
     width: calc(100% - 100px);
   }
   .list-item__actions{
     width: 100px;
   }
 }

  &.persons.active{
    border: 1px solid #1abb78;
  }

  &--enter{
    cursor: pointer;
    color: #7F7F7F;
    flex-shrink: 0;

    &:hover{
      color: #1ABB78
    }
  }
}
