.search-block{
  width: 100%;
  padding: 0 10px 16px 0;
  background-color: white;
  height: calc(-299px + 100vh);

  &::after{
    content: '';
    width: 20px;
  }

  &__wrap{
    overflow: hidden auto;
    height: 100%;
  }
  &__list{
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
    height: 100%;
  }

  &__preview{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;

    span{
      max-width: 320px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      color: #9898A6;
    }
  }

  .videos{
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;

    &__list {
      width: 100%;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 16px 0;
      padding: 8px 24px 20px 24px;
      border-bottom: 1px solid #E6E6E6;

      &--item{
        width: 48%;
        text-decoration: none;

        .video--poster{
          width: 100%;
          display: flex;
          gap: 10px;

          .video{
            &--name{
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              display: block;
              font-size: 14px;
              line-height: 16px;
              font-weight: 450;
            }

            &--loader{
              width: 94px;
              height: 53px;
              z-index: 1;
            }

            &__info{
              width: 44%;
            }

            &__time{
              left: 10px;
              right: unset;
              top: 28px;
              padding: 4px 6px;
            }

            &__info-date{
              span{
                white-space: nowrap;
                font-size: 10px;
                line-height: 12px;
              }
            }
            &__info--wrap{
              span:first-child{
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
          .video__channels{
            display: none;
          }

          &-img{
            width: 94px;
            height: 53px;
          }
        }
        .video__info--wrap{
          width: 100%;
          max-width: 100%;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          color: black;
        }
      }
    }
  }


  .search-persons {
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;

    &__list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 16px 0;
      padding: 8px 24px 20px 24px;
      border-bottom: 1px solid #E6E6E6;

      &-item{
        width: 48%;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        img{
          width: 35px;
          height: 35px;
          border-radius: 50%;
          object-fit: cover;
          flex-shrink: 0;
        }

        &--title{
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;

          display: flex;
          align-items: center;
          gap: 0 4px;
          flex-wrap: wrap;
        }

        &--wrap{
          display: flex;
          flex-direction: column;

          .find-text{
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: #9898A6;

            span:first-child{
             white-space: nowrap;

           }
           span:last-child{
             white-space: nowrap;
             text-overflow: ellipsis;
             overflow: hidden;
             width: 50px;
             display: inline-block;
           }
          }


        }
      }
    }
  }


  .transcriptions{
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;


    &__list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 8px 24px 20px 24px;

      &--header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 8px;

        &-name{
          font-size: 16px;
          font-weight: 450;
          line-height: 20px;
          text-align: left;
          color: rgba(152, 152, 166, 1);
          width: 45%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

        }
        &-video-name{
          font-size: 16px;
          font-weight: 450;
          line-height: 20px;
          text-align: right;
          color: #A8A8AC;
          cursor: pointer;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 5px;
          width: 50%;
          svg {
            height: 17px;
            width: 17px;
            flex-shrink: 0;
            path{
              fill: #A8A8AC;
            }
          }
          span{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &:hover{
            color: #8C8C8C;
            svg {
              path{
                fill: #8C8C8C;
              }
            }
          }
        }
      }
      &--footer{
        font-size: 14px;
        font-weight: 450;
        line-height: 20px;
        text-align: right;
        color: #9898A6;
        margin-top: 8px;
      }

      &--item {
        width: 100%;
        display: flex;
        align-items: start;
        flex-direction: column;
        text-decoration: none;
        color: black;
        margin-bottom: 16px;
        border-bottom: 1px solid #F2F2F2;
        padding-bottom: 8px;
        font-size: 14px;
        font-weight: 450;
        line-height: 20px;
        text-align: left;
      }

      &--link {
        width: 100%;
        display: flex;
        align-items: start;
        text-decoration: none;
        color: black;
        font-size: 16px;
        font-weight: 450;
        line-height: 20px;
        text-align: left;
      }
    }
  }

  .content__header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0 8px 0;
    padding: 0 24px;

    &--title{
      font-weight: 500;
      color: #000000;
      font-size: 16px;
      line-height: 20px;
      text-align: left;
      text-decoration: none;
    }
    &--count{
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #9898A6;
      text-decoration: underline;

      &:hover{
        color: #000000;
      }
    }
  }
}

.root{
  .chat-input{
    align-items: center;
  }

  .chat-input__right-icon{
    width: 16px;
    height: 16px;
    cursor: pointer;

    path{
      fill: #7F7F7F;
    }

    &:hover{
      path{
        fill: #1ABB78;
      }
    }
  }
}