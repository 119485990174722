.role-title {
  svg {
    margin-right: 6px;
  }
}

.form-roles {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: 170px;
  overflow-y: auto;
  gap: 16px;
  margin-top: 4px;

  .form-check {
    display: flex;
    align-items: center;
    flex: 45%;

    input {
      margin-right: 8px;
    }
  }

  &.full {
    .form-check {
      width: 100%;
    }
  }

  .form-check-input:checked {
    background-color: #1ABB78;
    border-color: #1ABB78;
  }

  .form-check-input:focus {
    outline: 0;
    box-shadow: none;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a8a7ad;
  }
}
