.auto-chains-channels {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 12px;

  &--title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    padding: 8px 0;
    margin-bottom: 16px;
  }

  &__content{
    overflow: auto;
    display: flex;
    flex-direction: column;

    .accordion{
      border: none;
      border-radius: 0;
    }
    .accordion.active{
      .channel-block__container--arrow{
        transform: rotate(90deg);
      }
    }

    .accordion-item{
      padding: 0;
      border: none;
      border-radius: 0 !important;
    }
    .accordion-body{
      padding: 0;
    }

    .accordion-button:not(.collapsed){
      background-color: white;
      box-shadow: none;
    }

    .accordion-button:focus{
      box-shadow: none;
    }

    .accordion-button{
      &:after{
        display: none;
      }
    }
  }

  .channel-block{

    &__container{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      padding-bottom: 12px;

      .form-check-input{
        margin-right: 0;
      }

      &--arrow{
        transform: rotate(-90deg);
        cursor: pointer;

        path{
          fill: #A8A8AC;

        }
      }

      a{
        text-decoration: none;
      }

      &--badge{
        padding: 5px 13px;
        border-radius: 60px;
        font-size: 14px;
        line-height: 16px;
        background-color: var(--chain_bg);
        color: var(--chain_color);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 90%;
      }
    }
    &__chains{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 12px;

      &--item{
        display: flex;
        align-items: center;
        gap: 4px;
        border-radius: 10px;
        border: 0.5px solid var(--chain_color);
        color: var(--chain_color);
        padding: 4px 8px;
        max-width: 95%;

        span{
          font-size: 14px;
          font-weight: 450;
          line-height: 18px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        svg{
          width: 12px;
          height: 12px;
          flex-shrink: 0;
          path{
            fill: var(--chain_color);
          }
        }

        svg:last-child{
          cursor: pointer;
        }
      }

    }



  }
}

